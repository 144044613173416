import "./style.scss";
import { useState, MouseEvent,useEffect } from "react";
import { Divider, List, ListItemIcon, ListItemText, Avatar, Typography } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Logo from '../assets/images/logo.png'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import CommuteIcon from '@mui/icons-material/Commute';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ListItem from "./components/layout-list-item";
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import WarningDialog from "../components/mui/warning-dialog";
import PaymentIcon from '@mui/icons-material/Payment';
import { useNavigate } from "react-router-dom";
import { Logout } from "../services/Login";
import { CardGiftcard as GiftCard } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

const Layout = () => {
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar()
    const {pathname} = location;
    const navigate = useNavigate()
    const [state, setState] = useState({
        collapse: false,
        menuOption: false,
        logoutWarning: false
    });

    //const adminId = useSelector((state:any) => state.admin.admin_id);
    /*eslint-disable*/
    useEffect(() => {
        if (pathname === "/") {
          navigate("/dashboard");
        }
      }, []);
    /*eslint-enable*/


    const changeSidebarSize = (e: MouseEvent<HTMLElement>) => {
        const leftSide = document.getElementById("left") as HTMLElement;
        const rightSide = document.getElementById("right") as HTMLElement;
        const logo = document.getElementById("logo") as HTMLElement;
        const collapseArrow = document.getElementById("collapse") as HTMLElement;
        const collapseText = document.getElementsByClassName("collapse-text") as any;
        const collapseListItem = document.getElementsByClassName("collapse-ListItem") as any;
        const nav_items_box = document.getElementsByClassName("nav-items-box") as any;

        if (state.collapse) {
            leftSide.style.width = "200px";
            rightSide.style.marginLeft = "calc(200px + 16px)";
            logo.style.display = "flex";
            collapseArrow.style.margin = "0px";
            collapseArrow.style.width = "45px";
            collapseArrow.style.height = "37px";
            collapseArrow.style.transform = "rotate(0deg)";
            nav_items_box[0].style.height = 'calc(100% - 145px)';

            for (let i = 0; i < collapseText.length; i++) {
                collapseText[i].style.display = "initial";
            }

            for (let i = 0; i < collapseListItem.length; i++) {
                collapseListItem[i].style.paddingLeft = "18px";
            }

        } else {
            leftSide.style.width = "60px";
            rightSide.style.marginLeft = "calc(60px + 16px)";
            logo.style.display = "none";
            collapseArrow.style.margin = "20px 0px";
            collapseArrow.style.transform = "rotate(180deg)";
            collapseArrow.style.width = "40px";
            collapseArrow.style.height = "40px";
            nav_items_box[0].style.height = 'calc(100% - 95px)';

            for (let i = 0; i < collapseText.length; i++) {
                collapseText[i].style.display = "none";
            }

            for (let i = 0; i < collapseListItem.length; i++) {
                collapseListItem[i].style.paddingLeft = "18px";
            }

        }

        // setCollapse(!collapse);
        setState(prevState => {
            return {
                ...prevState,
                collapse: !prevState.collapse
            }
        });
    };

    const handleProfileMenu = (e: MouseEvent<HTMLElement>) => {
        setState(prevState => {
            return {
                ...prevState,
                menuOption: !prevState.menuOption
            }
        });
    }

    const onClose = (e: MouseEvent<HTMLElement>) => {
        setState(prevState => {
            return {
                ...prevState,
                logoutWarning: !prevState.logoutWarning
            }
        });        
    }

    const handleLogout = () => {
       Logout().then(()=> {
           localStorage.removeItem("token");
           enqueueSnackbar("Logged out successfully", {
            variant: "success",
          });
           navigate("/login");
       })
            .catch((err)=> console.log(err))
    }

    return (
      <>
        {/* left sidebar  */}
        <div id="left" className="left-side-nav">
          <div className="center">
            <div id="logo" className="logo-box">
              <img src={Logo} alt="logo" />
            </div>
            <div
              id="collapse"
              className="collapse-arrow"
              onClick={changeSidebarSize}
            >
              <ArrowBackIosIcon className="collapse-arrow-icon w-100 h-100" />
            </div>
          </div>

          <div className="nav-items-box">
            {/* navigation buttons  */}
            <div style={{ overflow: "auto" }}>
              <List>
                <ListItem
                  navigateLink="/dashboard"
                  label="Dashboard"
                  Icon={DashboardIcon}
                />
                <ListItem
                  navigateLink="/users"
                  label="User"
                  Icon={SupervisedUserCircleIcon}
                />
                <ListItem
                  navigateLink="/trips"
                  label="Trips"
                  Icon={CommuteIcon}
                />
                 <ListItem
                  navigateLink="/payouts"
                  label="Payouts"
                  Icon={PaidOutlinedIcon}
                />
                <ListItem
                  navigateLink="/gift-cards/gift-category"
                  label="Gift Cards"
                  Icon={GiftCard}
                />
                <ListItem
                  navigateLink="/payment/all"
                  label="Payment"
                  Icon={PaymentIcon}
                />
                <ListItem
                  navigateLink="/configurations/category"
                  label="Configurations"
                  Icon={ManageAccountsIcon}
                />
                <ListItem
                  navigateLink="/admin-users"
                  label="Admin User"
                  Icon={AdminPanelSettingsIcon}
                />
                <ListItem
                  navigateLink="/broadcast"
                  label="Broadcast"
                  Icon={SendToMobileIcon}
                />
              </List>
            </div>

            {/* profile buttons */}
            <div>
              <Divider className="divider-color" />
              <div>
                <div
                  className={`profile-transition ${
                    state.menuOption ? "margin-top-30" : "profile-buttons-hide"
                  }`}
                >
                  <ListItem
                    label="Settings"
                    navigateLink="/settings/profile"
                    Icon={SettingsIcon}
                  />

                  <div
                    className="profileButtonWrap"
                    id="logout-icon"
                    onClick={onClose}
                  >
                    <ListItemIcon className="navIcons">
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText
                      className="collapse-text"
                      primary={"Logout"}
                      classes={{ primary: "listItem-text" }}
                    />
                  </div>
                </div>

                <div
                  id="profile"
                  className={
                    state.menuOption
                      ? "rotate-icon-down active-profile profile"
                      : "rotate-icon-up profile"
                  }
                  onClick={handleProfileMenu}
                >
                  <div className="profile-name-box">
                    <Avatar className="profile-avatar">A</Avatar>
                    <div className="name collapse-text">Admin</div>
                  </div>
                  <ExpandLessIcon className="collapse-text" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* right side content  */}
        <div id="right" className="right-side-content">
          <div className="top-nav">
            <Typography variant="h5">
              {location.pathname.split("/")[1] === "admin-users"
                ? "Admin User"
                : location.pathname.split("/")[1]}
            </Typography>
          </div>
          <Outlet />
        </div>

        {/* Logout warning  */}
        <WarningDialog
          isOpen={state.logoutWarning}
          onClose={onClose}
          onConfirm={handleLogout}
          title="Logout"
          description="Are you sure you want to logout ?"
        />
      </>
    );
}

export default Layout;
