import './style.scss'
import { Autocomplete, Box, Button, TextField, Tooltip } from '@mui/material';
import { FC, MouseEvent, ReactNode } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';

interface props {
    onSearch: (e:any ) => void;
    searchPlaceholder: string;
    btnText?: string;
    onBtnClick: (e: MouseEvent<HTMLElement>) => void;
    onDelete?: (e: MouseEvent<HTMLElement>) => void;
    isDeleteDisable?: boolean;
    className?: string;
    style?: any;
    children?: ReactNode;
    searchInput?: boolean
    searchSelect?: boolean;
    optionsToSelect?: object[];
    onSelect?:  (e:any, value: any ) => void;
}

const Header: FC<props> = ({
    onSearch,
    searchPlaceholder,
    btnText,
    onBtnClick,
    onDelete,
    isDeleteDisable,
    className,
    style,
    children,
    searchInput,
    searchSelect,
    optionsToSelect = [],
    onSelect
}) => {
    return (
        <Box id='header' justifyContent='space-between' className={`center ${className}`} style={style}>
            <Box className='h-100'>
                {
                    btnText?.length &&
                    <Button
                        color='primary'
                        variant='contained'
                        className='add-btn'
                        startIcon={<AddIcon />}
                        onClick={onBtnClick}
                    >
                        {btnText}
                    </Button>
                }
                {
                    onDelete &&
                    <Tooltip title='Delete'>
                        <Button
                            color='error'
                            variant='outlined'
                            className='h-100'
                            disabled={isDeleteDisable}
                            onClick={onDelete}
                        >
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                }
                {/* <Tooltip title='Filter'>
                    <Button
                        color='primary'
                        variant='outlined'
                        className='ml-2 h-100'
                    >
                        <FilterAltIcon />
                    </Button>
                </Tooltip> */}
                {children}
            </Box>
            {
                searchInput &&
                <Box className='search-box'>
                    <SearchIcon className='search-icon' />
                    <input
                        className="search-input"
                        name='search'
                        placeholder={searchPlaceholder}
                        onKeyUp={onSearch}
                    />
                </Box>
            }
            {
                searchSelect && 
                <Box>
                        <Autocomplete
                            disablePortal
                            id="input-search"
                            freeSolo
                            options={optionsToSelect}
                            sx={{ width: 300 }}
                            onInputChange={onSearch}
                            onChange={onSelect}
                            renderInput={(params) => <TextField {...params} label="Search by driver name" />}
                        />
                </Box>
            }

        </Box>
    )
}

Header.defaultProps = {
    className: '',
    onDelete: undefined,
    isDeleteDisable: true,
    style: {}
};

export default Header;