import { ISearchLocation } from "../../../interfaces/configuration/favourite-location";
import { httpRequest } from "../../Network";
import { configuration } from "../../endpoints";

export const getLocation = (filter:object) => {
    return new Promise<any>((resolve, reject) => {
        httpRequest("GET", configuration,{},filter).then((response) => {
            resolve(response);
        }).catch((er) => {
            reject(er);
        })
    })
}

export const getLocationCategoryById = (id:string) => {
    return httpRequest('GET',`/configuration/location/${id}`)
    .then((res)=> res)
    .catch((err)=> err)
}
export const addLocation = (payload:object) => {
    return new Promise<any>((resolve, reject) => {
        httpRequest("POST", "/configuration/location",payload).then((response) => {
            resolve(response)
        }).catch((er) => {
            reject(er);
        })
    })
}
export const updateLocationStatus = (payload: any) => {
    return new Promise<any>((resolve, reject) => {
        httpRequest("PUT", "/configuration/location/status", payload).then((response) => resolve(response)).catch((er) => console.log);
    })
}
export const upDateLoation = (payload:object) => {
    return new Promise((resolve, reject) => {
        httpRequest("PUT", "/configuration/location", payload).then((response) =>
            resolve(response)
        ).catch((er) => reject(er));
    })
}
export const deleteLocation = (payload: any) => {
    return new Promise((resolve, reject) => {
        httpRequest("DELETE", "/configuration/location", payload).then((response) =>
            resolve(response)
        ).catch((er) => reject(er));
    })
}
export const searchLocation = (status: string, searchLocation: string) => {
    return new Promise((resolve, reject) => {
        httpRequest("GET", `/configuration/location?status=${status}?searchValue=${searchLocation}`).then((response) =>
            resolve(response)).catch((er) => reject(er))
    })
}
export const orderCheck = (payload:object) => {
    return new Promise((resolve, reject) => {
        httpRequest("POST", "/configuration/location/order-check",payload).then((response) => resolve(response)
        ).catch((er) => { console.log("Error for order-check", er); reject(er) })
    })
}
export const searchFavouriteLocation = (status: string, searchValue: string) => {
    return new Promise<ISearchLocation | any>((resolve, reject) => {
        httpRequest("GET", `/configuration/location?status=${status}&searchValue=${searchValue}`)
        .then((response) => response)
        .catch((err) =>{
            throw err
        });
    })
}

