import { Grid, Button, Box} from '@mui/material';
import { useState,useEffect,ChangeEvent } from 'react';
import { StyledTextField } from '../../user/manage';
import { getCarbonConfig,updateCarbonConfig } from '../../../services/configurations/carbon-config';
import { useSnackbar } from 'notistack';
import Loader from '../../../components/mui/loader/Loader';
import './index.scss'
const CarbonSaving = () => {
  const [carbonConfig, setCarbonConfig] = useState('');
  const {enqueueSnackbar} = useSnackbar()
  const [loading,setLoading] = useState(true)
  const [error,setError] = useState({
    required:false,
    maxLen:false,
  })
  useEffect(()=>{
   getCarbonConfig()
   .then((res:any)=>{
    setCarbonConfig(res?.data?.carbonFootprint)
    setLoading(false)
   })
   .catch((err)=>{
    setLoading(false)
    enqueueSnackbar("No data found",{variant:"error"})
   })
   //eslint-disable-next-line
  },[])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { 
    setCarbonConfig(e.target.value);
  };

  const handleUpdate = () => {
    if(!carbonConfig){
      return setError((prev)=>{
       return{
        ...prev,
        required:true
       }
      })
    }
    const payload = {
      carbonFootprint: carbonConfig
    }
    updateCarbonConfig(payload)
      .then(() =>{
      setError((prev)=>{
        return{
         ...prev,
         required:false
        }
       })
        enqueueSnackbar("Updated Successfully", { variant: "success" })
      })
      .catch(() =>
        enqueueSnackbar("Couldn't update data", { variant: "error" })
      );
  }

  return (
    <>
    {loading && <Loader/>}
      <div className="stripe" style={{ marginTop: "24px" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6} className="grid">
            <StyledTextField
              label="CO2 saved per mile (unit - grams)"
              variant="outlined"
              value={carbonConfig ?? ""}
              onChange={(e) => {
                const re = /^[0-9\b]+$/; // regex to match only digits
                if (e.target.value === "" || re.test(e.target.value)) {
                  handleChange(e);
                }
              }}
            />
            {error.required && (
              <small className="other-error-message">
                This field is required
              </small>
            )}
            <Box className="mt-2" textAlign="end">
              <Button size="medium" type="submit" onClick={handleUpdate}>
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default CarbonSaving;