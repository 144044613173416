import "./style.scss";
import { useState, FC, useEffect } from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
//import dayjs from "dayjs";
import Tag from "./components/tags";
import BarGraph from "../../components/graphs/bar-graph";
import CustomTable from "../../components/mui/table";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../services/User";
import { useLanguage } from "../../components/language-provider";
import { IData, IState } from "../../interfaces/dashboard";
import Loader from "../../components/mui/loader/Loader";
import { useSnackbar } from "notistack";
import {
  getTripDashboardCount,
  getUsersData,
  getTripsData,
} from "../../services/dashboard";
import { IFilter } from "../../interfaces/dashboard";
import { getTimeRange, sortByDate, getDaysFromDates, findNameByLanguage, getMonthsName, capitalizeFirstLetter } from "../../utils";

const columns = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone",
  },
];
const Dashboard: FC = () => {
  const [state, setState] = useState<IState>({
    startDate: "",
    endDate: "",
    selectedTag: "",
    totalCount: {
      trips: 0,
      users: 0,
      pendingTripCount: 0,
    },
    list: [],
    labels: [],
    datasets: [],
    loader: true,
    filterType: "last week",
  });
  const { enqueueSnackbar } = useSnackbar();
  const {language} = useLanguage()

  useEffect(() => {
    const dateRange = getTimeRange(state.filterType);
    const filter: IFilter = {
      start: dateRange?.start,
      end: dateRange?.end,
      yearly: state.filterType === "last year" ? true : undefined,
    };

    if (state.selectedTag === "Total Users") {
      // setState((prev)=>{
      //   return{
      //     ...prev,
      //     labels:[],
      //     datasets:[]
      //   }
      // })
      getUsersData(filter)
        .then((res: any) => {
          const data = sortByDate(res.data);
          setState((prev) => ({
            ...prev,
            labels: data.map((data: any) => getDaysFromDates(data.date)),
            datasets: data.map((dataset: any) => dataset.count),
          }));
          if (filter.yearly) {
            const data = res.data
            // const startDate = dayjs(filter?.start).format("MMM D YYYY");
            // const endDate = dayjs(filter?.end).format("MMM D YYYY");
            // const dateRange = `${startDate} - ${endDate}`;
            setState((prev) => ({
              ...prev,
              labels: data.map((data:any)=> getMonthsName(data.date)),
              datasets: data.map((dataset:any)=> dataset.count)
            }));
          }
        })
        .catch((err) => console.log(err));
    } else if (
      state.selectedTag === "Total Trips" ||
      state.selectedTag === "Pending Trips"
    ) {
      // setState((prev)=>{
      //   return{
      //     ...prev,
      //     labels:[],
      //     datasets:[]
      //   }
      // })
      const status =
        state.selectedTag === "Pending Trips" ? "PENDING" : undefined;
      filter.status = status;
      getTripsData(filter)
        .then((res: any) => {
          const data = sortByDate(res.data);
          setState((prev) => ({
            ...prev,
            labels: data.map((data: any) => getDaysFromDates(data.date)),
            datasets: data.map((dataset: any) => dataset.count),
          }));
          if (filter.yearly) {
            const data = res.data
            // const startDate = dayjs(filter?.start).format("MMM D YYYY");
            // const endDate = dayjs(filter?.end).format("MMM D YYYY");
            // const dateRange = `${startDate} - ${endDate}`;
            setState((prev) => ({
              ...prev,
              labels: data.map((data:any)=> getMonthsName(data.date)),
              datasets: data.map((dataset:any)=> dataset.count)
            }));
          }
        })
        .catch((err) => console.log(err));
    }
  }, [state.selectedTag, state.filterType]);


  const handleTagsChange = (name: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedTag: name,
      };
    });
  };
  const createRow = (user: IData, index: number) => {
    return {
      SrNo: index + 1,
      name: (
        <p style={{ wordWrap: "break-word" }}>
          {capitalizeFirstLetter(findNameByLanguage(language, user.firstName)) +
            " " +
           capitalizeFirstLetter(findNameByLanguage(language, user.lastName))}
        </p>
      ),
      email: <p style={{ wordWrap: "break-word" }}>{user.email}</p>,
      phone: user.phone,
    };
  };
  const fetchData = () => {
    Promise.all<any>([
      getTripDashboardCount(),
      getAllUsers({ roleType: "ALL", status: "ALL", page: 1 }),
    ])
      .then((response) => {
        let tripDashboardCountResponse = response[0];
        let allUsersResponse = response[1];
        let list: {
          SrNo: number;
          email: string;
          name: string;
          phone: string;
        }[] = allUsersResponse.data.map((key: IData, index: number) =>
          createRow(key, index)
        );
        setState({
          ...state,
          loader: false,
          totalCount: {
            ...state.totalCount,
            trips: tripDashboardCountResponse.data.totalTrips,
            users: tripDashboardCountResponse.data.totalUsers,
            pendingTripCount: tripDashboardCountResponse.data.pendingTripCount,
          },
          list,
          selectedTag: "Total Users",
        });
      })
      .catch((er) =>
        enqueueSnackbar("Something went wrong please try again later", {
          variant: "error",
        })
      );
  };

  /* eslint-disable */
  useEffect(() => {
    fetchData();
  }, []);
  /* eslint-enable */

  const handleFilterChange = (e: any) => {
    const name = e.target.value;
    setState((prev) => {
      return {
        ...prev,
        filterType: name,
      };
    });
  };
  return (
    <div>
      <Box className="tags" marginBottom="30px">
        {/* <Typography
          className="head"
          variant="h5"
          marginBottom="10px"
          sx={{ fontWeight: "600" }}
        >
          Overview
        </Typography> */}
        <Box
          className="overview-tabs"
          display="flex"
          border="1px solid #c5c5c5"
        >
          <Tag
            name="Total Users"
            select={state.selectedTag}
            count={state.totalCount.users}
            onClick={handleTagsChange}
            id={"first"}
          />
          <Tag
            name="Total Trips"
            select={state.selectedTag}
            count={state.totalCount.trips}
            onClick={handleTagsChange}
            id={"second"}
          />
          <Tag
            name="Pending Trips"
            select={state.selectedTag}
            count={state.totalCount.pendingTripCount}
            onClick={handleTagsChange}
            id={"third"}
          />
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{paddingRight:'16px'}}>
          <Box
            className="center"
            marginBottom="10px"
            justifyContent="space-between"
          >
             {/* <Typography className="head" variant="body1">
             Analytics ({`${state.filterType}`})
              </Typography> */}
            <Typography
              className="head"
              variant="h6"
              marginBottom="5px"
              sx={{ fontWeight: "600" }}
            >
              Analytics ({`${state.filterType}`})
            </Typography>
            <div className="date-picker position-relative">
              <FormControl fullWidth>
                <Select
                  variant="outlined"
                  size="small"
                  defaultValue="last week"
                  // onChange={(e) => selectDuration(e)}
                  onChange={(e) => handleFilterChange(e)}
                >
                  {/* <MenuItem value={0} disabled>Choose Duration</MenuItem> */}
                  <MenuItem value="last week">Last Week</MenuItem>
                  <MenuItem value="last month">Last Month</MenuItem>
                  <MenuItem value="last year">Last Year</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Box>
          <BarGraph
            data={{
              labels: state.labels,
              datasets: [
                {
                  label: state.selectedTag,
                  data: state.datasets,
                  backgroundColor: ["rgba(158, 229, 193, 0.3)"],
                },
              ],
            }}
            height={330}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            className="center"
            marginBottom="18px"
            justifyContent="space-between"
          >
            <Typography className="recent-applications m-0" variant="h6">
              Recent Customers
            </Typography>
            <Link className="more-details underline-none" to="/users">
              More Details
            </Link>
          </Box>
          <CustomTable columns={columns} rows={state.list} height={375} />
        </Grid>
      </Grid>
      {state.loader && <Loader />}
    </div>
  );
};

export default Dashboard;