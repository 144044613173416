import { LOAD_CUSTOMER } from "../../actions";

const initialState = {
  userList: []
};

export const customerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOAD_CUSTOMER:{
      return {...state, userList:action.payload}
    }
    default: {
      return state;
    }
  }
};

