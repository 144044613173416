import "./style.scss";
import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, Link } from "@mui/material";
import Logo from "../../../assets/images/logo.png";
import { Auth } from "../../../services/Login";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IProfileData } from "../../../interfaces/profile";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { StyledTextField } from "../../user/manage";


interface IErrorField {
  email: boolean;
  emailRequired: boolean;
  password: boolean;
}



export const CustomTextField = styled(StyledTextField)({
  '& .MuiInputBase-input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 100px #fff inset',
    '-webkit-text-fill-color': '#000 !important',
    'caret-color': 'inherit',
  },
  '& .MuiInputBase-input:focus:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 100px #fff inset',
    '-webkit-text-fill-color': '#000 !important',
    'caret-color': 'inherit',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: '#000',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#000',
  },
});

const Login = () => {
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<IErrorField>({
    email: false,
    emailRequired: false,
    password: false,
  });

  /*eslint-disable*/
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  /*eslint-enable*/
  const onLogin = (event: SyntheticEvent) => {
    event.preventDefault();
    if (!loginInfo.password && !loginInfo.email) {
      return setError((prev) => {
        return {
          ...prev,
          email: true,
          password: true,
        };
      });
    }
    if (!loginInfo.email) {
      return setError((prev) => {
        return {
          ...prev,
          email: true,
          password:false
        };
      });
    }
    if (!loginInfo.password) {
      console.log("Here")
      return setError((prev) => {
        return {
          ...prev,
          email:false,
          password: true,
        };
      });
    }
    setError((prev) => {
      return {
        ...prev,
        email: false,
        password: false,
      };
    });
    Auth(loginInfo)
      .then((response: IProfileData) => {
        if (response.token) {
          localStorage.setItem("token", response.token);
          window.location.reload();
          enqueueSnackbar("Logged in successfully", {
            variant: "success",
          });
        }
      })
      .catch((err: any) => {
        enqueueSnackbar("Invalid Credentials", {
          variant: "error",
        });
      });
  };

  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if(error){
      setError((prev)=>{
       return{
        ...prev,
        email:false,
        emailRequired:false,
        password:false
       }
      })}
    setLoginInfo((prevLoginInfo) => ({
      ...prevLoginInfo,
      [name]: value,
    }));
  };

  useEffect(()=>{
    console.log(loginInfo)
    // eslint-disable-next-line
  },[loginInfo.email])

  return (
    <div className="container center">
      <div className="login-box">
        <form className="h-100" onSubmit={onLogin}>
          <Grid className="h-100" container>
            <Grid className="h-100" xs={12} md={6}>
              <img alt="Login" id="left-img" src={Logo} />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="credential-box center h-100">
                <div className="mb-5 text-center">
                  <Typography variant="h4" sx={{fontSize:'1.25rem'}}>Welcome to</Typography>
                  <Typography variant="h5" color="primary" sx={{fontSize:'2rem',fontWeight:"800"}}>
                    EcoPooling
                  </Typography>
                </div>
                <div className="email-box grid-box">
                  <CustomTextField
                    label="Email"
                    className="mb-3"
                    name="email"
                    type="email"
                    value={loginInfo.email}
                    onChange={handleChange}
                  />
                  {error.email && (
                    <p className="auth-error-message">Email is required</p>
                  )}
                </div>
                <div className="password-box grid-box mt-2">
                  <CustomTextField
                    label="Password"
                    name="password"
                    type={!passwordVisibility ? "password" : "text"}
                    className="mb-3"
                    value={loginInfo.password}
                    onChange={handleChange}
                  />
                  <span
                    className={passwordVisibility ? "visibility" : "hide-eye"}
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  >
                    <Visibility className="visibility-icon"  sx={{color:"#707070"}} />
                  </span>
                  <span
                    className={
                      !passwordVisibility ? "visibility-off" : "hide-eye"
                    }
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  >
                    <VisibilityOff className="visibility-officon" sx={{color:"#707070"}}/>
                  </span>
                  {error.password && (
                    <p className="auth-error-message">Password is required</p>
                  )}
                </div>
                <div className="mb-3 forgot-password" style={{width:'100%'}}>
                <Link
                  className=""
                  color={"blue"}
                  href="/forgot-password"
                  underline="none"
                  sx={{width:"100%"}}
                >
                  Forgot Password?
                </Link>
                </div>
                <Button type="submit" className="mb-3" size="medium" fullWidth sx={{textTransform:'none'}}>
                  Login
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default Login;
