

import { Button, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Loader from '../../components/mui/loader/Loader'
import { getBroadcastTemplates, sendBroadcastTemplate } from '../../services/broadcast'
import { enqueueSnackbar } from 'notistack'
import { BroadcastResponse } from '../../interfaces/broadcast'

const Broadcast = () => {
    const [broadcastTemplates, setBroadcastTemplates] = useState<{ templateName: string, templateText: string }[] | []>([])
    const [selectedTemplate, setSelectedTemplate] = useState("SELECT")
    const [loading, setLoading] = useState(true)

    // fetch the templates data
    useEffect(() => {
        // make an API request to get the templates
        getBroadcastTemplates()
        .then((res) => {
            const templateData = (res as { data: BroadcastResponse[] }).data.map(obj => ({
                templateName: obj.templateId,
                templateText: obj.configuration.components.find((component: any) => component.type === "BODY")?.text ?? ""
            }))

            setBroadcastTemplates(templateData)
        })
        .catch(() => {
            enqueueSnackbar("Unable to fetch data at the moment", {variant:"error"})
        })
        .finally(() => {
            setLoading(false)
        })
    }, [])

    const handleSelectTemplate = (event: SelectChangeEvent) => {
        setSelectedTemplate(event.target.value)
    }

    const handleBroadcastButton = () => {
        setLoading(true)

        sendBroadcastTemplate({templateId: selectedTemplate })
        .then(() => {
            enqueueSnackbar("broadcast successful", {variant:"success"})
        })
        .catch(() => {
            enqueueSnackbar("Unable to broadcast at the moment", {variant:"error"})
        })
        .finally(() => {
            setLoading(false)
        })
    }

  return (
    <div className='d-flex flex-column justify-center items-center border rounded-5 w-50 m-1-auto p-1 px-2'>
        {
            loading && <Loader />
        }

        <Select
            size="small"
            value={selectedTemplate}
            onChange={handleSelectTemplate}
            sx={{ width: '100%', overflow: 'hidden' }}
            className='mb-3'
        >
            <MenuItem value="SELECT" disabled>Select template</MenuItem>

            {
                broadcastTemplates.map((template: any, index: number) => (
                    <MenuItem key={index} value={template.templateName}>{template.templateName}</MenuItem>
                ))
            }
        </Select>

        <TextField
            value={broadcastTemplates.find(template => template.templateName === selectedTemplate)?.templateText}
            multiline={true}
            minRows={4}
            placeholder='Template text'
            className='mb-3'
            disabled
        />

        {/* bottom buttons */}
        <div className='d-flex justify-end width-100'>
            <Button variant="contained" color="primary" className='w-50 p-1' sx={{margin: "auto"}} onClick={handleBroadcastButton} disableElevation>
                Broadcast
            </Button>
        </div>
    </div>
  )
}

export default Broadcast