import { ChangeEvent, useEffect, useState } from "react";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import { IconButton, Switch, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getAllGiftCategories,
  updateGiftCategoryStatus,
  deleteGiftCategory
} from "../../../services/Gift Category";
import { useSnackbar } from "notistack";
import { capitalizeFirstLetter, findNameByLanguage } from "../../../utils";
import { useLanguage } from "../../../components/language-provider";
import ManageCategory from "./add-category";
import Loader from "../../../components/mui/loader/Loader";
import WarningDialog from "../../../components/mui/warning-dialog";


const columns = [
  {
    id: "name",
    label: "Gift Category Name",
  },
  {
    id: "order",
    label: "Order",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];

const GiftCategory = () => {
  const [state, setState] = useState({
    manage: {
      type: "new",
      isOpen: false,
    },
    deleteWarning: false,
    _category: "",
    type: "",
    list: [],
    filterType: "ALL",
    page: 1,
    totalPage: 1,
    searchLoader: false,
    initialState: "",
    searchItem: false,
    categoryDialog: false,
    addLocationDialog: false,
    categoryId: "",
    loading:true
  });
  
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLanguage();

  const onPageChange = (e: ChangeEvent<unknown>, pageNumber: number) => {
    setState({ ...state, page: pageNumber });
  };

  const handleManage = (type: "new" | "edit",id:string) => {
    setState((prev) => {
      return {
        ...prev,
        categoryDialog: !prev.categoryDialog,
        _category: id,
        type,
      };
    });
  };
  const handleDeleteWarning = (id: string) => {
    setState((prev) => {
      return {
        ...prev,
        deleteWarning: !prev.deleteWarning,
        _category: id,
      };
    });
  };

  const updateStatus = (categoryId: string, event:ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newStatus = isChecked ? "ACTIVE" : "INACTIVE";
    const body = {
      giftStatus: newStatus,
    };
    updateGiftCategoryStatus(categoryId,body).then(() => {
      enqueueSnackbar("Status Updated", {
        variant: "success"
      });
      fetchData();
    });
  };


  const createRow = (category:any,  handleManage:(type: "new" | "edit",id:string)=> void,
    handleDeleteWarning: (id: string) => void) => {
    const name = capitalizeFirstLetter(findNameByLanguage(language,category.giftCategoryName));
    const status =  <Switch
    checked={category.giftStatus === "ACTIVE" ? true : false}
    onChange={(e) => updateStatus(category._id, e)}
  />
    const order = category.order;
    const action = (
      <>
      <Tooltip title="Edit">
        <IconButton
          color="primary"
          onClick={() => handleManage('edit',category._id)}
        >
          <EditIcon />
        </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
        <IconButton
          color="error"
          onClick={() => handleDeleteWarning(category._id)}
        >
          <DeleteIcon />
        </IconButton>
        </Tooltip>
      </>
    )
    return {
      name,
      status,
      order,
      action
    };
  };

  const handleClose = () => {
    setState((prev) => {
      return {
        ...prev,
        categoryDialog: false,
      };
    });
  };

  const onDelete = () => {
    deleteGiftCategory(state._category)
      .then(() =>{
        enqueueSnackbar("Deleted successfully", {
          variant: "success",
        })
        handleDeleteWarning('')
        fetchData()
      }   
      )
      .catch(() =>
        enqueueSnackbar("Couldn't delete data", {
          variant: "error",
        })
      );
  };

  const fetchData = (searchValue?:string) => {
    // setState((prev)=>{
    //   return{
    //     ...prev,
    //     loading:true
    //   }
    // })
    //eslint-disable
    getAllGiftCategories({page:searchValue ? 1 : state.page,searchValue:searchValue})
    .then((res:any)=> {
      const list = res.data.map((data:any,index:number)=>createRow(data,handleManage,handleDeleteWarning))
      setState((prev)=> {
        return{
          ...prev,
          list : list?.sort((a:any,b:any)=> a.order - b.order),
          loading:false,
          page : res.meta.currentPage,
          totalPage: res.meta.totalPage
        }
      })
    })
    .catch((err:any)=>{
      console.log(err.response.data.message)
      enqueueSnackbar(err.response.data.message,{variant:"error"})
    } )
  }
  //eslint-enable

  useEffect(()=> {
    fetchData()
    // eslint-disable-next-line
  },[state.page])

  const handleSearch = (e: ChangeEvent<unknown> | any) => {
    const searchValue = e.target.value;
    if (e.key === "Enter") {
      fetchData(searchValue);
    }
    if (searchValue === "" && e.key === "Enter") {
      fetchData();
    }
  };

  return (
    <>
    {state.loading && <Loader/>}
      <Header
        className="my-2"
        searchPlaceholder="Search by category name"
        btnText="Add Category"
        onSearch={handleSearch}
        onBtnClick={() => handleManage("new","")}
        searchInput
      ></Header>

      <CustomTable
        columns={columns}
        rows={state.list}
        height="calc(100vh - 248px)"
        errorMessage="Add gift categories to see data here"
        pagination={{
          page: state.page,
          totalPages: state.totalPage,
        }}
        onPageChange={onPageChange}
        searchLoader={state.searchLoader}
      />

      <ManageCategory
        isOpen={state.categoryDialog}
        type={state.type}
        id= {state._category}
        onClose={handleClose}
        fetchData={fetchData}
      />

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDeleteWarning("")}
        onConfirm={onDelete}
        title="Delete category"
        description="Are you sure do you want to delete this category?"
      />
    </>
  );
};

export default GiftCategory;