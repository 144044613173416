import { ChangeEvent, useEffect, useState } from "react";
import Header from "../../components/header";
import {
  FormLabel,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomTable from "../../components/mui/table";
import Download  from "@mui/icons-material/Download";
import Manage from "./manage";
import WarningDialog from "../../components/mui/warning-dialog";
import ExportDialog from "../../components/mui/dialog/exportDialog";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { capitalizeFirstLetter, formatDate } from "../../utils";
import { DatePicker } from "@mui/x-date-pickers";
import { getTrips, deleteTrip, exportTrips, exportSeatBookings } from "../../services/Trips";
import { findNameByLanguage } from "../../utils";
import { useLanguage } from "../../components/language-provider";
import { getAllUsers } from "../../services/User";
import dayjs from "dayjs";
import "./index.scss";
import Loader from "../../components/mui/loader/Loader";
import { ITripStates } from "../../interfaces/trips";
import { tripColumns as Columns } from "../../utils";
import { useSnackbar } from "notistack";
import { formatAddress } from "../../utils";


const Trip = () => {
  const { language } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<ITripStates>({
    manage: {
      type: "new",
      isOpen: false,
      id: "",
    },
    add: {
      isOpen: false,
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    _trip: "",
    list: [],
    selectAll: [],
    exportTrip: false,
    status: "ALL",
    _users: [],
    search: "",
    page: 1,
    totalPage: 1,
    loading: true,
    startDate: undefined,
    endDate: undefined,
    seatBookingStartDate: undefined,
    seatBookingEndDate: undefined,
    seatBookingExport: false
  });

  const fetchData = (searchValue?:string) => {
    const filter = {
      tripStatus: state.status,
      page: searchValue ? 1 : state.page,
      searchValue: searchValue
    };
    getTrips(filter)
      .then((response: any) => {
        const data = response.data;
       
        setState((prevState) => {
          return {
            ...prevState,
            list : [...data],
            selectAll: [],
            totalPage: response.meta.totalPage,
            loading: false,
          };
        });
      })
      .catch((err) => console.log(err));
  };
  /*eslint-disable*/
  useEffect(() => { 
      fetchData();
  }, [
    state.status,
    state.search,
    state.page,
  ]);

  useEffect(() => {
    getAllUsers({ roleType: "ALL", status: "ALL", limit: 10000 })
      .then((res) => {
        const userData = res.data;
        const userList = userData.map((user: any, index: number) => ({
          id: user._id,
          label:
            findNameByLanguage(language, user.firstName) +
            " " +
            findNameByLanguage(language, user.lastName),
        }));
        setState((prev) => {
          return {
            ...prev,
            _users: userList,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  /*eslint-enable*/
  const handleSearch = (event:ChangeEvent<unknown>|any) => {
    const searchValue = event.target.value;
    if (event.key === "Enter") {
      fetchData(searchValue);
    }
  };

  const onPageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    setState({ ...state, page: newPage });
  };

  const handleAdd = () => {
    setState((prevState) => {
      return {
        ...prevState,
        add: {
          ...prevState.add,
          isOpen: !prevState.add.isOpen,
        },
      };
    });
  };

  const handleManage = (type: "new" | "edit" | "view") => {
    setState((prevState) => {
      return {
        ...prevState,
        manage: {
          ...prevState.manage,
          isOpen: !prevState.manage.isOpen,
          type,
        },
      };
    });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];

    if (e.target.checked) {
      if(state.list?.length){
        payload = state.list.map((ele:any) => ele._id.toString());
      }
    } else {
      payload = [];
    }
    const updatedList = state.list.map((items:any)=> (
      {...items,all:(
        <Checkbox
          sx={{color:"#bdbdbd"}}
          onChange={(e) =>handleMultipleDelete(e, state.selectAll,( items.id).toString())}
          checked={payload.includes(items._id.toString()) ? true : false}
        />
      )}
    ))
    setState((prevState) => {
      return {
        ...prevState,
        selectAll: payload,
        list : updatedList
      };
    });
  };

  const handleMultipleDelete = (
    e: ChangeEvent<HTMLInputElement>,
    selectAll: string[],
    id: string
  ) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = selectAll;
      payload.push(id);
    } else {
      payload = selectAll.filter((ele) => ele !== id);
    }

    setState((prevState) => {
      return {
        ...prevState,
        selectAll: payload,
      };
    });
  };

  const handleMultiDelete = () => {
    setState((prevState) => {
      return {
        ...prevState,
        multiDeleteWarning: !prevState.multiDeleteWarning,
      };
    });
  };

  const handleDelete = (_trip: string = "") => {
    setState((prevState) => {
      return {
        ...prevState,
        deleteWarning: !prevState.deleteWarning,
        _trip,
      };
    });
  };

  const handleStatusChange = (
    event: React.ChangeEvent<{ value: unknown }> | any
  ) => {
    const selectedStatus = event.target.value as string;
    setState((prev) => {
      return {
        ...prev,
        status: selectedStatus,
        page : 1
      };
    });
  };

  const handleExport = () => {
    const payload = {
      startDate: (state.startDate).toISOString() ,
      endDate: (state.endDate).toISOString(),
    };
    if (!state.startDate || !state.endDate) {
      return enqueueSnackbar("Select start date and end date first", {
        variant: "error",
      });
    }
    exportTrips(payload)
      .then((res:any) => {        
        enqueueSnackbar("Data exported", {
          variant: "success",
        });
        handleClose();
        window.open(res.data,"_self")
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
        handleClose();
      });
  };

  const handleSeatBookingsExport = () => {
    const payload = {
      startDate: (state.seatBookingStartDate).toISOString() ,
      endDate: (state.seatBookingEndDate).toISOString(),
    };
    if (!state.seatBookingEndDate || !state.seatBookingStartDate) {
      return enqueueSnackbar("Select start date and end date first", {
        variant: "error",
      });
    }

    exportSeatBookings(payload)
      .then((res:any) => {        
        enqueueSnackbar("Data exported", {
          variant: "success",
        });
        handleSeatBookingsExportClose();
        window.open(res.data,"_self")
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
        handleSeatBookingsExportClose();
      });
  }
 
  const onDelete = () => {
    const data = {
      ids: state._trip,
    };
    deleteTrip(data)
      .then(() => {
        setState((prevState) => {
          return {
            ...prevState,
            deleteWarning: !prevState.deleteWarning,
          };
        });
        fetchData();
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const onMultipleDelete = () => {
    const data = {
      ids: state.selectAll,
    };
    deleteTrip(data).then(() => {
      setState((prevState) => {
        return {
          ...prevState,
          multiDeleteWarning: !prevState.multiDeleteWarning,
        };
      });
      fetchData();
    });
  };

  const columns = [
    {
      id: "all",
      label: (
        <Checkbox
        sx={{color:"#bdbdbd"}}
          onChange={handleSelectAll}
          checked={
            state.selectAll.length &&
            state.list.length === state.selectAll.length
              ? true
              : false
          }
        />
      ),
    },
    ...Columns,
  ];

  const createRow = (
    selectAll: string[],
    trip: any,
    index: number,
    onEdit: any,
    onDelete: any,
    onMultiDelete: any
  ) => {
    return {
      all: (
        <Checkbox
        sx={{color:"#bdbdbd"}}
          onChange={(e) => onMultiDelete(e, selectAll, trip._id.toString())}
          checked={selectAll.includes(trip._id.toString()) ? true : false}
        />
      ),
      id: trip._id,
      tripId: (
        <Typography
          className="cursor-pointer"
          color="primary"
          variant="body1"
          style={{fontFamily:"Space Grotesk"}}
          onClick={() => {
            onEdit("view");
            setState((prevState) => ({
              ...prevState,
              manage: {
                ...prevState.manage,
                data: trip,
                id: trip._id,
              },
            }));
          }}
        >
          {trip.uniqueId}
        </Typography>
      ),
      name: (
        <p>
          {capitalizeFirstLetter(
            findNameByLanguage(language, trip?._driver?.firstName)
          )}{' '}
          {capitalizeFirstLetter(
            findNameByLanguage(language, trip?._driver?.lastName)
          )}
        </p>
      ),
      from: (
        <>
        <Tooltip title={findNameByLanguage(language,trip.pickUp.line1)}>
          <p>{formatAddress(trip.pickUp.line1, language)}</p>
        </Tooltip>
        </>
      ),
      to: (
        <Tooltip title={findNameByLanguage(language,trip.destination.line1)}>
          <p>{formatAddress(trip.destination.line1, language)}</p>
        </Tooltip>
      ),
      scheduledDate:<p>{formatDate(trip?.scheduleDate)}</p> ,
      // dropDate: <p>{formatDate(trip?.dropDate)}</p>,
      inviteAccepted: <p>
        {
        getInvitedDates(trip?.invites)
          }
        </p>,
      status: <p>{capitalizeFirstLetter(trip.tripStatus)}</p> ,
      action: (
        <>
          <Tooltip title="delete">
            <IconButton onClick={() => onDelete(trip._id)} color="error">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    };
  };

  // concatenate all invited dates in string
  const getInvitedDates = (invites: string[]) => {
    let dateString = "";

    for(let invite of invites) {
      dateString += formatDate(invite) + ", "
    }

    // remove trailing comma and space
    return dateString.slice(0, -2)
  }


  const handleClose = () => {
    setState({ ...state,startDate:undefined,endDate:undefined, exportTrip: !state.exportTrip });
  };

  const handleSeatBookingsExportClose = () => {
    setState({...state, seatBookingStartDate: undefined, seatBookingEndDate: undefined, seatBookingExport: !state.seatBookingExport})
  };

  return (
    <>
      {state.loading && <Loader />}
      <Header
        searchPlaceholder="Search by driver name"
        onSearch={handleSearch}
        onBtnClick={handleAdd}
        onDelete={handleMultiDelete}
        isDeleteDisable={state.selectAll.length ? false : true}
        searchInput
      >
        <Tooltip title="Export Trips">
          <Button
            color="primary"
            variant="outlined"
            className="ml-2 h-100"
            onClick={() =>
              setState({ ...state, exportTrip: !state.exportTrip })
            }
          >
           <Download/>
          </Button>
        </Tooltip>
        <Tooltip title="Export Seat Bookings">
          <Button
            color="primary"
            variant="outlined"
            className="ml-2 h-100"
            onClick={() =>
              setState({ ...state, seatBookingExport: !state.seatBookingExport })
            }
          >
           <Download/>
          </Button>
        </Tooltip>
        <FormControl className="ml-2" size="small">
          <Select
            size="small"
            defaultValue="ALL"
            onChange={(event) => handleStatusChange(event)}
          >
            <MenuItem value="ALL">All Status</MenuItem>
            <MenuItem value="COMPLETED">Completed</MenuItem>
            <MenuItem value="PENDING">Pending</MenuItem>
            <MenuItem value="CANCELLED">Cancelled</MenuItem>
            <MenuItem value="EXPIRED">Expired</MenuItem>
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
        <CustomTable
          columns={columns}
          rows={state.list.map((user: object, index: number) =>
            createRow(
              state.selectAll,
              user,
              index,
              handleManage,
              handleDelete,
              handleMultipleDelete
            )
          )}
          height="calc(100vh - 193px)"
          errorMessage="No trips available"
          pagination={{
            page: state.page,
            totalPages: state.totalPage,
          }}
          onPageChange={onPageChange}
        />
      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        id={state.manage.id}
        onClose={() => handleManage(state.manage.type)}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Trip"
        description="Are you sure do you want to delete this trip?"
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onMultipleDelete}
        title="Delete Trips"
        description="Are you sure do you want to delete all selected trips?"
      />

      {/* Export Data  */}
      <ExportDialog
        title="Export Trips"
        open={state.exportTrip}
        onConfirm={handleExport}
        onClose={() => handleClose()}
        btnText="Export Trips"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="date-container">
            <FormLabel sx={{marginBottom:'5px'}} className="date-label">Start Date</FormLabel>
            <DatePicker
              label={state.startDate ? "" : "Select Start Date"}
              className="start-date"
              value={state.startDate}
              minDate={
                state.endDate
                  ? dayjs(state.endDate).subtract(1, "month")
                  : undefined
              }
              maxDate={dayjs(new Date())}
              onChange={(newValue) =>
                setState((prev) => {
                  return {
                    ...prev,
                    startDate: (newValue),
                  };
                })
              }
            />
          </div>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="date-container">
            <FormLabel sx={{marginBottom:'5px',marginTop:'5px'}} className="date-label">End Date</FormLabel>
            <DatePicker
              label={state.endDate ? "" : "Select End Date"}
              className="end-date"
              value={state.endDate}
              minDate={state.startDate ? dayjs(state.startDate) : ""}
              maxDate={
                state.startDate &&
                dayjs().diff(dayjs(state.startDate), "day") > 31
                  ? dayjs(state.startDate).add(1, "month")
                  : dayjs(new Date())
              }
              onChange={(newValue) =>
                setState((prev) => {
                  return {
                    ...prev,
                    endDate: (newValue),
                  };
                })
              }
            />
          </div>
        </LocalizationProvider>
      </ExportDialog>
      {/* Export Data  */}
      <ExportDialog
        title="Export Seat Bookings"
        open={state.seatBookingExport}
        onConfirm={handleSeatBookingsExport}
        onClose={() => handleSeatBookingsExportClose()}
        btnText="Export Seat Bookings"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="date-container">
            <FormLabel sx={{ marginBottom: '5px' }} className="date-label">Start Date</FormLabel>
            <DatePicker
              label={state.seatBookingStartDate ? "" : "Select Start Date"}
              className="start-date"
              value={state.seatBookingStartDate}
              minDate={
                state.seatBookingEndDate
                  ? dayjs(state.seatBookingEndDate).subtract(1, "month")
                  : undefined
              }
              maxDate={dayjs(new Date())}
              onChange={(newValue) =>
                setState((prev) => {
                  return {
                    ...prev,
                    seatBookingStartDate: (newValue),
                  };
                })
              }
            />
          </div>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="date-container">
            <FormLabel sx={{ marginBottom: '5px', marginTop: '5px' }} className="date-label">End Date</FormLabel>
            <DatePicker
              label={state.seatBookingEndDate ? "" : "Select End Date"}
              className="end-date"
              value={state.seatBookingEndDate}
              minDate={state.seatBookingStartDate ? dayjs(state.seatBookingStartDate) : ""}
              maxDate={
                state.seatBookingStartDate &&
                  dayjs().diff(dayjs(state.seatBookingStartDate), "day") > 31
                  ? dayjs(state.seatBookingStartDate).add(1, "month")
                  : dayjs(new Date())
              }
              onChange={(newValue) =>
                setState((prev) => {
                  return {
                    ...prev,
                    seatBookingEndDate: (newValue),
                  };
                })
              }
            />
          </div>
        </LocalizationProvider>
      </ExportDialog>
    </>
  );
};

export default Trip;
