import { FC, useEffect, useState } from "react";
import CustomDialog from "../../../../components/mui/dialog";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem
} from "@mui/material";
import { StyledTextField, StyledSelect } from "../../../user/manage";
import { updateGiftUrl } from "../../../../services/Purchase Request";
//import { findNameByLanguage } from "../../../../utils";
//import { useLanguage } from "../../../../components/language-provider";
import { enqueueSnackbar } from "notistack";
import Loader from "../../../../components/mui/loader/Loader";
import * as yup from 'yup';
import { purchaseRequestSchema } from "../../../../utils";

interface props {
  isOpen: boolean;
  isDisabled?: boolean;
  _id: string;
  onClose: () => void;
  fetchData: () => void;
  name: string,
  amount: string | number,
  customer: string,
  phone: number | string,
  status: string,
  giftURL: string,
}

interface IError {
  name ?: string,
  amount ?: string,
  customer ?: string,
  phone ?: string,
  status ?: string,
  giftUrl ?: string
}


const EditDialog: FC<props> = ({
  isOpen,
  isDisabled,
  _id,
  name,
  amount,
  customer,
  phone,
  status,
  giftURL,
  fetchData,
  onClose,
}) => {
  const [state, setState] = useState({
    info: {
      _id:"",
      name: "",
      amount: "",
      customer: "",
      phone: "",
      status: "",
      giftURL: "",
    },
    loading: false,
  });

  const [error, setError] = useState<IError>({
    name: "",
    amount: "",
    customer: "",
    phone: "",
    status: "",
    giftUrl: "",
  });

 // const { language } = useLanguage();

  //eslint-disable
  useEffect(() => {
    // if (isOpen && _id) {
    //   setState((prev) => {
    //     return {
    //       ...prev,
    //       loading: true,
    //     };
    //   });
    //   getPurchaseRequestById(_id)
    //     .then((res: any) => {
    //       setState((prev: any) => {
    //         return {
    //           ...prev,
    //           info: {
    //             ...prev.info,
    //             name: findNameByLanguage(language, res.data._giftCard.name),
    //             amount: res.data.amount,
    //             customer:
    //               findNameByLanguage(language, res.data.firstName) +
    //               " " +
    //               findNameByLanguage(language, res.data.lastName),
    //             phone: res.data.phone,
    //             status: res.data.purchaseRequestStatus,
    //             giftURL: res.data.giftURL,
    //           },
    //           loading: false,
    //         };
    //       });
    //     })
    //     .catch((err) => console.log(err));
    // }
    setState((prev:any)=>{
      return{
        ...prev,
        info:{
          ...prev.info,
          _id,
          name,
          amount,
          customer,
          status,
          phone,
          giftURL
        }   
      }
    })
    // eslint-disable-next-line
  }, [name,amount,customer,status,phone,giftURL]);

  //eslint-enable

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (error) {
      setError((prev) => {
        return {
          ...prev,
          [name]: false,
        };
      });
    }
    setState((prev) => {
      return {
        ...prev,
        info: {
          ...prev.info,
          [name]: value,
        },
      };
    });
  };


  const onUpdate = () => {
    const payload = {
      giftUrl: state.info.giftURL,
      giftStatus:state.info.status,
      _id:state.info._id
     };
    purchaseRequestSchema
      .validate(payload, { abortEarly: false })
      .then(() => {
        updateGiftUrl(payload)
           .then(() => {
            enqueueSnackbar("Data updated successfully", {
              variant: "success",
            });
            handleClose();
            fetchData();
          })
          .catch(() =>
            enqueueSnackbar("Couldn't update data", { variant: "error" })
          );
      })
      .catch((error) => {
        console.log(error)
        const allError: { [key: string]: string } = {};
        error.inner.forEach((fieldError: yup.ValidationError) => {
          allError[fieldError.path!] = fieldError.message;
        });
        console.log(allError)
        setError(allError);
      });
  };

  const handleClose = () => {
    setError((prev)=>{
      return{
        ...prev,
        name: "",
        amount: "",
        customer: "",
        phone: "",
        status: "",
        giftUrl: "",
      }
    })
    onClose()
  }


  return (
    <>
      {state.loading && isOpen ? (
        <Loader />
      ) : (
        <CustomDialog
          title={"Edit Details"}
          isOpen={isOpen}
          onClose={handleClose}
          onConfirm={onUpdate}
          disabled={false}
          onEdit={onUpdate}
        >
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  label="Gift Card Name"
                  name="gift-card-name"
                  value={state.info.name}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  label="Amount"
                  name="amount"
                  value={state.info.amount}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  label="Customer Name"
                  name="customer-name"
                  value={state.info.customer}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  label="Phone"
                  name="phone"
                  value={state.info.phone}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6} className="grid-box">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <StyledSelect
                    size="small"
                    defaultValue="select"
                    value={state.info.status}
                    onChange={handleChange}
                    label="Status"
                    name="status"
                  >
                    <MenuItem value="select" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="PENDING">Pending</MenuItem>
                    <MenuItem value="COMPLETED">Completed</MenuItem>
                    <MenuItem value="CANCELLED">Cancelled</MenuItem>
                  </StyledSelect>
                </FormControl>
                {error && error.status && (
                  <small className="error-message">{error.status}</small>
                )}
              </Grid>
              <Grid item xs={12} md={6} className="grid-box">
                <StyledTextField
                  label="Gift URL"
                  name="giftURL"
                  type="url"
                  value={state.info.giftURL}
                  onChange={handleChange}
                  disabled={false}
                />
                {error && error.giftUrl && (
                  <small className="error-message">{error.giftUrl}</small>
                )}
              </Grid>
            </Grid>
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

EditDialog.defaultProps = {
  isDisabled: false,
};

export default EditDialog;
