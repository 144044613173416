import dayjs from "dayjs";
import * as yup from "yup";
import "dayjs/locale/en";
type DebouncedFunction<T extends (...args: any[]) => any> = (...args: Parameters<T>) => void;

const months = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"]
interface DateRange {
  start: string;
  end: string;
}

export const findNameByLanguage = (
  language: string,
  namesList: any
): string | undefined => {
  if(namesList !== "N/A"){
    const nameObj = namesList?.find((name: any) => name?.lang === language);
    return nameObj?.value || "";
  }
  return "-"
};

export const openImage = (imageUrl: string) => {
  window.open(imageUrl, "_blank");
};

export const formattedData = (arr1: any, arr2: any) => {
  const result: any = [];
  arr1.forEach((day1: any) => {
    const day2 = arr2.find((day: any) => day.day === day1.day);
    result.push({
      day: day1?.day,
      pickUp: day2?.pickUp || "-",
      dropOff: day2?.dropOff || "-",
    });
  });
  return result;
};

export const dateConverter = (date: string) => {
  const dateFormat = dayjs(date);
  return dateFormat.format("DD-MM-YYYY");
};

export const capitalizeFirstLetter = (string: any) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const getDay = (dayName: string): string => {
  return dayName.toUpperCase().substr(0, 3);
};

export const formatDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const monthName: string = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(date);
  const timeString: string = date.toLocaleTimeString(undefined, {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  });
  return `${date.getDate()} ${monthName},${timeString}`;
};
export const formatAddress = (address: any, language: string) => {
  let text = findNameByLanguage(language, address);
  if (text) {
    const displayText = text?.length > 15 ? text?.slice(0, 15) + "..." : text;
    return displayText;
  }
};
export const userSchema = yup.object().shape({
  firstname: yup
    .string()
    .required("First Name is required")
    .min(2, "First Name must be at least 2 characters")
    .max(20, "First Name cannot be more than 20 characters"),
  lastname: yup
    .string()
    .required("Last Name is required")
    .min(2, "Last Name must be at least 2 characters")
    .max(20, "Last Name cannot be more than 20 characters"),
  email: yup
    .string()
    .email()
    .max(30, "Email cannot be more than 20 characters")
    .required("Email is required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    ),
  phone: yup.string().required("Phone number is required").min(10, "Invalid phone number").max(14, "Invalid phone number"),
  // .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
  gender: yup.string().oneOf(["MALE", "FEMALE"]).required("Gender is required"),
  preference: yup
    .string()
    .oneOf(["OFFER", "FINDER", "BOTH"])
    .required("Preference is required"),
  reg_number: yup.string().required("Registration number is required"),
  homeLocation: yup.string().required("Home Location is required"),
  street: yup.string().optional(),
  city: yup.string().required("City is required"),
  postCode: yup.string().required("Post Code is required"),
  state: yup.string().required("State is required"),
  country: yup.string().optional(),
  geo: yup.array(yup.number()).length(2).required().typeError("Geo is required"),
});

export const adminSchema = (type: string) =>
  yup.object().shape({
    image: yup.string().required("Image is required"),
    firstname: yup
      .string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters")
      .max(20, "First Name cannot be more than 20 characters"),
    lastname: yup
      .string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters")
      .max(20, "Last Name cannot be more than 20 characters"),
    email: yup
      .string()
      .email()
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      ),
    phone: yup.string().required("Phone number is required").min(10,'Invalid phone number').max(12,'Invalid phone number'),
    // .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
    gender: yup
      .string()
      .required("Gender is required")
      .oneOf(["MALE", "FEMALE"], "Please select either male or female"),

    ...(type === "new"
      ? { password: yup.string().required("Password is required") }
      : {}),
  });

export const giftCategorySchema = yup.object().shape({
  category: yup
    .string()
    .required("Category is required")
    .min(2, "Category name must be of atleast 2 characters")
    .max(20, "Category name can not be of more than 20 characters"),
  order: yup
    .string()
    .required("Order is required")
    .max(10, "Order can not be of more than 10 characters")
    .notOneOf(["0"], "Order cannot be zero"),
});

export const giftProviderSchema = yup.object().shape({
  image: yup.string().required("Image is required"),
  giftProvider: yup.string().required("Gift Provider name is required"),
  giftCategory: yup.string().required('Gift Category is required'),
  discount: yup.number().required("Discount is required"),
  availability : yup.string().required("Choose availability"),
  terms_and_condition_url : yup.string().optional().url("Invalid URL format"),
  deliveryType : yup.string().required("Delivery type is required"),
})

export const purchaseRequestSchema = yup.object().shape({
  giftUrl: yup
    .string()
    .required("Gift URL is required")
    // .matches(
    //   /^(https?:\/\/)([\w.-]+)(:[0-9]+)?(\/[\w./?%&=]*)?$/,
    //   "Invalid URL format"
    // ),
    .matches(
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
      "Invalid URL format"
    ),
  giftStatus: yup.string().required("Status is required"),
  _id: yup.string().required("Id is required")
});

export const locationCategorySchema = yup.object().shape({
  category: yup
    .string()
    .min(2, "Category name must be of minimum 2 letters")
    .max(20, "Category name can not be of more than 20 letters")
    .required("Category name is required"),
  order: yup
    .string()
    .required("Order is required")
    .max(10, "Order can not be of more than 10 characters")
    .notOneOf(["0"], "Order cannot be zero"),

  reminderDay: yup.string().required("Reminder Day is required"),
  reminderTime: yup.string().required("Reminder time is required"),
  emoji : yup.string().required("Emoji is required")
});

export const locationSchema = (type: string) => yup.object().shape({
  locationCategory: yup.string().required(),
  latitude: yup.number().required().typeError('Please enter a valid number'),
  longitude: yup.number().required().typeError('Please enter a valid number'),
  locationName: yup
    .string()
    .required("Location name is required")
    .min(2, "Location name must be at least 2 characters"),
  ecologyURL: yup
    .string()
    .required("Ecology URL is required")
    .matches(
      /^(https?:\/\/)?[\w.-]+\.[a-z]{2,}(\S*)?$/,
      "Invalid URL format"
    ),
  community: yup.string().required("Community is required"),
  verification_type: yup.string().required("Verification type is required"),
  ...(type === "PRIVATE"
    ? {
      whitelist_type: yup.string().required("Whitelist type is required"),
      whiteListValues: yup.array().min(1, "Field should have valid email or domain").required("This field can't be empty")
    }
    : {}),
});



export const getTimeRange = (type: string): DateRange | null => {
  const now = dayjs();
  let start;
  let end;

  switch (type) {
    case "last week":
      start = now.subtract(1, "week").startOf("week");
      end = now.subtract(1, "week").endOf("week");
      break;
    case "last month":
      start = now.subtract(1, "month").startOf("month");
      end = now.subtract(1, "month").endOf("month");
      break;
    case "last year":
      start = now.subtract(1, "year").startOf("month");
      end = now.startOf("month");
      break;
    default:
      return null;
  }

  return { start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD") };
};

export const getDaysFromDates = (dates: any) => {
  const date = dayjs(dates);
  const dayName = date.format("ddd D MMMM YYYY");

  return dayName;
};

export const sortByDate = (arr: any) => {
  arr.sort((a: any, b: any) => dayjs(a.date).diff(dayjs(b.date)));
  return arr;
};

export const userColumns = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "image",
    label: "Image",
  },

  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "preference",
    label: "Carpool Preference",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "driverVerified",
    label: "Driver verified",
  },
  {
    id: "action",
    label: "Actions",
  },
];

export const tripColumns = [
  {
    id: "tripId",
    label: "Trip ID",
  },
  {
    id: "name",
    label: "Driver Name",
  },
  {
    id: "from",
    label: "From",
  },
  {
    id: "to",
    label: "To",
  },
  {
    id: "scheduledDate",
    label: "Scheduled Date",
  },
  {
    id: "inviteAccepted",
    label: "Invite Accepted",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];

export const adminColumns = [
 
  {
    id: "name",
    label: "Name",
  },
  {
    id: "image",
    label: "Image",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "role",
    label: "Role",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];


export const getMonthsName = (month: number) => {
  return `${months[month - 1].slice(0, 3)} ${dayjs().year()}`
}

export const showError = (errorMsg:string) => {
  return errorMsg.replace(/^[/\s]+/, '');
}


export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): DebouncedFunction<T> {
  let timeoutId: NodeJS.Timeout;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
