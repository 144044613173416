import { httpRequest } from "../../Network"
import axios from "axios"

export const getCarbonConfig = () => {
    return httpRequest('GET','/configuration/cost/carbon-footprint')
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}

export const updateCarbonConfig = (payload:object) => {
    return httpRequest('PUT','/configuration/cost/carbon-footprint',payload)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}


export const updateCarbonOffset = (locationId: string, payload:object) => {
    return httpRequest('PUT',`/configuration/loc/carbon-offset/${locationId}`,payload)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}

// purchase carbon avoidance credits
export const purchaseCarbonAvoidance = (payload:object) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: "https://public.ecologi.com/impact/carbon",
            data: payload,
            headers: {
                "Accept": 'application/json',
                "Authorization": `Bearer ${process.env.REACT_APP_ECOLOGI_SECRET_KEY}`,
                "Content-Type": "application/json",
                "Idempotency-Key": Math.random().toString(36).slice(2)
            }
        })
            .then((response: any) => {
                if (String(response.status).match(/20/gi)) {
                    resolve(response.data);
                } else {
                    reject("Something went wrong");
                }
            })
            .catch((err: any) => {
                console.log('inside catch', err.response);
                reject(err);
            })
    });
}
