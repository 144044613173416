import "./index.scss";
import { findNameByLanguage } from "../../utils";
import { useLanguage } from "../language-provider";
import { formattedData } from "../../utils";
import { useState, useEffect } from 'react'


interface IProps {
  details: any;
  border: boolean,
  userId: string
}




const Favourite = ({ details, border, userId }: IProps) => {
  const { language } = useLanguage();
  const [data, setData] = useState([
    {
      day: "MONDAY",
      pickUp: "-",
      dropOff: "-",
    },
    {
      day: "TUESDAY",
      pickUp: "-",
      dropOff: "-",
    },
    {
      day: "WEDNESDAY",
      pickUp: "-",
      dropOff: "-",
    },
    {
      day: "THURSDAY",
      pickUp: "-",
      dropOff: "-",
    },
    {
      day: "FRIDAY",
      pickUp: "-",
      dropOff: "-",
    }
  ])
  //eslint-disable
  useEffect(() => {
    const newData = formattedData(data, schedule)
    setData(newData)
    // eslint-disable-next-line
  }, [])

  //eslint-enable
  const { categoryName, locationName, dailySchedule: { schedule } } = details;

  //setScheduleData(newData)

  return (
    <div className="fav-location-main-wrapper">
      <div className="category-name-and-address">
        <div className="category">
          {categoryName && <b>{findNameByLanguage(language, categoryName)}</b>}
        </div>
        <div className="location-name">
          {locationName && <p>{findNameByLanguage(language, locationName)}</p>}
        </div>
        <div className="location-name" style={{ display: "flex", gap: "1rem", alignItems: "center", paddingBottom: "1rem" }}>
          invited: {details.invitedUsers.includes(userId) ? "True" : "False"}
        </div>
      </div>

      <div className={border ? "schedule-wrapper border-bottom" : "schedule-wrapper"}>
        <div className="schedule-header">
          <div className="day-name">
            <div className="days-header">Day</div>
            {data.map((days, key) => (
              <div className="days">{days.day}</div>
            ))}
          </div>
          <div className="pick-up-container">
            <div className="pick-up-header"><b>Pick up</b></div>
            {data.map((days, key) => <div className="pick-up">{days.pickUp}</div>)}
          </div>
          <div className="drop-off-container">
            <div className="drop-off-header"><b>Drop Off</b></div>
            {data.map((days, key) => <div className="drop-off">{days.dropOff}</div>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Favourite;
