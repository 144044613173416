import { ChangeEvent, useState, useEffect } from 'react';
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, FormControl, IconButton, MenuItem, Select, Switch, Tooltip} from '@mui/material';
import Manage from './manage';
import WarningDialog from '../../../components/mui/warning-dialog';
import { giftListing, updateGiftStatus, deleteGift} from '../../../services/configurations/giftProvider';
import { useSnackbar } from 'notistack';
import { capitalizeFirstLetter, findNameByLanguage } from '../../../utils';
import { useLanguage } from '../../../components/language-provider';
import Loader from '../../../components/mui/loader/Loader';
import { IName, IUser, IState, IGiftProviderLocation, IList } from '../../../interfaces/configuration/gift-provider';
import messages from '../../../assets/messages';

let list: IList[];
let count: number = 10;
const columns = [
  // {
  //   id: 'id',
  //   label: 'S.No'
  // },
  {
    id: 'image',
    label: 'Image'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'category',
    label: 'Category'
  },
  {
    id: 'availability',
    label: 'Availability'
  },
  {
    id:'discount',
    label: 'Discount %'
  },
  {
    id: 'order',
    label: 'Order'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'action',
    label: 'Action'
  },
];
export interface IPayload {
  id: string;
  status: string;
}
export interface IEditDetails {
  name: IName[];
  order: number;
  image: string;
  id: string;
}

// interface CategoryStates {
//   reminderTime: string | null;
// }


const GiftProvider = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [deleteGiftId, setDeleteGiftId] = useState("");
  const [state, setState] = useState<IState>({
    manage: {
      type: 'new',
      isOpen: false,
    },
    deleteWarning: false,
    _gift: '',
    list: [],
    filterType: "ALL",
    editDetails: {
      name: [{ value: "" }],
      order: "",
      image: "",
      id: ""
    },
    page: 1,
    totalPage: 1,
    loader: true,
    searchLoader: false,
    initialState: "",
    searchItem: false
  });
  const fetchData = (searchValue ?: string) => {
    const filter = {
      status: state.filterType,
      page : searchValue ? 1 :state.page,
      searchValue
    }
    if (!state.searchItem) {
      const result = giftListing(filter);
      result.then((response: IGiftProviderLocation) => {

        setState({ ...state, loader: false });
        list = response.data.map((user: IUser, index: number) =>
          createRow(user, index, count, handleDelete, onUpdate, handleManage));
        setState(prevState => {
          return {
            ...prevState,
            list : list.sort((a:any,b:any)=> a.order - b.order),
            page: response.meta.currentPage,
            totalPage: response.meta.totalPage,
            manage: { isOpen: false, type: "new" },
            deleteWarning: false,

          }
        });
      })
      .catch((err:any)=>{
        console.log(err.response.data.message)
        enqueueSnackbar(err.response.data.message,{variant:"error"})
      } )
    }
    else {
      return;
    }
  }
  const { language } = useLanguage();
  /* eslint-disable */
  useEffect(() => {
    fetchData();
  }, [state.filterType, state.page]);
  /* eslint-enable */
  const customData = (event: any) => {
    setState({ ...state, filterType: event.target.value,page : 1, loader: true });
  }
  const handleSearch = (event:ChangeEvent<unknown>|any) => {
    const searchValue = event.target.value;
    console.log(searchValue)
    if (event.key === "Enter") {
      fetchData(searchValue);
    }
  };

  const onPageChange = (e: ChangeEvent<unknown>, pageNumber: number) => {
    setState({ ...state, page: pageNumber, loader: true });
  }

  const handleManage = (type: "new" | "edit" | "view",id:string) => {
    setState((prev) => {
     return{
      ...prev,
      manage:{
        ...prev.manage,
        type ,
        isOpen : !prev.manage.isOpen
      },
      _gift: id
     }
    });
  };

  const handleClose = () => {
    setState((prev) => {
      return {
        ...prev,
        manage:{
          ...prev.manage,
          isOpen : false
        }
      };
    });
  };

  const handleDelete = (_gift: string) => {
    setState(prevState => {
      return {
        ...prevState,
        deleteWarning: !prevState.deleteWarning,
      }
    });
    setDeleteGiftId(_gift);
  }
  const onUpdate = (id: string, event: any) => {

    let isChecked = event.target.checked;
    let status = isChecked === true ? "ACTIVE" : "INACTIVE";
    updateGiftStatus(id, status).then((response) => {
      fetchData();

      enqueueSnackbar(`${messages.snackbar.success.status}`, {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      })
    }).catch((er) => {
      enqueueSnackbar(`${messages.snackbar.error.status}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      })
      console.log("Error", er);
    })
  }
  const onDelete = (_id: string) => {
    setState(prevState => {
      return {
        ...prevState,
        loader: true,
        deleteWarning: false,
        _gift: _id
      }
    });
    deleteGift({ ids: [`${_id}`] }).then((response) => {
      enqueueSnackbar(`${messages.snackbar.success.delete}`, {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      })
      fetchData();
    }).catch((er) => {
      console.log("Error", er); enqueueSnackbar(`${messages.snackbar.error.delete}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      })
    });
  }

  const createRow = (gift: any, index: number, count: number, onDelete?: any, onUpdate?: any, onEdit?: any) => {
    return {
      id: (state.page - 1) * count + index + 1,
      image: <Avatar sx={{margin:"0px auto"}}alt='coupon' src={gift.image} >C</Avatar>,
      name: findNameByLanguage(language, gift.name),
      category: findNameByLanguage(language,gift?._category?.giftCategoryName),
      order : gift._category.order,
      discount : gift.discount,
      availability : capitalizeFirstLetter(gift.availability),
      status: <Switch checked={gift.status === 'ACTIVE' ? true : false} onChange={(event) => onUpdate(gift._id, event)} />,
      action: <>
        <Tooltip title='Edit'>
          <IconButton onClick={() => onEdit('edit', gift._id)} color="primary">
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton onClick={() => onDelete(gift._id)} color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    }
  }
  return (
    <>
      <div className={state.loader ? "gift-provider fade" : "gift-provider"}>
        <Header
          className='my-2'
          searchPlaceholder="Search by name"
          btnText="Add Gift Provider"
          onSearch={handleSearch}
          onBtnClick={() => handleManage('new', "")}
          searchInput
        >
          <FormControl className="ml-2" size='small'>
            <Select
              size='small'
              value={state.filterType}
              onChange={customData}
            >
              <MenuItem value='ALL'>All Status</MenuItem>
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='inactive'>InActive</MenuItem>
            </Select>
          </FormControl>
        </Header>

        <CustomTable
          columns={columns}
          rows={state.list}
          height='calc(100vh - 248px)'
          errorMessage="Add gifts to see the data here"
          pagination={{
            page: state.page,
            totalPages: state.totalPage
          }}
          onPageChange={onPageChange}
          searchLoader={state.searchLoader}
        />

        <Manage
          isOpen={state.manage.isOpen}
          type={state.manage.type}
          onClose={handleClose}
          isDisabled = {false}
          fetchData={fetchData}
          id={state._gift} 
        />

        <WarningDialog
          isOpen={state.deleteWarning}
          onClose={() => setState({ ...state, deleteWarning: false })}
          onConfirm={() => onDelete(deleteGiftId)}
          title="Delete"
          description="Are you sure do you want to delete this coupon?"
        />
        {state.loader &&
          <Loader />
        }
      </div>
    </>
  )
}

export default GiftProvider;