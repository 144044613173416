import { FC, ReactNode, createContext, useContext } from "react";
import { TLanguageContextType } from "../../interfaces";

const LanguageContext = createContext<TLanguageContextType>({ language: "" });

interface ContextProps {
    children: ReactNode;
}

const LanguageProvider: FC<ContextProps> = ({ children }) => {
    const locale = localStorage.getItem("locale");
    const language = locale ? locale : "EN";
    return (
        <LanguageContext.Provider value={{ language }}>
            {children}
        </LanguageContext.Provider>
    );
};

const useLanguage = () => useContext(LanguageContext) as TLanguageContextType;

export { useLanguage };
export default LanguageProvider;