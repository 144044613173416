import './style.scss';
import { FC, ChangeEvent } from "react";
import Pagination from '@mui/material/Pagination';
import { Button, TableContainer, Table, TableHead, TableRow, Tooltip, TableBody, styled, TableCell, tableCellClasses, Typography, Box, CircularProgress } from "@mui/material";
import TableChartIcon from '@mui/icons-material/TableChart';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7f7f7',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& td": {
    //maxWidth: "135px",
    minWidth: '200px',
    wordWrap: 'break-word',
    maxWidth: '350px'

  },
  "&:hover": {
    backgroundColor: "#f7f7f7",
    cursor: "pointer",
  },
}));

interface props {
  columns: any;
  rows: any;
  height?: number | string;
  errorMessage?: string;
  pagination?: {
    page: number;
    totalPages: number;
  }
  onPageChange?: (e: ChangeEvent<unknown>, page: number) => void;
  searchLoader?: boolean
}
interface IColumns {
  id: string;
  label: string;
  type?: string;
  action?: string[];
  format?: any;
  align?: any;
}
const CustomTable: FC<props> = ({ columns, rows, height, pagination, onPageChange, errorMessage = 'Add some data to see', searchLoader }) => {

  const truncateValue = (value: string) => {
    const maxLength = 30;
    if (value?.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    }

    return value;
  }

  const renderTableCell = (value: any, column: IColumns) => {
    if (column.id === 'action' && column.action) {
      return (
        <TableCell key={column.id} align="center">
          {column.action.map((key: string) => (
            <Button key={key} size="small" className="button-delete">
              <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="DeleteIcon">
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
              </svg>
            </Button>
          ))}
        </TableCell>
      );
    }

    return (
      <TableCell className='testtttt' key={column.id} align="center">
        {column.format && typeof value === 'number' ? column.format(value) : truncateValue(value)}
      </TableCell>
    );
  };


  return (
    <>
      <TableContainer sx={{ border: "1px solid #e3e3e3", height: (pagination && pagination?.totalPages <= 1 ? 'auto' : height), borderRadius: "2px" }}>
        {
          !searchLoader ?
            <Table stickyHeader aria-label="sticky table">

              <TableHead>
                <TableRow>
                  {columns.map((column: any, index: number) => (
                    <StyledTableCell
                      sx={{ fontWeight: "bold" }}
                      key={index}
                      align="center"
                      style={{ whiteSpace: "nowrap", minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .map((row: any, index: number) => {
                    return (
                      <StyledTableRow role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column: IColumns, i: number) => {
                          const value = row[column.id];
                          return (
                            <Tooltip title={typeof value === 'string' && value.length > 30 ? value : ''} key={i}>
                              {renderTableCell(value, column)}
                            </Tooltip>
                          );
                        })}
                      </StyledTableRow>

                    );
                  })}
              </TableBody>
            </Table> : <div className='loader-content'>
              <CircularProgress />
            </div>
        }
        {!rows?.length &&
          <div className='no-data'>
            <TableChartIcon fontSize='large' className='error-icon' />
            <Typography className='noData-title'>
              No data to show!
            </Typography>
            <Typography className='noData-sub-title'>
              {errorMessage}
            </Typography>
          </div>
        }
      </TableContainer>
      <Box marginTop='10px' marginBottom='24px'>
        {
          (pagination?.page && pagination?.totalPages > 1) &&
          <Pagination count={pagination.totalPages} page={pagination.page} onChange={onPageChange} color="primary" />
        }
      </Box>
    </>

  )
}

export default CustomTable
