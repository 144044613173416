import './style.scss';
import { Box} from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { StyledTextField } from '../../screens/user/manage';

const initMap = (lat = -33.8688, lng = 151.2195) => {
    const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
            center: { lat, lng },
            zoom: 13,
            mapTypeId: "roadmap",
        }
    );

    return map;
}
const searchLocation = (map: any, setState: any) => {
    let location: any;
    let markers: google.maps.Marker[] = [];

    const input = document.getElementById('location') as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);

    searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces() || [];

        if (places.length === 0) {
            return;
        }

        // Clear out the old markers.
        markers.forEach((marker) => {
            marker.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place: any) => {
            if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
            }
            location = {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                place,
                hometown: place?.name,
                homeLocation: place?.formatted_address,
                city: place?.address_components?.find((x: any) =>
                    x.types.includes("locality")
                )?.long_name,
                line2: place?.address_components?.find((x: any) =>
                    x.types.includes("locality")
                )?.long_name,
                country: place?.address_components?.find((x: any) =>
                    x.types.includes("country")
                )?.long_name,
                zip: place?.address_components?.find((x: any) =>
                    x.types.includes("postal_code")
                )?.long_name,
                state: place?.address_components?.find((x: any) =>
                    x.types.includes("administrative_area_level_1")
                )?.long_name
            }


            setState(location);

            const icon = {
                url: place.icon as string,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25),
            };

            // Create a marker for each place.
            markers.push(
                new google.maps.Marker({
                    map,
                    icon,
                    title: place.name,
                    position: place.geometry.location,
                })
            );
            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });
        map.fitBounds(bounds);
    });

    return location;
}

interface props {
    name?: string
    value?: string,
    handleChange?: (prev: any) => void,
    latitude?: number;
    longitude?: number;
    showMap?: boolean;
    label: string;
    setLocation: (prev: any) => void
}

const GoogleMap: FC<props> = ({ name, value, handleChange, latitude, longitude, setLocation, label, showMap }) => {
    const location = useRef<HTMLHeadingElement>(null);
    const [state, setState] = useState({
        latitude,
        longitude,
        place: {}
    })

    useEffect(() => {
        const map = initMap(state.latitude, state.longitude);
        searchLocation(map, setState);
        setLocation(state);
    }, [state, state.latitude, state.longitude, setLocation]);

    return (
        <div>
            <StyledTextField
                ref={location}
                id='location'
                name={name}
                value={value}
                onChange={handleChange}
                className="controls"
                label={label}
                type="text"
                placeholder='Search Location'
            // InputLabelProps={{ shrink: true }}
            />
            <Box display={showMap ? "block" : "none"} height="500px" width='100%' marginTop="40px" id="map"></Box>
        </div>
    )
}

GoogleMap.defaultProps = {
    latitude: -33.8688,
    longitude: 151.2195,
    showMap: false
}

export default GoogleMap;
