const messages = {
    snackbar: {
        success: {
            edit: "Updated Successfully",
            delete: "Deleted Successfully",
            add: "Added Successfully",
            status:"Status updated successfully",
            declarations:"Declarations updated successfully"
        },
        error: {
            edit: "Coudn't update data",
            delete: "Couldn't delete data",
            add: "Coudn't add data",
            status:"Coudn't update status",
            declarations:"Couldn't update data"
        }
    }
}
export default messages;