import { httpFormRequest } from "../Network";

export const upload = (file: any = {}) => {
    // return httpRequest("GET",`/auth/user?roleType=${roleType}&status=${status}&searchValue=${searchValue}`)
    return httpFormRequest("upload", file)
    .then((response:any)=>{
        return response.data
    })
    .catch((err)=>{
        console.log(err)
    })
}