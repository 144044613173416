import { FC, useState, useEffect, ChangeEvent } from "react";
import CustomDialog from "../../../components/mui/dialog";
import { Grid, Box } from "@mui/material";
import { updateVehicleDetail } from "../../../services/User";
import { useSnackbar } from "notistack";
import { getUserDetail } from "../../../services/User";
import Loader from "../../../components/mui/loader/Loader";
import { IVehicles } from "../../../interfaces/users";
import { StyledTextField } from "../manage";

const VehicleDetails: FC<IVehicles> = ({ isOpen, id, onClose, isDisabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    _id: id,
    reg_no: "",
    seat: "",
    child_seat: "",
    loading: false,
  });

  const fetchData = (id: string) => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const _id = {
      _id: id,
    };

    getUserDetail(_id)
      .then((res) => {
        if (res.carDetail) {
          const {
            carDetail: {
              registrationNo,
              noOfSeatAvailable,
              noOfChildSeatAvailable,
            },
          } = res;
          setState((prev) => {
            return {
              ...prev,
              _id: id,
              reg_no: registrationNo,
              seat: noOfSeatAvailable,
              child_seat: noOfChildSeatAvailable,
              loading: false,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /*eslint-disable*/
  useEffect(() => {
    if (id && isOpen) {
      fetchData(id);
    }
  }, [id]);
/*eslint-enable*/
  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    const payload = {
      _id: id,
      registrationNo: state.reg_no,
      noOfSeatAvailable: state.seat,
      noOfChildSeatAvailable: state.child_seat,
    };
    if (
      !payload._id ||
      !payload.registrationNo ||
      !payload.noOfSeatAvailable ||
      !payload.noOfChildSeatAvailable
    ) {
      return enqueueSnackbar("Fields can't be empty", {
        variant: "error"
      });
    }
    updateVehicleDetail(payload)
      .then(() => {
        enqueueSnackbar("Details updated", {
          variant: "success"
        });
        onClose();
      })
      .catch(() => {
        enqueueSnackbar("Couldn't update data", {
          variant: "error"
        });
        onClose();
      });
  };

  return (
    <>
      {state.loading ? (
        <Loader />
      ) : (
        <CustomDialog
          title={"Vehicle Details"}
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleUpdate}
          disabled={false}
        >
          <Box>
            <Grid container spacing={4}>
              <>
                <Grid item xs={12} md={6}>
                <StyledTextField
                    label="Registration Number"
                    name="reg_no"
                    value={state.reg_no}
                    onChange={handleChange}
                  />
                </Grid>
              </>
              <Grid item xs={12} md={6}>
              <StyledTextField
                  label="Seat Availability"
                  name="seat"
                  value={state.seat}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
              <StyledTextField
                  label="Child Seat Availability"
                  name="child_seat"
                  value={state.child_seat}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

export default VehicleDetails;