import React, { FC, useEffect, useState, ChangeEvent } from "react";
import CustomDialog from "../../../../components/mui/dialog";
import { Box, Grid, MenuItem, InputLabel, FormControl, Button } from "@mui/material";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { StyledTextField, StyledSelect } from "../../../user/manage";
import { getAllCategories, getLocationMembers } from "../../../../services/User";
import Loader from "../../../../components/mui/loader/Loader";
import { capitalizeFirstLetter, findNameByLanguage } from "../../../../utils";
import { useLanguage } from "../../../../components/language-provider";
import {
  addNewLocation,
  approveMember,
  getLocationById,
  updateStatus,
} from "../../../../services/configurations/locations";
import { enqueueSnackbar } from "notistack";
import { locationSchema } from "../../../../utils";
import MultipleInput from "../../../../components/Multiple Input";
import { LocationState as ILocationState } from "../../../../interfaces/configuration/locations";
import * as yup from "yup";
import { IUser } from "../../../../interfaces/payment";
import { upload } from "../../../../services/common";

interface props {
  isOpen: boolean;
  type: any;
  locationId?: string | undefined;
  isDisabled?: boolean;
  fetchData: () => void;
  onClose: () => void;
}

interface IError {
  locationCategory?: string;
  locationName?: string;
  ecologyURL?: string;
  community?: string;
  verification_type?: string;
  whitelist_type?: string;
  whiteListValues?: string;
  latitude?: number;
  longitude?: number;
  offset?: number;
  isBeta?: string;
}

const urlPatternValidation = (URL: string, type: string) => {
  const domainRegex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (type === "emails") {
    return emailRegex.test(URL);
  } else return domainRegex.test(URL);
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ManageLocation: FC<props> = ({
  isOpen,
  type,
  locationId,
  fetchData,
  onClose,
}) => {
  const { language } = useLanguage();

  const [state, setState] = useState<ILocationState>({
    info: {
      locationCategory: "",
      locationName: "",
      ecologyURL: "",
      otherFee: "",
      community: "",
      whitelist_type: "",
      verification_type: "",
      latitude: 0,
      longitude: 0,
      offset: 0,
      isBeta: "NO",
    },
    mapData: {
      line1: "",
      line2: "",
      state: "",
      city: "",
      country: "",
      zip: "",
      geo: [],
    },
    categories: [],
    locationCategory: "",
    loading: true,
  });
  const [error, setError] = useState<IError>({
    locationCategory: "",
    locationName: "",
    ecologyURL: "",
    community: "",
    verification_type: "",
    whitelist_type: "",
    whiteListValues: "",
    longitude: undefined,
    latitude: undefined,
    offset: undefined,
    isBeta: "",
  });
  const [whiteListEmails, setWhiteListEmails] = useState<string[]>([]);
  const [currEmail, setCurrEmail] = useState<string>("");
  const [locationMembers, setLocationMembers] = useState([])
  const [communityAdmin, setCommunityAdmin] = useState("")
  const [domainData, setDomainData] = useState<{
    [domain: string]: { imageData: File | string, organisationName: string, previewURL: string, uploaded?: boolean }
  }>({})

  const handleChange = (event: ChangeEvent<HTMLElement> | any) => {
    const { name, value } = event.target;

    if (error) {
      setError((prev) => {
        return {
          ...prev,
          [name]: false,
        };
      });
    }

    if (name === "whitelist_type") {
      setWhiteListEmails([]);
    }

    setState((prev) => {
      return {
        ...prev,
        info: {
          ...prev.info,
          [name]: value,
        },
      };
    });
  };

  const getVerificationType = (type: string[]) => {
    if (type.length === 1) {
      if (type[0] === "NAME") {
        return "NAME";
      }
      else if (type[0] === "ADDRESS") {
        return "ADDRESS";
      }
      else {
        return "NONE";
      }
    } else return "BOTH";
  };

  //eslint-disable
  useEffect(() => {
    if (type === "edit") {
      let adminId = ''

      getLocationById(locationId)
        .then((res: any) => {
          setState((prev) => {
            return {
              ...prev,
              info: {
                ...prev.info,
                locationCategory: res.data.categoryId,
                locationName: findNameByLanguage(
                  language,
                  res.data.locationName
                ) as string,
                ecologyURL: res.data.ecologiUrl,
                otherFee: res.data.otherFee,
                verification_type: getVerificationType(
                  res.data.verificationType
                ),
                community: res.data.communityType,
                whitelist_type: res.data.whiteListType,
                latitude: res.data.address.geo[0],
                longitude: res.data.address.geo[1],
                offset: res.data.co2Offset || 0,
                isBeta: res.data.isBeta ? "YES" : "NO" || "NO",
              },
              mapData: {
                line1: findNameByLanguage(
                  language,
                  res.data.address.line1
                ) as string,
                line2: findNameByLanguage(
                  language,
                  res.data.address.line2
                ) as string,
                city: findNameByLanguage(
                  language,
                  res.data.address.city
                ) as string,
                state: findNameByLanguage(
                  language,
                  res.data.address.state
                ) as string,
                country: findNameByLanguage(
                  language,
                  res.data.address.country
                ) as string,
                zip: res.data.address.zip,
                geo: res.data.address.geo,
              },
              loading: false,
            };
          });

          if(res.data.domainData) {
            let domainDataObj = {} as {
              [domain: string]: { imageData: File | "", organisationName: string, previewURL: string, uploaded?: boolean }
            }

            for(let key of Object.keys(res.data.domainData)) {
              if(res.data.whiteListValues.includes(key)) {
                domainDataObj[key] = {
                  imageData: res.data.domainData[key].imageData,
                  organisationName: res.data.domainData[key].organisationName,
                  previewURL: res.data.domainData[key].imageData,
                  uploaded: true
                }
              }
            }

            setDomainData(domainDataObj)
          } else {
            setDomainData({})
          }

          adminId = res.data.adminId

          if (res.data.whiteListValues) {
            setWhiteListEmails(res.data.whiteListValues);
          }
        })
        .catch((err) => console.log(err));

      // fetch all the users/members of the location
      getLocationMembers(locationId as string)
        .then((res: any) => {
          if (res.data) {
            setLocationMembers(res.data)

            // if user is returned(i,e, user exists and not deleted), then set the community admin
            if (res.data.find((user: IUser) => user._id.toString() === adminId.toString())) {
              setCommunityAdmin(adminId)
            }
          } else {
            setLocationMembers([])

            setCommunityAdmin("")
          }
        })
        .catch((err: any) => {
          enqueueSnackbar("Unable to get member details", { variant: "error" })
        })
    }

    if (type === "new") {
      setState((prev) => {
        return {
          ...prev,
          info: {
            ...prev.info,
            locationCategory: "",
            locationName: "",
            ecologyURL: "",
            otherFee: "",
            community: "",
            verification_type: "",
            whitelist_type: "",
            latitude: 0,
            longitude: 0,
            offset: 0,
            isBeta: "NO",
          },
        };
      });

      setWhiteListEmails([]);
      setCurrEmail("");
    }

    getAllCategories({ status: "ALL", limit: 10000 })
      .then((res: any) => {
        const data = res.data;

        const allCategories = data.map((item: any, key: number) => ({
          categoryId: item._id,
          label: capitalizeFirstLetter(
            findNameByLanguage(language, item.locationName)
          ),
        }));

        setState((prev) => {
          return {
            ...prev,
            categories: allCategories,
          };
        });
      })
      .catch((er) => console.log(er));
    // eslint-disable-next-line
  }, [isOpen === true, locationId]);

  // useEffect(() => {
  //   if (location) {
  //     const {
  //       homeLocation,
  //       line2,
  //       state,
  //       city,
  //       country,
  //       zip,
  //       latitude,
  //       longitude,
  //     } = location;
  //     if (homeLocation) {
  //       setState((prev) => {
  //         return {
  //           ...prev,
  //           info: {
  //             ...prev.info,
  //             locationAddress: homeLocation,
  //           },
  //           mapData: {
  //             ...prev.mapData,
  //             line1: homeLocation,
  //             line2,
  //             state,
  //             city,
  //             country,
  //             zip,
  //             geo: [latitude, longitude],
  //           },
  //         };
  //       });
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [location]);

  //eslint-enable
  const handleSave = async () => {
    // make a copy of the domain data
    let domainDataCopy = JSON.parse(JSON.stringify(domainData));

    // loop through the domain data to check if organisationName and organizationImage is added by the admin or not
    for (let key of Object.keys(domainDataCopy)) {
      if(domainDataCopy[key].organisationName === undefined) {
        domainDataCopy[key].organisationName = ""
      }

      if(domainDataCopy[key].imageData === undefined) {
        domainDataCopy[key].imageData = ""
      } else {
        if(domainData[key].uploaded) {
          delete domainDataCopy[key].previewURL
          delete domainDataCopy[key].uploaded

          continue
        }

        setState((prev) => {
          return {
            ...prev,
            loading: true,
          };
        })

        // upload the images
        try {
          const response = await upload(domainData[key].imageData)

          domainDataCopy[key].imageData = response
        } catch (error) {
          domainDataCopy[key].imageData = ""

          console.error("Error uploading image: ", error);
        }
      }

      delete domainDataCopy[key].previewURL
      delete domainDataCopy[key].uploaded
    }

    const payload = {
      categoryId: state.info.locationCategory,
      locationName: {
        lang: language,
        value: state.info.locationName,
      },
      co2Offset: state.info.offset,
      isBeta: state.info.isBeta === "YES" ? true : false,
      // address: {
      //   line1: [
      //     {
      //       lang: language,
      //       value: state.mapData.line1,
      //     },
      //   ],
      //   line2: state.mapData.line2 ? [
      //     {
      //       lang: language,
      //       value: state.mapData.line2 || "",
      //     },
      //   ] : undefined,
      //   state: [
      //     {
      //       lang: language,
      //       value: state.mapData.state,
      //     },
      //   ],
      //   city: state.mapData.city ? [
      //     {
      //       lang: language,
      //       value: state.mapData.city,
      //     },
      //   ] : undefined,
      //   country: [
      //     {
      //       lang: language,
      //       value: state.mapData.country,
      //     },
      //   ],
      //   zip: state.mapData.zip,
      //   // geo: state.mapData.geo,
      //   geo: [state.info.latitude, state.info.longitude],
      // },
      address: {
        geo: [state.info.latitude, state.info.longitude],
      },
      ecologiUrl: state.info.ecologyURL,
      otherFee: state.info.otherFee || 0,
      communityType: state.info.community,
      whiteListType: state.info.whitelist_type,
      whiteListValues: whiteListEmails,
      verificationType:
        state.info.verification_type === "BOTH"
          ? ["NAME", "ADDRESS"]
          : [state.info.verification_type],
      ...(((Object.keys(domainData).length > 0) && (state.info.whitelist_type === "DOMAIN")) ? {domainData: domainDataCopy} : {domainData: {}}),
      ...(communityAdmin !== "" &&
        { adminId: communityAdmin })
    };

    setCurrEmail("");

    if (type === "edit") {
      locationSchema(state.info.community)
        .validate(
          { ...state.info, whiteListValues: whiteListEmails },
          { abortEarly: false }
        )
        .then(() => {
          updateStatus(locationId as string, payload)
            .then(async () => {

              if (communityAdmin !== "") {
                // make a request to set the status of admin to approved
                await approveMember(locationId as string, { userIds: [communityAdmin] })
              }

              enqueueSnackbar("Location updated successfully", {
                variant: "success",
              });

              onClose();
              fetchData();
            })
            .catch(() =>
              enqueueSnackbar("Couldn't update data", { variant: "error" })
            );
        })
        .catch((error) => {
          const errors: { [key: string]: string } = {};
          error.inner.forEach((fieldError: yup.ValidationError) => {
            errors[fieldError.path!] = fieldError.message;
          });
          console.log(errors);
          setError(errors);
        });
    } else {
      locationSchema(state.info.community)
        .validate(
          { ...state.info, whiteListValues: whiteListEmails },
          { abortEarly: false }
        )
        .then(() => {
          addNewLocation(payload)
            .then(() => {
              enqueueSnackbar("Location added successfully", {
                variant: "success",
              });
              onClose();
              fetchData();
            })
            .catch(() =>
              enqueueSnackbar("Couldn't update data", { variant: "error" })
            );
          console.log(payload);
        })
        .catch((error) => {
          const errors: { [key: string]: string } = {};
          error.inner.forEach((fieldError: yup.ValidationError) => {
            errors[fieldError.path!] = fieldError.message;
          });
          console.log(errors);
          setError(errors);
        });
    }
  };

  const handleWhiteListValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (error.whiteListValues) {
      setError((prev) => {
        return {
          ...prev,
          whiteListValues: "",
        };
      });
    }
    setCurrEmail(e.target.value);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      if (currEmail !== "") {
        let isValidURL = urlPatternValidation(currEmail, state.info.community);
        if (!isValidURL) {
          setError((prev) => {
            return {
              ...prev,
              whiteListValues: `Invalid ${state.info.whitelist_type === "EMAIL" ? "email" : "domain"
                }`,
            };
          });
        } else {
          setWhiteListEmails((oldState) => [...oldState, currEmail]);
          setError((prev) => {
            return {
              ...prev,
              invalidEmail: false,
              required: false,
            };
          });
          setCurrEmail("");
        }
      }
    }
  };

  const handleDelete = (item: string, index: number) => {
    const arr = [...whiteListEmails];

    arr.splice(index, 1);

    setWhiteListEmails(arr);

    let updatedDomainData = {} as {
      [domain: string]: { imageData: File | string, organisationName: string, previewURL: string, uploaded?: boolean }
    }
    for(let key of Object.keys(domainData)) {
      if(arr.includes(key)) {
        updatedDomainData[key] = domainData[key]
      }
    }

    setDomainData(updatedDomainData)
  };

  // change community admin user
  const handlecommunityAdminChange = (e: any) => {
    setCommunityAdmin((e.target as HTMLSelectElement).value)
  }

  return (
    <>
      {type === "edit" && isOpen && state.loading ? (
        <Loader />
      ) : (
        <CustomDialog
          title={type === "new" ? "Add New Location " : "Edit Location"}
          disabled={false}
          isOpen={isOpen}
          onConfirm={handleSave}
          onClose={() => {
            onClose();
            setError({});
          }}
        >
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} className="grid-box">
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Location Category
                  </InputLabel>
                  <StyledSelect
                    size="small"
                    value={state.info.locationCategory}
                    name="locationCategory"
                    label="Location Category"
                    onChange={handleChange}
                  >
                    {state.categories?.map((category: any, key: number) => (
                      <MenuItem key={key} value={category.categoryId}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                {error && error.locationCategory && (
                  <small className="error-message">
                    {error.locationCategory}
                  </small>
                )}
              </Grid>

              <Grid item xs={12} md={6} className="grid-box">
                <StyledTextField
                  label="Location Name"
                  name="locationName"
                  value={state.info.locationName}
                  onChange={handleChange}
                />
                {error && error.locationName && (
                  <small className="error-message">{error.locationName}</small>
                )}
              </Grid>

              {/* <Grid item xs={12} md={6} className="grid-box">
                <GoogleMap
                  label="Location Address"
                  name="locationAddress"
                  value={state.info.locationAddress}
                  handleChange={handleChange}
                  showMap={false}
                  setLocation={setLocationDetail}
                />
              </Grid> */}

              <Grid item xs={12} md={6} className="grid-box">
                <StyledTextField
                  label="Ecologi URL"
                  name="ecologyURL"
                  value={state.info.ecologyURL}
                  onChange={handleChange}
                />
                {error && error.ecologyURL && (
                  <small className="error-message">{error.ecologyURL}</small>
                )}
              </Grid>

              <Grid item xs={12} md={6} className="grid-box">
                <StyledTextField
                  label="Other Fee (optional)"
                  name="otherFee"
                  value={state.info.otherFee}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6} className="grid-box">
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Community
                  </InputLabel>
                  <StyledSelect
                    size="small"
                    value={state.info.community}
                    name="community"
                    label="Community"
                    onChange={handleChange}
                  >
                    <MenuItem value="PUBLIC">Public</MenuItem>
                    <MenuItem value="PRIVATE">Private</MenuItem>
                  </StyledSelect>
                </FormControl>
                {error && error.community && (
                  <small className="error-message">{error.community}</small>
                )}
              </Grid>

              {state.info.community === "PRIVATE" && (
                <Grid item xs={12} md={6} className="grid-box">
                  <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Whitelist Type
                    </InputLabel>

                    <StyledSelect
                      size="small"
                      value={state.info.whitelist_type}
                      name="whitelist_type"
                      label="Whitelist Type"
                      onChange={handleChange}
                    >
                      <MenuItem value="EMAIL">Admin</MenuItem>
                      <MenuItem value="DOMAIN">Domain</MenuItem>
                    </StyledSelect>
                  </FormControl>
                  {error && error.whitelist_type && (
                    <small className="error-message">
                      {error.whitelist_type}
                    </small>
                  )}
                </Grid>
              )}

              {type === "edit" && state.info.community === "PRIVATE" && state.info.whitelist_type === "EMAIL" && (
                <Grid item xs={12} md={6} className="grid-box">
                  <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Community Admin
                    </InputLabel>

                    <StyledSelect
                      size="small"
                      value={communityAdmin}
                      name="community_admin"
                      label="Community Admin"
                      onChange={handlecommunityAdminChange}
                    >
                      {
                        locationMembers.map((member: IUser, index: number) => (
                          <MenuItem key={index} value={member._id}>
                            {
                              member.firstName[0].value
                            }
                          </MenuItem>
                        ))
                      }
                    </StyledSelect>
                  </FormControl>

                  {error && error.whitelist_type && (
                    <small className="error-message">
                      {error.whitelist_type}
                    </small>
                  )}
                </Grid>
              )}

              {state.info.community === "PRIVATE" &&
                state.info.whitelist_type && (
                  <Grid item xs={12} md={6} className="grid-box">
                    <MultipleInput
                      inputFor={
                        state.info.whitelist_type === "EMAIL"
                          ? "emails"
                          : "domains"
                      }
                      values={whiteListEmails}
                      setValues={setWhiteListEmails}
                      currValue={currEmail}
                      setCurrValue={setCurrEmail}
                      handleChange={handleWhiteListValueChange}
                      handleKeyUp={handleKeyUp}
                      handleDelete={handleDelete}
                    />

                    {error && error.whiteListValues && (
                      <small className="error-message">
                        {error.whiteListValues}
                      </small>
                    )}
                  </Grid>
                )}

              <Grid item xs={12} md={6} className="grid-box">
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Verification Type
                  </InputLabel>

                  <StyledSelect
                    size="small"
                    value={state.info.verification_type as string}
                    name="verification_type"
                    label="Verification Type"
                    onChange={handleChange}
                  >
                    <MenuItem value="NONE">None</MenuItem>
                    <MenuItem value="NAME">Name</MenuItem>
                    <MenuItem value="ADDRESS">Address</MenuItem>
                    <MenuItem value="BOTH">Both</MenuItem>
                  </StyledSelect>
                </FormControl>

                {error && error.verification_type && (
                  <small className="error-message">
                    {error.verification_type}
                  </small>
                )}
              </Grid>

              <Grid item xs={6} md={3} className="grid-box">
              <StyledTextField
                  label="Latitude"
                  name="latitude"
                  value={state.info.latitude}
                  onChange={handleChange}
                  type="number"
                />
                {error && error.latitude && (
                  <small className="error-message">{error.latitude}</small>
                )}
              </Grid>

              <Grid item xs={6} md={3} className="grid-box">
              <StyledTextField
                  label="Longitude"
                  name="longitude"
                  value={state.info.longitude}
                  onChange={handleChange}
                  type="number"
                />
                {error && error.longitude && (
                  <small className="error-message">{error.longitude}</small>
                )}
              </Grid>

              <Grid item xs={6} md={3} className="grid-box">
              <StyledTextField
                  label="Offset"
                  name="offset"
                  value={state.info.offset}
                  onChange={handleChange}
                  type="number"
                />
                {error && error.offset && (
                  <small className="error-message">{error.offset}</small>
                )}
              </Grid>
              <Grid item xs={6} md={3} className="grid-box">
                <FormControl size="small" fullWidth disabled={type === 'new' ? false : true}>
                  <InputLabel id="demo-simple-select-label">
                    Beta Location
                  </InputLabel>

                  <StyledSelect
                    size="small"
                    value={state.info.isBeta}
                    name="isBeta"
                    label="Beta Location"
                    onChange={handleChange}
                  >
                    <MenuItem value={"NO"}>No</MenuItem>
                    <MenuItem value={"YES"}>Yes</MenuItem>
                  </StyledSelect>
                </FormControl>

                {error && error.isBeta && (
                  <small className="error-message">
                    {error.isBeta}
                  </small>
                )}
              </Grid>
              {
                whiteListEmails.length > 0 && (
                  <>
                    {
                      whiteListEmails.map((email: string, index: number) => (
                        <Grid key={index} item xs={12} md={12} className="grid-box">
                          <div className="h-100 d-flex items-center gap-1">
                            <span className="w-30 font-700">
                              {
                                email
                              }
                            </span>

                            <StyledTextField
                              label="Organisation name"
                              name="organisationName"
                              value={domainData[email]?.organisationName || ""}
                              onChange={(e) => setDomainData({...domainData, [email]: {...domainData[email], organisationName: e.target.value}})}
                              type="string"
                            />

                            <div style={{ width: '10%', height: `${domainData[email]?.previewURL ? '3rem' : 'auto'}` }} className="d-flex items-center justify-center rounded-5 overflow-hidden">
                              {
                                domainData[email]?.previewURL && domainData[email]?.previewURL !== "" ? (
                                  <img src={domainData[email]?.previewURL} alt="preview" className="width-100 h-100 cover" />
                                ) : (
                                  <CorporateFareIcon />
                                )
                              }
                            </div>

                            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} className="w-30">
                              Upload file

                              <VisuallyHiddenInput type="file" onChange={(e) => {
                                const objectUrl = URL.createObjectURL(e.target.files?.[0] as File);

                                setDomainData({...domainData, [email]: {...domainData[email], imageData: e.target.files?.[0] as File, previewURL: objectUrl, uploaded: false}});
                              }} />
                            </Button>


                          </div>
                        </Grid>
                      ))
                    }
                  </>
                )
              }
            </Grid>
          </Box>
        </CustomDialog>
      )}{" "}
    </>
  );
};

ManageLocation.defaultProps = {
  isDisabled: true,
};

export default ManageLocation;
