import { httpRequest } from "../Network";
import { user } from "../endpoints";
import {favouriteLocation} from '../endpoints'
import { configuration } from "../endpoints";

export const getAllUsers = (search: object) => {
  return httpRequest("GET", user, {}, search)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err
    });
};

// get all passengers with same favourite location as of driver
export const getAllPassengers = (userId: string) => {
  return httpRequest("GET", user+'/passengers', {}, { userId })
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err
    });
};

export const searchUsers = (searchValue: string) => {
  return httpRequest(
    "GET",
    `/auth/user?roleType=ALL&status=ACTIVE&searchValue=${searchValue}`
  )
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteUser = (id: any) => {
  return httpRequest("DELETE", user, id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error
    });
};

export const updateUserStatus = (data: object) => {
  return httpRequest("PUT", `${user}/status`,data)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};

export const updateUser = (data: any) => {
  return httpRequest("PUT", user, data)
    .then((response) => {
      return response
    })
    .catch((err) =>{
      throw err
    });
};

// update user passengers
export const updateUserPassengers = (data: any) => {
  return httpRequest("PUT", user + "/passengers", data)
    .then((response) => {
      return response
    })
    .catch((err) =>{
      throw err
    });
};


export const getUserDetail = (id:object) => {
  return httpRequest('GET',`${user}/detail`,{},id)
  .then((response:any)=>  response.data)
  .catch((err)=>  err)
}

// get single user
export const getSingleUser = (id: string) => {
  return httpRequest('GET',`${user}/${id}`)
  .then((response:any)=>  response.data)
  .catch((err)=>  err)
}


export const updateVehicleDetail = (payload:object) => {
  return httpRequest('PUT','/auth/vehicle',payload)
  .then((res)=> res)
  .catch((err)=>{
    throw err
  })
}

export const getAllCategories = (filter:object) => {
  return httpRequest('GET',configuration,{},filter)
  .then((res)=> res)
  .catch((err)=>{
    throw err
  })
}

export const getFavouriteLocation = (id:string) => {
return httpRequest('GET',`${favouriteLocation}/admin/${id}`)
.then((res)=> res)
.catch((err)=>{
  throw err
})
}


export const setFavouriteLocation = (data:object) => {
  return httpRequest('POST',`${favouriteLocation}/admin`,data)
  .then((res)=> res)
  .catch((err)=>{
    throw err
  })
}

export const deleteFavouriteLocation = (data:object) => {
  return httpRequest('DELETE',`${favouriteLocation}/admin`,data)
  .then((res)=> res)
  .catch((err)=>{
    throw err
  })
}

//https://ecopooling-api.relinns.co.in/auth/public/favourite-locations?whatsAppNumber=918859170007

export const favouriteLocations = (id:object) => {
 return httpRequest('POST','auth/favourite-location/admin/user',id)
 .then((res)=> res)
 .catch((err)=>{
  throw err
 })
}

export const myEcoSavings = (id:string,filter?:object) => {
  return httpRequest('GET',`aggregation/marketplace/admin/eco-credits-entity/${id}`,{},filter)
  .then((res)=> res)
  .catch((err)=> err)
}

export const simulateMatching = (locationId:string) => {
  return httpRequest("GET",`/aggregation/invite/admin/simulate/${locationId}`)
  .then((res)=> res)
  .catch((err)=>{
    throw err
  })
}

// get all users/members of a private location with community admin type
export const getLocationMembers = (locationId: string) => {
  return httpRequest("GET", user + `/location/${locationId}`)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err
    });
};

// update user by id
export const updateUserById = (data: any) => {
  return httpRequest("PUT", user + "/driver-verification", data)
    .then((response) => {
      return response
    })
    .catch((err) =>{
      throw err
    });
};

export const getGroupUsersByUserId = (userId: string) => {
  return httpRequest("GET", `auth/group/admin/group-users/${userId}`)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err
    });
}

export const getUsersByName = (userName: string) => {
  return httpRequest("GET", `auth/user/user-by-name?name=${userName}`)
    .then((response: any) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

// get all marketplace users of carpool user
export const getMarketplaceUsers = (userId: string) => {
  return httpRequest("GET", `auth/admin/marketplace-users/${userId}`)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err
    });
}