import { FC, ReactNode } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root': {
        padding: '24px',
    },
    '& .MuiDialogContent-root': {
        padding: '24px',
    },
    '& .MuiDialogActions-root': {
        padding: '24px',
    },
    
}));

export interface DialogTitleProps {
    id: string;
    children?: ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 1.28 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

interface props {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    title: string;
    isOpen: boolean;
    disabled?: boolean;
    onEdit?: () => void;
    onClose: () => void;
    onConfirm?: () => void;
    children: ReactNode;
    btnText?: string
}

const CustomDialog: FC<props> = ({ size, title, btnText, isOpen, disabled, onClose, onConfirm, children }) => {

    return (
        <div>

            <BootstrapDialog
                maxWidth={size}
                onClose={() => onClose()}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                scroll='paper'
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => onClose()}>
                    {title}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {children}
                </DialogContent>
                {
                    !disabled &&
                    <DialogActions>
                        <Button variant='outlined' size='medium' sx={{marginRight:'8px'}} onClick={() => onClose()}>
                            Cancel
                        </Button>

                        <Button size='medium' autoFocus onClick={() => onConfirm && onConfirm()}>
                            {btnText}
                        </Button>

                    </DialogActions>
                }
            </BootstrapDialog>
        </div>
    );
}

CustomDialog.defaultProps = {
    size: 'md',
    disabled: false,
    onClose: () => { },
    onConfirm: () => { },
    btnText: "Save changes"
}

export default CustomDialog;
