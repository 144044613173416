import { FC, useEffect, useState } from "react";
import CustomDialog from "../../../../components/mui/dialog";
import { Box } from "@mui/material";
import { IManageProps} from "../../../../interfaces/payment";
import PaymentDetails from "../details";
import { getPaymentById } from "../../../../services/payment";
import { capitalizeFirstLetter, findNameByLanguage, formatDate } from "../../../../utils";
import { useLanguage } from "../../../../components/language-provider";
import Loader from "../../../../components/mui/loader/Loader";
import { enqueueSnackbar } from "notistack";
const Manage: FC<IManageProps> = ({ transactionId, isOpen, onClose }) => {
  const { language } = useLanguage();
  const [state, setState] = useState({
    loading: false,
    userName: "",
    email: "",
    gender: "",
    phone: "",
    userImage: "",
    paymentType: "",
    userPreference: "",
    transactionId: "",
    paymentDate: "",
    totalAmount: "",
    paymentMethod: "",
    paymentUniqueId: "",
  });
  useEffect(() => {
    if (transactionId && (isOpen === true)) {
        setState((prev)=>{
            return{
                ...prev,
                loading: true
            }
        })
      getPaymentById(transactionId)
        .then((res: any) => {
          const {
            data: {
              firstName,
              lastName,
              userImage,
              gender,
              email,
              phone,
              userPreference,
              transactionType,
              amount,
              paymentStatus,
              uniqueId,
              createdAt,
            },
          } = res;
       
          setState((prev) => {
            return {
              ...prev,
              userName: `${capitalizeFirstLetter(
                findNameByLanguage(language, firstName)
              )} ${capitalizeFirstLetter(
                findNameByLanguage(language, lastName)
              )}`,
              userImage,
              gender,
              email,
              phone,
              userPreference,
              totalAmount: amount.totalAmount,
              paymentDate: formatDate(createdAt),
              paymentUniqueId: uniqueId,
              paymentStatus,
              paymentType: transactionType,
              loading:false
            };
          });
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message || "User data cannot be fetched", { variant: "error" })
          
          setState((prev)=>{
              return{
                  ...prev,
                  loading: false
              }
          })

          onClose()
        });
    }
    // eslint-disable-next-line
  }, [transactionId, isOpen]);

  return (
    state.loading ? (
      <Loader />
    ) : (
      <CustomDialog
        title={"Details"}
        disabled={true}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Box>
          <PaymentDetails
            image={state.userImage}
            fullName={state.userName}
            gender={state.gender}
            email={state.email}
            phone={state.phone}
            paymentUniqueId={state.paymentUniqueId}
            totalAmount={state.totalAmount}
            paymentDate={state.paymentDate}
            userPreference={state.userPreference}
            paymentType={state.paymentType}
          />
        </Box>
      </CustomDialog>
    )
  );
  
};

export default Manage;
