import { IResponse } from "../../interfaces/configuration/whatsapp-bot";
import { httpRequest } from "../Network";
export const getWhatsAppBot = () => {
    return new Promise <any>((resolve,reject) => {
        httpRequest("GET", "/configuration/bot").then((response) => {
            resolve(response)
        }).catch((er) => reject(er))
    })
}
export const updateWhatsAppData = async (payload: any) => {
    return new Promise<IResponse>((resolve, reject) => {
        httpRequest("PUT", "/configuration/bot",payload).then((resposne:any) => {
            resolve(resposne);
        }).catch(er => {
            console.log("Error", er);
        })
    })
}