import { FC, useState, useEffect } from "react";
import CustomDialog from "../../../components/mui/dialog";
import { useLanguage } from "../../../components/language-provider";
import Loader from "../../../components/mui/loader/Loader";
import { favouriteLocations } from "../../../services/User";
import './index.scss';
import Favourites from "../../../components/Favourite Location";


interface props {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
  isDisabled: boolean;
}


interface state {
  locations: any[];
  userFavourite: any[];
  latitude?: number;
  longitude?: number;
  value?: string;
  category_id?: string;
  location_id?: string;
  favouriteLocationIds: string[];
  loading: boolean;
  schedule: object | undefined;
  categoryName: string,
  locationName: string,
  favorite: string | undefined
}

const initialState: state = {
  locations: [],
  userFavourite: [],
  favouriteLocationIds: [],
  loading: false,
  favorite: '',
  categoryName: '',
  locationName: '',
  schedule: {},

};


const FavouriteLocations: FC<props> = ({
  isOpen,
  userId,
  onClose,
}) => {

  const { language } = useLanguage();
  const [state, setState] = useState<state>(initialState);
  /* eslint-disable */
  useEffect(() => {
    if (isOpen && userId) {
      setState((prev) => {
        return {
          ...prev,
          loading: true
        }
      })
      const data = {
        userId: userId
      }
      favouriteLocations(data).then((res: any) => {
        const list = res.data.map((data: any, index: any) => (

          {
            schedule: data.schedule,
            dailySchedule: data.dailySchedule,
            categoryName: data?.locationCategory?.categoryId.locationName || "",
            locationName: data?.locationCategory?.locationName || "",
            invitedUsers: data.locationCategory?.invitedUsers ?? [],
          }
        ))
        console.log('list', list)
        setState((prev) => {
          return {
            ...prev,
            locations: list,
            loading: false
          }
        })
      })
        .catch((err) => console.log(err))
    }
  }, [userId, language]);

  /* eslint-enable */

  return (
    <>
      {state.loading && isOpen ? (
        <Loader />
      ) : (
        <CustomDialog
          title={"Favourite Locations"}
          isOpen={isOpen}
          onClose={onClose}
          disabled={true}
        >
          {state.locations.map((details, key) => (
            <Favourites
              border={state.locations.length > 1 ? true : false}
              details={details}
              userId={userId}
            />
          ))}
        </CustomDialog>
      )}
    </>
  );
};

export default FavouriteLocations;
