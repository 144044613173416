import React from 'react'
import CustomDialog from '../mui/dialog'
import GoogleMap from '../google-map';


interface props {
  latitude: number | undefined,
  longitude: number | undefined,
  value: string | undefined,
  isOpen: boolean;
  isDisabled?: boolean;
  onClose: () => void;
  handleChange?: (prev: any) => void;
  handleSelection?: () => void;
  favouriteLocationId?: string | undefined;
  setLocationDetail: React.Dispatch<React.SetStateAction<undefined>>
}
const SetLocation = ({
  isOpen,
  value,
  latitude,
  handleChange,
  setLocationDetail,
  longitude,
  onClose,
  handleSelection,
  favouriteLocationId,
  isDisabled,
}: props) => {
  return (
    <>
      <CustomDialog
        title={"Set Location"}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleSelection}
        disabled={isDisabled}
        btnText="Save"
      >
        <GoogleMap
          label="search"
          value={value}
          latitude={latitude}
          longitude={longitude}
          handleChange={handleChange}
          showMap={true}
          setLocation={setLocationDetail}
        />
      </CustomDialog>
    </>
  );
};

export default SetLocation
