import { ChangeEvent, FC, useEffect, useState } from "react";
import CustomDialog from "../../../components/mui/dialog";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { createAdmin, updateAdmin } from "../../../services/Admin";
import { useLanguage } from "../../../components/language-provider";
import { upload } from "../../../services/common";
import { findNameByLanguage } from "../../../utils";
import { enqueueSnackbar } from "notistack";
import { getAdmin } from "../../../services/Admin";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loader from "../../../components/mui/loader/Loader";
import { StyledTextField,StyledSelect} from "../../user/manage";
import * as yup from "yup";
import './index.scss'
import {
  IState,
  IProps,
  IPayload,
  IErrors,
//  ILocation,
} from "../../../interfaces/admin/index";
import uploadPic from "../../../assets/images/logo.png";
import { adminSchema } from "../../../utils";
import { CustomTextField } from "../../auth/login";

const Manage: FC<IProps> = ({ isOpen, data, type, isDisabled, onClose }) => {
  const { language } = useLanguage();
  //const [location, setLocationDetail] = useState<ILocation | any>();
  const [errors, setError] = useState<IErrors>({});
  const [passwordVisibility,setPasswordVisibility] = useState(false)
  isDisabled = type === "view" ? true : false;
  const [state, setState] = useState<IState>({
    info: {
      _id: "",
      image: "",
      firstname: "",
      lastname: "",
      email: "",
      gender:"",
      phone: "",
      roleId: "",
      roleType:"",
      status: "",
      password: "",
    },
    loading: true,
  });

  const schema = adminSchema(type)

  const handleChange = (event: ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = event.target;

    if (errors) {
      setError((prev: object) => ({
        ...prev,
        [name]: false,
      }));
    }

    setState((prevState) => ({
      ...prevState,
      info: {
        ...prevState.info,
        [name]: value,
      },
    }));
  };

  const [payload, setPayload] = useState<IPayload | object>({});

  const fetchData = (id:string) => {
    setState((prev)=>{
      return{
        ...prev,
        loading:true
      }
    })
    getAdmin(id).then((response: any) => {
      setState((prevState) => {
        return {
          ...prevState,
          info: {
            ...prevState.info,
            _id: data._id,
            image: `${response.data.photo}`,
            firstname: `${findNameByLanguage(
              language,
              response.data.firstName
            )}`,
            lastname: `${findNameByLanguage(
              language,
              response.data.lastName
            )}`,
            gender:response.data.gender,
            email: response.data.email,
            phone: `${response.data.phone}`,
            roleId:`${response.data.role.roleId}`,
            roleType:`${response.data.role.roleType}`,
            status: `${response.data.status}`,
          },
           loading: false,
        };
      });
    });
  } 

  /* eslint-disable */
  useEffect(() => {
    if (type === "edit") {
      setError({});
      fetchData(data._id)
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          info: {
            ...prevState.info,
            _id: "",
            image: "",
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            gender: "",
            role: "",
            status: "",
            password: "",
          },
          loading:false
        };
      });
    }
  }, [type, data]);


  useEffect(() => {
    const payload: IPayload = {
      firstName: [
        {
          lang: language,
          value: state.info.firstname,
        },
      ],
      lastName: [
        {
          lang: language,
          value: state.info.lastname,
        },
      ],
      photo: state.info.image,
      email: state.info.email,
      gender: state.info.gender,
      role: type === 'edit' ? { roleId: state.info.roleId, roleType: state.info.roleType } : "ADMIN",
      phone: state.info.phone
    };

    if (type === "new") {
      payload.password = state.info.password;
    }

    if (type === "edit") {
      payload._id = state.info._id;
    }
    setPayload(payload);
  }, [state.info]);
  /* eslint-enable */


  const onEdit = () => {};

  const uploadImage = (e: ChangeEvent<HTMLInputElement> | any) => {
    const files = e.target.files;
    const maxSize = 3 * 1024 * 1024;
    const fileSize = files[0]?.size / 1024 / 1024;
    if (files?.length && fileSize < maxSize)
      upload(files[0])
        .then((response) =>{
          setState((prevState) => {
            return {
              ...prevState,
              info: {
                ...prevState.info,
                image: response,
              },
            };
          })
          if(errors && errors.image){
            setError((prev) => {
              return {
                ...prev,
                image: "",
              };
            });
          }
        }        
        )
        .catch((er) =>
          enqueueSnackbar("Couldn't upload image", {
            variant: "error"
          })
        );
  };


  const handleSubmit = () => {
    if (type === "new") {
      schema
        .validate(state.info, { abortEarly: false })
        .then(() => {
          createAdmin(payload)
            .then(() => {
              setError({});
              enqueueSnackbar("User Added", {
                variant: "success"
              });
              onClose();
            })
            .catch((err) => console.log(err));
        })
        .catch((error) => {
          const errors: { [key: string]: string } = {};
          error.inner.forEach((fieldError: yup.ValidationError) => {
            errors[fieldError.path!] = fieldError.message;
          });
          setError(errors);
        });
    }
    if (type === "edit") {
      schema
        .validate(state.info, { abortEarly: false })
        .then(() => {
          updateAdmin(payload)
            .then(() => {
              enqueueSnackbar("Data Updated", {
                variant: "success"
              });
              onClose();
            })
            .catch((err) => enqueueSnackbar("Couldn't update data", {
              variant: "error"
            }));
        })
        .catch((error) => {
          const errors: { [key: string]: string } = {};
          error.inner.forEach((fieldError: yup.ValidationError) => {
            errors[fieldError.path!] = fieldError.message;
          });
          setError(errors);
        });
    }
  };

  return (
    <>
      {(state.loading && isOpen) ? (
        <Loader />
      ) : (
        <CustomDialog
          title={"Add User"}
          isOpen={isOpen}
          onClose={() => {
            setError({});
            onClose();
          }}
          onConfirm={handleSubmit}
          disabled={isDisabled}
          onEdit={onEdit}
        >
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} className='grid-box'>
                <Box className="center image-container" sx={{margin:"16px"}}>
                  <Box
                    height="100px"
                    width="100px"
                    className="img-circle"
                    aria-label="upload picture"
                    component="label"
                  >
                    <img
                      alt="Profile-Pic"
                      src={state.info.image || uploadPic}
                    />
                    <div className="edit-img ">Edit</div>
                    <FormControl error={!!(errors && errors.image)} fullWidth>
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={uploadImage}
                      />
                    </FormControl>
                  </Box>
                  {errors && errors.image && (
                        <small className="image-error-message">{errors.image}</small>
                      )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6} className='grid-box'>
              <StyledTextField
                  label="First Name"
                  name="firstname"
                  value={state.info.firstname}
                  disabled={isDisabled}
                  onChange={handleChange}
                />
                {errors && errors.firstname && (
                  <small className="error-message">{errors.firstname}</small>
                )}
              </Grid>
              <Grid item xs={12} md={6} className='grid-box'>
              <StyledTextField
                  label="Last Name"
                  name="lastname"
                  value={state.info.lastname}
                  disabled={isDisabled}
                  onChange={handleChange}
                />
                {errors && errors.lastname && (
                  <small className="error-message">{errors.lastname}</small>
                )}
              </Grid>
              <Grid item xs={12} md={6} className='grid-box'>
              <CustomTextField
                  label="Email"
                  name="email"
                  value={state.info.email}
                  disabled={isDisabled}
                  onChange={handleChange}
                />
                {errors && errors.email && (
                  <small className="error-message">{errors.email}</small>
                )}
              </Grid>
              <Grid item xs={12} md={6} className='grid-box'>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <StyledSelect
                    name="gender"
                    value={state.info.gender}
                    onChange={handleChange}
                    label="Gender"
                  >
                    <MenuItem disabled>Select</MenuItem>
                    <MenuItem value="MALE">Male</MenuItem>
                    <MenuItem value="FEMALE">Female</MenuItem>
                  </StyledSelect>
                </FormControl>
                {errors && errors.gender && (
                  <small className="error-message">{errors.gender}</small>
                )}
              </Grid>

              <Grid item xs={12} md={6} className='grid-box'>
              <StyledTextField
                  label="Phone"
                  name="phone"
                  value={state.info.phone}
                  disabled={isDisabled}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/; // regex to match only digits
                    if (e.target.value === '' || re.test(e.target.value)) {
                        handleChange(e);
                    }
                }}
                />
                {errors && errors.phone && (
                  <small className="error-message">{errors.phone}</small>
                )}
              </Grid>
              {type === "new" && (
                <Grid item xs={12} md={6} className='grid-box'>
                 <StyledTextField
                    label="Password"
                    name="password"
                    type = {passwordVisibility?'text':'password'}
                    value={state.info.password}
                    disabled={isDisabled}
                    onChange={handleChange}
                  />
                   <span
                    className={passwordVisibility ? "admin-visibility" : "hide-eye"}
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  >
                    <Visibility className="visibility-icon"  sx={{color:"#707070"}} />
                  </span>
                  <span
                    className={
                      !passwordVisibility ? "admin-visibility-off" : "hide-eye"
                    }
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  >
                    <VisibilityOff className="visibility-officon" sx={{color:"#707070"}}/>
                  </span>
                  {errors && errors.password && (
                    <small className="error-message" style={{ color: "red" }}>
                      {errors.password}
                    </small>
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

Manage.defaultProps = {
  isDisabled: true,
};

export default Manage;