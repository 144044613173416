import { Box, Switch } from "@mui/material"
import { enqueueSnackbar } from "notistack"
import { useCallback, useEffect, useState } from "react"
import { getMaintenanceDetails, updateMaintenanceDeatils } from "../../../services/maintenance"
import WarningDialog from "../../../components/mui/warning-dialog"
import Loader from "../../../components/mui/loader/Loader"

export const MaintenanceSettings = () => {
  const [maintenance, setMaintenance ] = useState({
    active: false
  });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  const getData = useCallback(async () => {
    setShowLoading(true);

    try {
      const maintenanceDetails = await getMaintenanceDetails();
  
      setMaintenance(state => ({
        ...state,
        active: maintenanceDetails.active
      }));
    } catch (error) {
      enqueueSnackbar("Error occurred while fetching maintenance details", { variant: "error" });
    } finally {
      setShowLoading(false);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  // toggle button onclick listener
  const handleMaintenanceToggle = async () => {
    setShowLoading(true);

    try {
      // toggle maintenance active in DB
      await updateMaintenanceDeatils({
        active: !maintenance.active,
      });

      setMaintenance({
        active: !maintenance.active
      })
    } catch (error) {
      enqueueSnackbar((error as Error).message || 'Error occurred while updating maintenance details', {
        variant: 'error'
      })
    } finally {
      setShowDeleteDialog(false);
      setShowLoading(false);
    }
  }

  return (
    <Box>
      <h5 className="mb-1">Maintenance mode</h5>
      <Switch
        title="Maintenance mode"
        checked={maintenance.active}
        onChange={() => setShowDeleteDialog(true)}
      />

      {
        showLoading &&
        <Loader />
      }
      
      <WarningDialog
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onConfirm={handleMaintenanceToggle}
        title="Toggle maintenance"
        description="Are you sure you want to perform this operation?"
      />

    </Box>
  )
}