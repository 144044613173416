import { IGiftProviderLocation } from '../../../interfaces/configuration/gift-provider';
import { httpRequest } from '../../Network';
export const giftListing = (filter:object) => {
    return new Promise<any>((resolve, reject) => {
        httpRequest("GET", `/configuration/gift`,{},filter).then((reponse) => {
            resolve(reponse)
        }).catch((er) => {
            reject(er);
        })
    })
}
export const getGiftProviderById = (id:string) => {
    return httpRequest('GET',`configuration/gift/${id}`)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}
export const uploadImageDoc = (payload: any) => {
    return new Promise((resolve, reject) => {
        httpRequest("POST", "/upload", { payload }).then((response) => {
            resolve(response);
        }).catch((er) => {
            reject(er);
        })
    })
}
export const addGift = (payload: any) => {
    return new Promise((resolve, reject) => {
        httpRequest("POST", "/configuration/gift", payload).then((resposne) => {
            resolve(resposne);
        }).catch((er) => {
            reject(er);
        })
    })
}
export const updateGiftStatus = (id: any, status: any) => {
    return new Promise((resolve, reject) => {
        httpRequest("PUT", "/configuration/gift/status", { id, status }).then((response) => resolve(response))
            .catch((er) => {
                console.log("Error", er);
            })
    })
}
export const deleteGift = (payload: any) => {
    return new Promise((resolve, reject) => {
        httpRequest("DELETE", "/configuration/gift", payload).then((response) =>
            resolve(response)).catch((er) => {
                console.log('Error', er);
            })
    })
}
export const editGift = (payload:object) => {

    return new Promise((resolve, reject) => {
        httpRequest("PUT", "/configuration/gift", payload).then((response) => {
            resolve(response);
        }).catch((er) => { reject(er) })
    })
}
export const orderCheck = (payload:object) => {
    return new Promise((resolve, reject) => {
        httpRequest("POST", "/configuration/gift/order-check", payload).then((response) => {
            resolve(response)
        }).catch((er) => reject(er))
    })
}
export const searchGiftProvider = (status: string, searchValue: string) => {
    return new Promise<IGiftProviderLocation | any>((resolve, reject) => {
        httpRequest("GET", `/configuration/gift?status=${status}&searchValue=${searchValue}`).then((response) => {
            resolve(response)
        }).catch((er) => reject(er))
    })
}