import React from "react";
import CustomDialog from "../../../../components/mui/dialog";
import { useState, useEffect } from "react";
import { capitalizeFirstLetter, findNameByLanguage } from "../../../../utils";
import { useLanguage } from "../../../../components/language-provider";
import Button from "@mui/material/Button";
import Loader from "../../../../components/mui/loader/Loader";
import { whatsAppWebHook } from "../../../../services/whatsapp-webhook";
import { enqueueSnackbar } from "notistack";
import { simulateMatching } from "../../../../services/User";
import { updateInvitedUsers } from "../../../../services/configurations/locations";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  locationId: string;
  locationName: string;
  fetchData: () => void;
}

interface IUserProps {
  image: string;
  name: string;
  email: string;
  phone: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  zip: string | number;
  gender: string;
  preference: string;
}

const UserDetails = ({
  image,
  name,
  email,
  phone,
  gender,
  line1,
  line2,
  city,
  state,
  country,
  zip,
  preference,
}: IUserProps) => {
  return (
    <div className="user-detail-container ">
      <div className="user-detail-card">
        <div className="user-row">
          <div className="col-xs-12 user-image-container">
            <img src={image} className="user-profile-image" alt="user-pic" />
          </div>
          <div className="col-md-6 col-xs-12 user-details">
            <span>
              <b>{name}</b>
            </span>

            <address className="text-wrap">
              <span>
                <b>Address: </b>
              </span>
              {line1},&nbsp;
              {line2}&nbsp;
              {city}
              ,&nbsp;{state},&nbsp; {country},&nbsp; {zip}
            </address>

            <span>
              <b>Gender : </b>
            </span>
            <span>{capitalizeFirstLetter(gender)}</span>

            <br />
            <span>
              <b>Email : </b>
            </span>
            <span>{email}</span>

            <br />
            <span>
              <b>Phone : </b>
            </span>
            <span>{phone}</span>
            <br />
            <span>
              <b>Preference : </b>
            </span>
            <span>{preference}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const MatchedUsers = ({
  isOpen,
  onClose,
  locationId,
  locationName,
  fetchData,
}: IProps) => {
  const [matchedUser, setMatchedUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { language } = useLanguage();

  const handleInviteButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const buttonElement = e.currentTarget || e.target;

    if (buttonElement.textContent === "invite") {
      buttonElement.textContent = "invited";
      buttonElement.disabled = true;
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);

      simulateMatching(locationId)
        .then((res: any) => {
          console.log(res);

          setLoading(false);

          setMatchedUsers(res.data);
        })
        .catch((err) => {
          console.log(err)

          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [isOpen === true, locationId]);

  const inviteAll = () => {
    let phoneNo = matchedUser.map((user, key) => ({
      whatsAppNumber: user.userInfo[0].phone,
      userPreference: user.userInfo[0].userPreference,
      jti: user.userInfo[0].jti,
    }));

    let userIds = matchedUser.map((user) => user._user);

    let payload = {
      type: "FIRSTMESSAGE",
      locationName,
      users: phoneNo,
    };

    whatsAppWebHook(payload)
      .then(async () => {
        // make a request to add the user in the location invited list
        await updateInvitedUsers(locationId, { userIds })

        enqueueSnackbar("Invited all", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Couldn't invite users, try after some time", {
          variant: "error",
        });
      });
  };

  const inviteUser = (
    payload: object,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    userId: string,
    locationId: string
  ) => {
    whatsAppWebHook(payload)
      .then(async () => {
        // make a request to add the user in the location invited list
        await updateInvitedUsers(locationId, { userIds: [userId] })

        enqueueSnackbar("Invited", { variant: "success" });

        handleInviteButtonClick(e)
      })
      .catch(() => {
        enqueueSnackbar("Couldn't invite users, try after some time", {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <CustomDialog
          title="Matched Results"
          isOpen={isOpen}
          onClose={() => {
            onClose();
            fetchData();
          }}
          btnText="Invite all"
          onConfirm={() => {
            inviteAll();
            onClose();
          }}
          disabled={matchedUser.length === 0}
        >
          {(matchedUser || [])?.map((user: any, key) => {
            const {
              userInfo: [
                {
                  firstName,
                  lastName,
                  photo,
                  phone,
                  email,
                  gender,
                  address: { line1, line2, city, state, country, zip },
                  userPreference,
                  jti,
                },
              ],
            } = user;
            return (
              <React.Fragment>
                <UserDetails
                  key={key}
                  name={`${findNameByLanguage(
                    language,
                    firstName
                  )} ${findNameByLanguage(language, lastName)}`}
                  image={photo}
                  phone={phone}
                  email={email}
                  gender={gender}
                  line1={findNameByLanguage(language, line1) as string}
                  line2={findNameByLanguage(language, line2) as string}
                  city={findNameByLanguage(language, city) as string}
                  state={findNameByLanguage(language, state) as string}
                  country={findNameByLanguage(language, country) as string}
                  zip={zip}
                  preference={userPreference}
                />
                <div className="send-invite-btn-container d-flex justify-end">
                  <Button
                    size="medium"
                    className="br-20"
                    autoFocus
                    onClick={(e) => {
                      inviteUser({
                        type: "FIRSTMESSAGE",
                        locationName,
                        users: [
                          {
                            whatsAppNumber: phone,
                            userPreference: userPreference,
                            jti: jti,
                          },
                        ],
                      }, e, user._user, locationId);
                    }}
                  >
                    invite
                  </Button>
                </div>
                {matchedUser.length > 1 && key !== matchedUser.length - 1 && (
                  <hr
                    style={{
                      marginTop: "16px",
                      marginBottom: "16px",
                      borderTop: "1px solid #0000001a",
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}

          {matchedUser.length === 0 && (
            <div className="h-200 d-flex items-center justify-center">
              <b>No users found for this location !</b>
            </div>
          )}
        </CustomDialog>
      )}
    </React.Fragment>
  );
};

export default MatchedUsers;
