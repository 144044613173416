import { useState, SyntheticEvent, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
//import Send from '@mui/icons-material/Send';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
//import {Button,Tooltip} from '@mui/material'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const redirectionLinks = ['category','locations', 'whatsapp-bot','carbon-saving']

const Configuration = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setValue(redirectionLinks.indexOf(location.pathname.split('/')[2]));
  }, [location.pathname]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    
    navigate(redirectionLinks[newValue]);
  };

  return (
    <Box width="100%">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {/* <Tooltip title="Send Templates">
        <Button color="primary" variant='outlined'>
           <Send/>
        </Button>
        </Tooltip> */}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            className="tabs-space start-tab-space"
            label="Location Category"
            {...a11yProps(0)}
          />
          <Tab className="tabs-space" label="Locations" {...a11yProps(1)} />
          {/* <Tab className='tabs-space' label="Gift Provider" {...a11yProps(2)} /> */}
          {/* <Tab className="tabs-space" label="Declaration" {...a11yProps(2)} /> */}
          <Tab className="tabs-space" label="WhatsApp Bot" {...a11yProps(3)} />
          {/* <Tab className="tabs-space" label="Stripe" {...a11yProps(4)} /> */}
          <Tab className="tabs-space" label="Other" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
}

export default Configuration;
