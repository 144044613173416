import { httpRequest } from "../Network";
import { giftCategory } from "../endpoints";


export const addGiftCategory = (payload:object) => {
    return httpRequest('POST',giftCategory,payload)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}
export const getAllGiftCategories = (filter?:object) => {
    return httpRequest('GET',giftCategory,{},filter)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}

export const getGiftCategoryById = (id:string) => {
  return httpRequest("GET",`${giftCategory}/${id}`)
  .then((res)=> res)
  .catch((err)=> {
    throw err
  })
}

export const updateGiftCategory = (id:string,payload:object) => {
    return httpRequest("PUT",`${giftCategory}/${id}`,payload)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}


export const updateGiftCategoryStatus = (id:string,payload:object) => {
    return httpRequest("PUT",`${giftCategory}/status/${id}`,payload)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}

export const deleteGiftCategory = (id:string) => {
    return httpRequest("DELETE",`${giftCategory}/${id}`)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}

export const getUserGiftReceipt = (_id: string) => {
    return httpRequest("POST", 'payment/transaction/admin/gift-card-receipt', {
        _id
    })
    .then((res: any) => res.data)
    .catch(err => {throw err;})
}