import "./index.scss";
import { findNameByLanguage } from "../../utils";
import { useLanguage } from "../language-provider";
import profileIcon from '../../assets/images/logo.png';
import greenReceiptIcon from '../../assets/images/green_reciept.svg';
import { getUserTripReceipt } from "../../services/Trips";
import { enqueueSnackbar } from "notistack";

const PassengerDetail = (props: any) => {
  const { data, tripId } = props;
  console.log(data,'djflsdj')
  const { language } = useLanguage();
  return (
    <div className="passenger-container">
      <div className="passenger-info">
        <div className="passenger-image">
          <img
            className="passenger-image"
            src= {data?.photo || profileIcon}
           alt=""
          />
        </div>
        <div className="passenger-name-and-phone-number">
          <div>
            <b>
              {findNameByLanguage(language, data?.firstName) +
                " " +
                findNameByLanguage(language, data?.lastName)}
            </b>
          </div>
          <div style={{ marginTop: "4px" }}>{data?.email}</div>
          <div style={{ marginTop: "4px" }}>{data?.phone}</div>
        </div>
      </div>
      <div className="passenger-receipt-icon" onClick={async () => {
              try {
                const link = (await getUserTripReceipt(data?._id, tripId )).path;

                let url = '';

                // https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js
                // fetch('https://cors-anywhere.herokuapp.com/'+link, {
                fetch(link, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/pdf',
                  },
                })
                  .then(response => response.blob())
                  .then(blob => {
                    url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href=url;
                    a.download='trip_receipt.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                  })
                  .catch(err => {
                    enqueueSnackbar(err.message || 'Error occurred while downloading receipt', {
                      variant: 'error'
                    })
                  })
                  .finally(() => {
                    URL.revokeObjectURL(url);
                  });
              
                // const a = document.createElement('a');
                // a.href=link;
                // a.download='trip_receipt.pdf';
                // a.target="_blank";
                // document.body.appendChild(a);
                // a.click();
                // document.body.removeChild(a);
              } catch (error) {
                enqueueSnackbar('Error occurred while generating receipt')
              }
            }}>
        <img src={greenReceiptIcon} alt="Reciept Icon" />
      </div>
    </div>
  );
};

export default PassengerDetail;
