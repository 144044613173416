import { ChangeEvent, useEffect, useState } from "react";
import {
  Typography,
  Tooltip,
  IconButton,
  Button,
  FormLabel,
} from "@mui/material";
import Header from '../../../components/header'
import CustomTable from "../../../components/mui/table";
import { useParams } from "react-router-dom";
import Manage from "./manage";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningDialog from "../../../components/mui/warning-dialog";
import { deletePayment, exportPayment, getPaymentList } from "../../../services/payment";
import { capitalizeFirstLetter, formatDate } from "../../../utils";
import { IPaymentListing, IState } from "../../../interfaces/payment";
import Loader from "../../../components/mui/loader/Loader";
import { enqueueSnackbar } from "notistack";
import messages from "../../../assets/messages";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Download  from "@mui/icons-material/Download";
import ExportDialog from "../../../components/mui/dialog/exportDialog";


let list: any[] = [];

type params = {
  duration: string;
};

//let filter: { users: (string | any)[] } = { users: [] };
const PaymentList = () => {
  const param = useParams<params>();

  const { duration } = param;

  const [state, setState] = useState<IState>({
    manage: {
      isOpen: false,
      type: "view",
    },
    list: [],
    filter: "All",
    users: [],
    page: 1,
    totalPage: 1,
    deleteId: "",
    laoder: false,
    deleteWarning: false,
    exportTrip: false,
    data: {
      _id: "",
      registration_id: "",
      trip_id: "",
      driver_name: "",
      email: "",
      phone: "",
      total_distance: "",
      paymentMethod: "",
      paymentStatus: "",
      dropDate: "",
      image: "",
    },
    transactionId : "",
    isOpen : false,
    startDate: {},
    endDate: {},
  });

  const fetchData = (searchValue?: string) => {
    setState({
      ...state,
      laoder: true,
      deleteWarning: false,
      manage: { ...state.manage, isOpen: false },
    });
    if (duration === "all") {
      getPaymentList({ tripId: searchValue, page: state.page })
        .then((res: IPaymentListing) => {
          list = res.data.map((ele: any, index: number) => {
            return createRow(ele, index, handleManage, handleDelete);
          });
          setState({
            ...state,
            list,
            page: res.meta.page,
            totalPage: res.meta.totalPages,
            laoder: false,
            deleteWarning: false,
            manage: { ...state.manage, isOpen: false },
          });
        })
        .catch((err) => {
          console.log(err)
          setState({ ...state, laoder: false });
        });
    } else {
      getPaymentList({ paymentStatus: duration?.toUpperCase(), tripId: searchValue, page: state.page })
        .then((res: IPaymentListing) => {
          list = res.data.map((ele: any, index: number) => {
            return createRow(ele, index, handleManage, handleDelete);
          });
          setState({
            ...state,
            list,
            page: res.meta.page,
            totalPage: res.meta.totalPages,
            laoder: false,
            deleteWarning: false
          });
        })
        .catch((err) => {
          setState({ ...state,list:[],page:1,totalPage:1,laoder: false });
        });
    }
  };

  const handleSearch = (event: ChangeEvent<unknown> | any) => {
    const searchValue = event.target.value;

    if (event.key === "Enter") {
      fetchData(searchValue)
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [duration, state.page]);

  const onPageChange = (e: ChangeEvent<unknown> | any, newPage: number) => {
    setState({ ...state, page: newPage });
  };

  const handleDelete = (id: string) => {
    setState({ ...state, deleteId: id, deleteWarning: true, list });
  };

  const handleManage = (
    transactionId: string,
  ) => {
    setState((prev)=>{
      return{
        ...prev,
        isOpen : true,
        transactionId
      }
    })
  };

  const onDelete = () => {
    deletePayment(state.deleteId)
      .then(() =>
        enqueueSnackbar(`${messages.snackbar.success.delete}`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        })
      )
      .catch((er) => {
        if (er.message === "Network Error") {
          enqueueSnackbar(`${messages.snackbar.error.delete}`, {
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
            variant: "error",
          });
        }
      });
    fetchData();
  };

  const columns = [
    // {
    //   id: "id",
    //   label: "S.No",
    // },
    {
      id: "transactionId",
      label: "Transaction ID",
    },
    {
      id: "transactionType",
      label: "Transaction Type",
    },
    {
      id: "trip_id",
      label: "Trip ID",
    },
    {
      id: "gift_id",
      label: "Gift ID",
    },
    {
      id: "total_amount",
      label: "Total amount",
    },
    {
      id: "payment_date",
      label: "Payment Date",
    },
    {
      id: "payment_method",
      label: "Payment Method",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "actions",
      label: "Action",
    },
  ];



  const createRow = (
    payment: any,
    index: number,
    showDetail: any,
    onDelete: any
  ) => {
    const transactionId = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={() => {
          showDetail(
            payment?.transactionId
          );
        }}
      >
        {payment.transactionId}
      </Typography>
    );
    const actions = (
      <Tooltip title="delete">
        <IconButton onClick={() => onDelete(payment._id)}>
          <DeleteIcon color="error" />
        </IconButton>
      </Tooltip>
    );
    return {
      id: index + 1,
      transactionId,
      transactionType: payment.transactionType,
      gift_id: payment.giftUniqueId ? <span style={{ padding: '4px' }}>{payment.giftUniqueId} <ContentCopyIcon className="copy-icon" onClick={() => handleCopyClick(payment.giftUniqueId)} /></span> : "",
      trip_id: <div> {payment._trip?.map((trips: any) => (payment.tripUniqueId || []).map((itm: any) => <span style={{ padding: '4px' }}>{itm} <ContentCopyIcon className="copy-icon" onClick={() => handleCopyClick(itm)} /></span>))} </div>,
      total_amount: "£" + payment?.amount?.totalAmount?.toFixed(2),
      payment_date: formatDate(payment?.createdAt),
      payment_method: "Online",
      status: capitalizeFirstLetter(payment?.paymentStatus),
      actions: actions,
    };
  };

  const handleCopyClick = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    enqueueSnackbar(`${textToCopy} Copied`, {
      variant: "success",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    })
  };

  // export button click handler
  const handleExport = () => {
    const payload = {
      startDate: (state.startDate).toISOString() ,
      endDate: (state.endDate).toISOString(),
    };

    if (!state.startDate || !state.endDate) {
      return enqueueSnackbar("Select start date and end date first", {
        variant: "error",
      });
    }

    exportPayment(payload)
      .then((res:any) => {        
        enqueueSnackbar("Data exported", {
          variant: "success",
        });

        handleClose();

        window.open(res.data,"_self")
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });

        handleClose();
      });
  };

  // export modal onClose handler
  const handleClose = () => {
    setState({ ...state, startDate:{}, endDate:{}, exportTrip: !state.exportTrip });
  };

  return (
    <>

      {/* Show Data  */}
      <Header
        searchPlaceholder="Search by trip id"
        onSearch={handleSearch}
        onBtnClick={() => { }}
        searchInput
      >
        <Tooltip title="Export">
          <Button
            color="primary"
            variant="outlined"
            className="ml-2 h-100"
            onClick={() =>
              setState({ ...state, exportTrip: !state.exportTrip })
            }
          >
           <Download/>
          </Button>
        </Tooltip>
      </Header>

      <CustomTable
        columns={columns}
        rows={state.list}
        height="calc(100vh - 252px)"
        errorMessage="Do Transaction to see the data here"
        pagination={{
          page: state.page,
          totalPages: state.totalPage,
        }}
        onPageChange={onPageChange}
      />

      <Manage
        isOpen={state.isOpen}
        onClose={() => setState({ ...state, isOpen: false })}
        transactionId={state.transactionId}
      />

      <WarningDialog
        isOpen={state.deleteWarning}
        title="Delete Payment"
        description="Are you sure you want to delete this payment"
        onClose={() =>
          setState({
            ...state,
            deleteWarning: false,
            manage: { ...state.manage, isOpen: false },
          })
        }
        onConfirm={onDelete}
      />

      {/* Export Data  */}
      <ExportDialog
        title="Export Trips"
        open={state.exportTrip}
        onConfirm={handleExport}
        onClose={() => handleClose()}
        btnText="Export Trips"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="date-container">
            <FormLabel sx={{marginBottom:'5px'}} className="date-label">
              Start Date
            </FormLabel>

            <DatePicker
              label={state.startDate ? "" : "Select Start Date"}
              className="start-date"
              value={state.startDate}
              minDate={
                state.endDate
                  ? dayjs(state.endDate).subtract(1, "month")
                  : undefined
              }
              maxDate={dayjs(new Date())}
              onChange={(newValue) =>
                setState((prev) => {
                  return {
                    ...prev,
                    startDate: (newValue),
                  };
                })
              }
            />
          </div>
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="date-container">
            <FormLabel sx={{marginBottom:'5px',marginTop:'5px'}} className="date-label">
              End Date
            </FormLabel>

            <DatePicker
              label={state.endDate ? "" : "Select End Date"}
              className="end-date"
              value={state.endDate}
              minDate={state.startDate ? dayjs(state.startDate) : ""}
              maxDate={
                state.startDate &&
                dayjs().diff(dayjs(state.startDate), "day") > 31
                  ? dayjs(state.startDate).add(1, "month")
                  : dayjs(new Date())
              }
              onChange={(newValue) =>
                setState((prev) => {
                  return {
                    ...prev,
                    endDate: (newValue),
                  };
                })
              }
            />
          </div>
        </LocalizationProvider>
      </ExportDialog>

      {state.laoder && <Loader />}
    </>
  );
};

export default PaymentList;
