import { FC, useEffect, useState,ChangeEvent } from 'react';
import CustomDialog from '../../../../components/mui/dialog';
import { Box,Grid } from '@mui/material';
import { StyledTextField} from '../../../user/manage';
import { addGiftCategory,getGiftCategoryById,updateGiftCategory} from '../../../../services/Gift Category';
import { useSnackbar } from 'notistack';
import { useLanguage } from '../../../../components/language-provider';
import { findNameByLanguage } from '../../../../utils';
import { giftCategorySchema } from '../../../../utils';
import Loader from '../../../../components/mui/loader/Loader';
import { orderCheck } from '../../../../services/configurations/giftProvider';
import * as yup from 'yup';
import { debounce } from '../../../../utils';
import { showError } from '../../../../utils';

interface props {
    isOpen: boolean;
    type: any;
    id:string;
    isDisabled?: boolean;
    onClose: () => void;
    fetchData:() => void
}

interface IError {
  category?: string,
  order ?: string
}

const ManageCategory: FC<props> = ({ isOpen, type,id, isDisabled, onClose,fetchData }) => {
   
   const [categoryState,setCategoryState] = useState({
    category:'',
    order:''
   })
   const [errors,setErrors] = useState<IError>({
    category:'',
    order: ''
   })
   const [loading,setLoading] = useState(false)
   const [categoryOrder,setCategoryOrder] = useState("")

   const {language} = useLanguage()
   const {enqueueSnackbar} = useSnackbar()
    const handleChange = (event:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {name,value} = event.target;
      if(name === 'order' && value !== ""){
       debouncedSearch(value)
      }
      if (errors) {
        setErrors((prev: object) => ({
          ...prev,
          [name]: false,
        }));
      }
        setCategoryState((prev)=> {
          return{
            ...prev,
            [name]:value
          }
        })  
    }
  
    const handleConfirm = () => {
      console.log(categoryState)
     giftCategorySchema.validate(categoryState,{abortEarly:false})
     .then(()=>{
          const payload = {
            giftCategoryName:[{
              lang:language,
              value: categoryState.category.trim()
            }],
            order:categoryState.order
          }
          if(type === 'new'){
            addGiftCategory(payload)
            .then(()=>{
              enqueueSnackbar("Category added successfully",{variant:"success"})
              handleClose()
              fetchData()
            })
            .catch(()=> enqueueSnackbar("Category already exist",{variant:"error"}))
          }
          else{
            updateGiftCategory(id,payload)
            .then(()=>{
              enqueueSnackbar("Category updated successfully",{variant:"success"})
             handleClose()
              fetchData()
            } )
            .catch((err)=> enqueueSnackbar(showError(err.message),{variant:"error"}))
          }   
     })
     .catch((error)=>{
      const allError: { [key: string]: string } = {};
      error.inner.forEach((fieldError: yup.ValidationError) => {
        allError[fieldError.path!] = fieldError.message;
      });
      setErrors(allError)
     })
    }
//eslint-disable
    useEffect(()=>{
      if(type === 'edit' && id){
        setLoading(true)
          getGiftCategoryById(id)
          .then((res:any)=> {
            const name = findNameByLanguage(language,res.data.giftCategoryName)
            setCategoryState((prev:any)=> {
              return{
                ...prev,
                category:name,
                order : res.data.order
              }
            })
            setLoading(false)
            
          })
          .catch((err)=> console.log(err))
      }
      else{
        setCategoryState((prev:any)=>{
          return{
            ...prev,
            category:"",
            order:""
          }
        })
      }
      // eslint-disable-next-line
    },[id])
//eslint-enable
    const handleClose = () => {
      if(type === 'new'){
        setCategoryState((prev)=>{
          return{
            ...prev,
            category:'',
            order:''
          }
        })
      }
      setErrors((prev)=>{
        return{
          ...prev,
          order:"",
          category:""
        }
      })
      onClose()
    }

    const handleOrderChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setCategoryOrder(event.target.value);
      debouncedSearch(event.target.value);
    };

    function checkOrderAvailability(order:string|number) {
    
      if(order){
        let query ={}
      
        if(type==='new'){
           query = {
            order : order
          }
        }
  
        if(type === 'edit'){
          query = {
            order : order,
            id: id
          }
        }
        orderCheck(query)
        .then((res:any)=> {
          if(res.data.exist){
            return setErrors((prev)=>{
              return{
                ...prev,
                order:"Order already exists"
              }
            })
          }
          else{
            setErrors((prev)=>{
              return{
                ...prev,
                order:"Order available"
              }
            })
          setCategoryState((prev)=>{
              return{
                ...prev,
                order:categoryOrder
              }
            })
          }
        })
        .catch((err)=> err)
      }
     
     
    }

    const debouncedSearch = debounce(checkOrderAvailability, 300);
    return (
      <>
        {loading && isOpen ? (
          <Loader />
        ) : (
          <CustomDialog
            title={type === "new" ? "Add New Category " : "Edit Category"}
            disabled={isDisabled}
            isOpen={isOpen}
            onConfirm={handleConfirm}
            onClose={handleClose}
          >
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} className="grid-box">
                  <StyledTextField
                    label="Gift Category Name"
                    name="category"
                    value={categoryState.category}
                    onChange={handleChange}
                    disabled={isDisabled}
                  />
                  {errors && errors.category && (
                    <small className="error-message">{errors.category}</small>
                  )}
                </Grid>
                <Grid item xs={12} md={6} className="grid-box">
                  <StyledTextField
                    label="Order"
                    name="order"
                    value={categoryOrder}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/; // regex to match only digits
                      if (e.target.value === "" || re.test(e.target.value)) {
                        handleOrderChange(e);
                      }
                    }}
                    disabled={isDisabled}
                  />
                  {errors && errors.order && (
                    <small
                      className={
                        errors.order === "Order available"
                          ? "success-message"
                          : "error-message"
                      }
                    >
                      {errors.order}
                    </small>
                  )}
                </Grid>
              </Grid>
            </Box>
          </CustomDialog>
        )}
      </>
    );
}

ManageCategory.defaultProps = {
    isDisabled: false
}

export default ManageCategory;