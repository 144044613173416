import React, { useEffect, useState } from 'react'
import CustomDialog from '../../../components/mui/dialog'
import { IUser } from '../../../interfaces/payment'
import { Box, TextField } from '@mui/material'
import { IPassengerProps, ILocationPassengerMap } from '../../../interfaces/users'
import { ILocation } from '../../../interfaces'
import { updateUserPassengers } from '../../../services/User'
import { enqueueSnackbar } from 'notistack'

const Passengers = ({ passengers, onClose, setState, userClicked, isOpen = false  }: IPassengerProps) => {
    const [passengerDetails, setPassengerDetails] = useState<ILocationPassengerMap>({})
    const [driverDistance, setDriverDistance] = useState('')
    const [driverTime, setDriverTime] = useState('')
    const [editedPassengers, setEditedPassengers] = useState<string[]>([]);

    // pre-populate values in input fields
    useEffect(() => {
        if(userClicked.locationPassengerMap && isOpen) {
            setPassengerDetails(userClicked.locationPassengerMap)

            if(Object.keys(passengers.favouriteLocation).length > 0 && userClicked.locationPassengerMap[(passengers.favouriteLocation as ILocation)._id]) {
                const firstPassengerId = Object.keys(userClicked.locationPassengerMap[(passengers.favouriteLocation as ILocation)._id])[0]
    
                setDriverDistance(userClicked.locationPassengerMap[(passengers.favouriteLocation as ILocation)._id][firstPassengerId].driverDistance)

                setDriverTime(userClicked.locationPassengerMap[(passengers.favouriteLocation as ILocation)._id][firstPassengerId].driverTime)
            }
        }

        // eslint-disable-next-line
    }, [userClicked, isOpen])

    // onChange handler for input fields
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, passenger: IUser, favouriteLocation: ILocation) => {
        setPassengerDetails(prev => ({
            ...prev,
            [favouriteLocation._id]: {
                ...prev[favouriteLocation._id],
                [passenger._id]: {
                    ...(prev[favouriteLocation._id] && prev[favouriteLocation._id][passenger._id] && prev[favouriteLocation._id][passenger._id]),
                    [e.target.name]: e.target.value
                }
            }
        }));

        if (!editedPassengers.includes(passenger._id)) {
            setEditedPassengers([...editedPassengers, passenger._id]);
        }
    }

    // on submit handler
    const saveData = async () => {
        // check if driver distance and time is set
        if(driverDistance === '' || driverTime === '') {
            enqueueSnackbar("Please set driver journey's distance and time", { variant: "error" })

            return
        }

        let passengerDetailsCopy = JSON.parse(JSON.stringify(passengerDetails));
        let passengersToEdit = editedPassengers;

        // store all the passengers for selected location
        let allPassengers = JSON.parse(JSON.stringify(passengerDetailsCopy[(passengers.favouriteLocation as ILocation)._id]));

        // for each passenger, check if any field is not set, if yes, remove the passenger
        Object.keys(allPassengers).forEach((passengerId) => {
            // extract the ACTIVE passengers' ids in a list
            let activePassengerIds = passengers.passengers.map(passenger => passenger._id)

            // remove the passenger from locationPassengerMap if the user is not in the passengers list ,i.e., the passenger's document is removed
            if(!activePassengerIds.includes(passengerId)) {
                delete allPassengers[passengerId]

                return
            }

            if(
                allPassengers[passengerId].driverPassengerTime === "" || 
                allPassengers[passengerId].driverPassengerDistance === "" || 
                allPassengers[passengerId].passengerDestinationTime === "" || 
                allPassengers[passengerId].passengerDestinationDistance === "" ||
                allPassengers[passengerId].driverPassengerTime === undefined || 
                allPassengers[passengerId].driverPassengerDistance === undefined || 
                allPassengers[passengerId].passengerDestinationTime === undefined || 
                allPassengers[passengerId].passengerDestinationDistance === undefined
            ) {
                // remove the passenger from the list
                delete allPassengers[passengerId]
                passengersToEdit = passengersToEdit.filter(passenger => passengerId !== passenger);

                return
            }

            // set driver time and distance in the passenger details
            allPassengers[passengerId].driverTime = driverTime
            allPassengers[passengerId].driverDistance = driverDistance
        })

        passengerDetailsCopy[(passengers.favouriteLocation as ILocation)._id] = allPassengers

        try {
            await updateUserPassengers({
                locationPassengerMap: passengerDetailsCopy,
                userId: userClicked._id,
                editedPassengers: passengersToEdit
            }) as {data: IUser}

            setEditedPassengers([]);

            enqueueSnackbar('User updated successfully', {
                variant: 'success'
            })
        } catch (error) {
            console.log('User cannot be updated: ', JSON.stringify(error))   

            enqueueSnackbar('User cannot be updated', {
                variant: 'error'
            })
        }

        setDriverTime('')
        setDriverDistance('')
        setPassengerDetails({})
        onClose()
    }

  return (
    <>
        <CustomDialog 
            isOpen={isOpen}
            title="Passengers"
            onClose={() => {
                setDriverTime('')
                setDriverDistance('')
                setPassengerDetails({})

                onClose()
            }}
            onConfirm={() => {
                saveData()
            }}
        >
            {
                passengers.passengers.length === 0 ? (<>No passengers</>) : (
                    <>
                    <div style={{fontWeight: "500"}}>
                        Location name: {" "}
                        {
                            (passengers.favouriteLocation as ILocation)?.locationName[0]?.value
                        }
                    </div>

                    <div style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                        <TextField 
                            value={driverDistance}
                            onChange={(e) => setDriverDistance(e.target.value)}
                            label="Driver journey distance" 
                            variant="outlined"
                            name="driverDistance"
                            className='w-48'
                            type="number"
                        />

                        <TextField 
                            value={driverTime}
                            onChange={(e) => setDriverTime(e.target.value)}
                            label="Driver journey time" 
                            variant="outlined" 
                            name="driverTime" 
                            className='w-48'
                            type="number"
                        />
                    </div>

                        {
                            passengers.passengers.map((passenger, index) => (
                                <div key={index} style={{marginBottom: "1rem"}}>
                                    <p style={{cursor: "pointer", display: "inline-block", color: "#29995F"}} onClick={() => setState(prev => ({ ...prev, showUserDetail: true, userId: passenger._id }))}>
                                    {
                                        passenger.firstName[0].value
                                    }
                                    </p>
            
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", gap: "1rem" }}>
                                        {/* <TextField 
                                            value={
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id] && 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id] &&
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id].driverDistance ? 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id]?.driverDistance : 
                                                ""
                                            }
                                            onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, passenger, passengers.favouriteLocation as ILocation)}
                                            label="Driver journey distance" 
                                            variant="outlined"
                                            name="driverDistance"
                                            className='w-48'
                                            type="number"
                                        />
                                        <TextField 
                                            value={
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id] && 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id] &&
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id].driverTime ? 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id]?.driverTime : 
                                                ""
                                            }
                                            onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, passenger, passengers.favouriteLocation as ILocation)}
                                            label="Driver journey time" 
                                            variant="outlined" 
                                            name="driverTime" 
                                            className='w-48'
                                            type="number"
                                        /> */}
            
                                        <TextField 
                                            value={
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id] && 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id] &&
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id].driverPassengerDistance ? 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id]?.driverPassengerDistance : 
                                                ""
                                            }
                                            onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, passenger, passengers.favouriteLocation as ILocation)}
                                            label="Driver to passenger distance" 
                                            variant="outlined" 
                                            name="driverPassengerDistance" 
                                            className='w-48'
                                            type="number"
                                        />

                                        <TextField 
                                            value={
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id] && 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id] &&
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id].driverPassengerTime ? 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id]?.driverPassengerTime : 
                                                ""
                                            }
                                            onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, passenger, passengers.favouriteLocation as ILocation)}
                                            label="Driver to passenger time" 
                                            variant="outlined" 
                                            name="driverPassengerTime" 
                                            className='w-48'
                                            type="number"
                                        />

                                        <TextField 
                                            value={
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id] && 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id] &&
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id].passengerDestinationDistance ? 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id]?.passengerDestinationDistance : 
                                                ""
                                            }
                                            onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, passenger, passengers.favouriteLocation as ILocation)}
                                            label="Passenger to destination distance" 
                                            variant="outlined" 
                                            name="passengerDestinationDistance" 
                                            className='w-48'
                                            type="number"
                                        />

                                        <TextField 
                                            value={
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id] && 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id] &&
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id].passengerDestinationTime ? 
                                                passengerDetails[(passengers.favouriteLocation as ILocation)._id][passenger._id]?.passengerDestinationTime : 
                                                ""
                                            }
                                            onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, passenger, passengers.favouriteLocation as ILocation)}
                                            label="Passenger to destination time" 
                                            variant="outlined" 
                                            name="passengerDestinationTime" 
                                            className='w-48'
                                            type="number"
                                        />
                                    </Box>
            
                                </div>
                            ))
                        }
                    </>
                )
            }
        </CustomDialog>
    </>
  )
}

export default Passengers