// import { IResponseDeclarations } from '../../interfaces/configuration/declarations/index';
import { httpRequest } from '../Network';
export const getDeclarations = () => {
    return new Promise<any>((resolve, reject) => {
        httpRequest("GET", "/configuration/declaration").then((response) => {
            resolve(response);
        }).catch((er) => {
            console.log('Error', er);
        })
    })
}
export const updateDeclarations = (payload: object) => {
    return new Promise((resolve, reject) => {
        httpRequest("PUT", "/configuration/declaration", payload).then((response) => {
            resolve(response);
        }).catch((er) => {
            console.log("Error", er);
        })
    })
}