import { httpRequest } from "../Network";
import { admin } from "../endpoints";

export const createAdmin = (data:object) => {
    return httpRequest("POST",admin,data)
    .then((res)=> res)
    .catch((err)=>{ 
        throw err
    })
}

export const getAllAdmin = (filter:object) => {
    return httpRequest("GET",admin,{},filter)
    .then((response:any)=>{
        return response
    })
    .catch((err)=>{
        throw err
    })
}

export const getAdmin = (id:string) => {
    return httpRequest("GET",`${admin}/${id}`)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}

export const updateAdmin = (data:object) => {
    return httpRequest("PUT",admin,data)
    .then((response:any)=>{
        return response
    })
    .catch((err)=>{
        throw err
    })
}


export const searchAdmin = (searchValue:string) => {
    return httpRequest("GET",`/auth/admin?status=ALL&searchValue=${searchValue}`)
    .then((response:any)=>{
        console.log(response.data)
        return response.data
    })
    .catch((err)=>{
        console.log(err)
    })
}

export const updateAdminStatus = (data:object) => {
    return httpRequest("PUT",`${admin}/status`, data)
    .then((response)=>  response)
    .catch((err)=> console.log(err))
}

export const deleteAdmin = (body:any) => {
    return httpRequest("DELETE","/auth/admin",body)
    .then((response:any)=>{
        return response.data
    })
    .catch((err)=>{
        console.log(err)
    })
}