import "./style.scss";
import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, Link } from "@mui/material";
import Logo from "../../../assets/images/logo.png";
//import { Auth } from "../../../services/Login";
//import { Visibility, VisibilityOff } from "@mui/icons-material";
//import { IProfileData } from "../../../interfaces/profile";
import { useSnackbar } from "notistack";
import { ForgetPassword } from "../../../services/Login";
import { useEffect } from "react";
import { StyledTextField } from "../../user/manage";
//import { styled } from '@mui/material/styles';

interface IErrorField {
  email: boolean;
  emailRequired: boolean;
  password: boolean;
}



// const CustomTextField = styled(TextField)({
//   '& .MuiInputBase-input:-webkit-autofill': {
//     '-webkit-box-shadow': '0 0 0 100px #fff inset',
//     '-webkit-text-fill-color': '#000 !important',
//     'caret-color': 'inherit',
//   },
//   '& .MuiInputBase-input:focus:-webkit-autofill': {
//     '-webkit-box-shadow': '0 0 0 100px #fff inset',
//     '-webkit-text-fill-color': '#000 !important',
//     'caret-color': 'inherit',
//   },
//   '& .MuiFormLabel-root.Mui-focused': {
//     color: '#000',
//   },
//   '& .MuiInput-underline:after': {
//     borderBottomColor: '#000',
//   },
// });

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email,setEmail] = useState('')
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<IErrorField>({
    email: false,
    emailRequired: false,
    password: false,
  });

  /*eslint-disable*/
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  /*eslint-enable*/
  const handleSubmit = (event: SyntheticEvent) => {
    if (!email) {
      return setError((prev) => {
        return {
          ...error,
          email: true,
        };
      });
    }
    else{
      setError((prev) => {
        return {
          ...error,
          email:false,
        };
      });

      ForgetPassword({email:email})
      .then(() =>{
        enqueueSnackbar("Check your email", {
          variant: "info",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        })
        navigate('/login')
      }
        
      )
      .catch((err) =>
        enqueueSnackbar("No admin found with this email", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        })
      );
    }
  

  };

  const handleChange = (event: any) => {
     setEmail(event.target.value)
  };

  return (
    <div className="container center">
      <div className="login-box">
          <Grid className="h-100" container>
            <Grid className="h-100" xs={12} md={6}>
              <img alt="Login" id="left-img" src={Logo} />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="credential-box center h-100">
                <div className="mb-5 text-center">
                  <Typography variant="h4" sx={{fontSize:'1.25rem'}}>Welcome to</Typography>
                  <Typography variant="h5" color="primary" sx={{fontSize:'2rem',fontWeight:"800"}}>
                    EcoPooling
                  </Typography>
                </div>
                <div className="email-box grid-box">
                  <StyledTextField
                    label="Email"
                    className="mb-3"
                    name="email"
                    type="email"
                    value={email}
                    onChange={handleChange}
                  />
                  {error.email && (
                    <p className="auth-error-message">Email is required</p>
                  )}
                </div>
                <div className="mb-3 forgot-password" style={{width:'100%'}}>
                <Link
                  color="blue"
                  href="/login"
                  underline="none"
                  sx={{width:"100%",marginTop:'2px'}}
                >
                 Back to login
                </Link>
                </div>
                <Button type="submit" className="mb-3" size="medium" fullWidth onClick={handleSubmit}>
                  Send
                </Button>
              </div>
            </Grid>
          </Grid>
      </div>
    </div>
  );
};

export default ForgotPassword;