import { httpRequest } from "../Network";

interface IProps{
    email:string,
    password:string
}

export const Auth = (data:IProps) => {
   return httpRequest('POST','/auth/login',data).then((response:any)=>{
        return response.data
    })
    .catch((err)=>{
       throw err
    })
}


export const Logout = () => {
    return httpRequest('POST','/auth/logout')
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}


export const ForgetPassword = (payload:object) => {
    return httpRequest('POST','/auth/forgot-password/admin',payload)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}

export const resetPassword = (payload:object) => {
    return httpRequest('POST','/auth/reset-password/admin',payload)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}

export const changeAdminPassword = (payload:object) => {
      return httpRequest ('PUT','/auth/change-password/admin',payload)
      .then((res)=> res)
      .catch((err)=> {
        throw err
      })
}