import { ChangeEvent, useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
  SelectChangeEvent
} from "@mui/material";
import Header from "../../components/header";
import CustomTable from "../../components/mui/table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Home from "@mui/icons-material/Home";
import TimeToLeave from "@mui/icons-material/TimeToLeave";
import Manage from "./manage";
import WarningDialog from "../../components/mui/warning-dialog";
import { deleteUser, getAllPassengers, getAllUsers, getGroupUsersByUserId, getMarketplaceUsers, getSingleUser, updateUserById, updateUserStatus } from "../../services/User";
import { useLanguage } from "../../components/language-provider";
import VehicleDetails from "./vehicleDetails";
import FavouriteLocations from "./favouriteLocation";
import Avatar from "@mui/material/Avatar";
import { useSnackbar } from "notistack";
import { capitalizeFirstLetter, openImage, findNameByLanguage } from "../../utils";
import profileIcon from '../../assets/images/logo.png';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Loader from "../../components/mui/loader/Loader";
import UserDetail from "./userDetails";
import { IUserState } from "../../interfaces/users";
import { userColumns as Columns } from "../../utils";
import Passengers from "./passengers";
import { IUser } from "../../interfaces/payment";
import { IFavouriteLocation } from "../../interfaces";
import { Group } from "@mui/icons-material";
import GroupsIcon from '@mui/icons-material/Groups';
import GroupMembers from "./groupMembers";
import MarketPlaceFriendsList from "./marketplaceUsers";

const User = () => {
  const { language } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<IUserState>({
    manage: {
      type: "new",
      isOpen: false,
      data: {},
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    _user: "",
    totalPage: 1,
    list: [],
    selectAll: [],
    status: "ALL",
    preference: "ALL",
    loading: true,
    page: 1,
    vehicleData: {
      _id: "",
      reg_no: "",
      seatAvailable: "",
      childSeat: "",
    },
    vehicleDetails: false,
    favouriteLocation: false,
    userId: "",
    favouriteLocationId: "",
    vehicleDetailId: "",
    manageId: "",
    manageDialog: false,
    showUserDetail: false,
    showGroupUsers: false,
    showMarketplaceUsers: false,
    groupUsers: [],
    marketplaceUsers: []
  });
  const [passengers, setPassengers] = useState<{passengers: IUser[], favouriteLocation: IFavouriteLocation | {}}>({ passengers: [], favouriteLocation: {}});
  const [showPassengers, setShowPassengers] = useState(false);
  const [userClicked, setUserClicked] = useState<IUser>({} as IUser);

  const fetchData = (searchValue?: string) => {
    getAllUsers({
      roleType: state.preference,
      status: state.status,
      page: searchValue ? 1 : state.page,
      searchValue,
    })
      .then((response) => {
        const data = response.data;

        setState({
          ...state,
          list: [...data],
          totalPage: response.meta.totalPage,
          deleteWarning: false,
          multiDeleteWarning: false,
          loading: false,
          selectAll: [],
          manage: {
            ...state.manage,
            isOpen: false
          }
        });
      })
      .catch((err) => {
        setState((prev) => {
          return {
            ...prev,
            loading: false
          }
        })
        enqueueSnackbar("Couldn't get data", {
          variant: "error"
        });
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [
    state.status,
    state.preference,
    state.page,
  ]);

  /* eslint-enable */
  const handleSearch = (event: ChangeEvent<unknown> | any) => {
    const searchValue = event.target.value;
    if (event.key === "Enter") {
      fetchData(searchValue);
    }
  };

  const onPageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    setState({ ...state, page: newPage });
  };

  const handleManage = (type: "new" | "edit" | "view") => {
    setState((prevState) => {
      return {
        ...prevState,
        manage: {
          ...prevState.manage,
          isOpen: !prevState.manage.isOpen,
          type,
        },
      };
    });
  };

  const handleClose = () => {
    handleManage(state.manage.type)
    setState((prev) => {
      return {
        ...prev,
        manageId: ""
      }
    })
    fetchData()
  }

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      if (state.list?.length) {
        payload = state.list.map((ele: any) => ele._id.toString());
      }
    } else {
      payload = [];
    }

    const updatedList = state.list.map((items: any) => (
      {
        ...items, all: (
          <Checkbox
            sx={{ color: "#bdbdbd" }}
            onChange={(e) => handleMultipleSelect(e, state.selectAll, (items._id).toString())}
            checked={payload.includes(items._id.toString()) ? true : false}
          />
        )
      }
    ))

    setState((prevState) => {
      return {
        ...prevState,
        selectAll: payload,
        list: updatedList
      };
    });
  };

  const handleMultipleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    selectAll: string[],
    id: string
  ) => {
    let payload: Array<string> = [];

    if (e.target.checked) {
      payload = selectAll;
      payload.push(id);
    } else {
      payload = selectAll.filter((ele) => ele !== id);
    }

    setState(prevState => ({
      ...prevState,
      selectAll: [...payload]
    }));
  };

  const handleDelete = (_user: string = "") => {
    setState((prevState) => {
      return {
        ...prevState,
        deleteWarning: !prevState.deleteWarning,
        _user,
      };
    });
  };

  const handleMultiDelete = () => {
    setState((prevState) => {
      return {
        ...prevState,
        multiDeleteWarning: !prevState.multiDeleteWarning,
      };
    });
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setState((prevState) => {
      return {
        ...prevState,
        status: event.target.value,
        page: 1
      };
    });
  };

  const updateStatus = (userId: string, event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newStatus = isChecked ? "ACTIVE" : "INACTIVE";
    const body = {
      _id: userId,
      status: newStatus,
    };
    updateUserStatus(body).then(() => {
      fetchData();
    });
  };

  const handlePreferenceChange = (event: SelectChangeEvent<string>) => {
    setState((prevState) => {
      return {
        ...prevState,
        preference: event.target.value,
      };
    });
  };

  const handleDetailDialogClose = () => {
    setState((prev) => {
      return {
        ...prev,
        showUserDetail: !state.showUserDetail,
        userId: ""
      }
    })
  }
  const onDelete = () => {
    const data = {
      ids: [state._user],
    };
    deleteUser(data)
      .then(() => {
        enqueueSnackbar("Deleted successfully", {
          variant: "success"
        });
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteMultiple = () => {
    const data = {
      ids: state.selectAll,
    };
    deleteUser(data)
      .then(() => {
        enqueueSnackbar("Data deleted", {
          variant: "success"
        });
        fetchData();
      })
      .catch((err) => {
        enqueueSnackbar("Couldn't delete data", {
          variant: "error"
        });
      });
    console.log(data)
    fetchData()
  };

  const columns = [
    {
      id: "all",
      label: (
        <Checkbox
          sx={{ color: "#bdbdbd" }}
          onChange={handleSelectAll}
          checked={
            (state.selectAll.length) &&
              (state.list.length === state.selectAll.length)
              ? true
              : false
          }
        />
      ),
    },
    ...Columns
  ];

  const createRow = (
    selectAll: string[],
    user: any,
    index: number,
    onEdit: (type: ('new' | 'edit' | 'view')) => void,
    onDelete: (id: string) => void,
    onMultiDelete: (...args: any[]) => void
  ) => {
    const all = (
      <Checkbox
        sx={{ color: "#bdbdbd" }}
        onChange={(e) => onMultiDelete(e, selectAll, (user._id).toString())}
        checked={selectAll.includes(user._id.toString()) ? true : false}
      />
    );

    const fallbackImg = user.photo ? user.photo : profileIcon;

    const image = (
      <Avatar
        sx={{ cursor: "pointer", margin: '0px auto' }}
        alt="image"
        src={fallbackImg}
        onClick={() => openImage(user.photo)}
      />
    );


    const name = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={() => {
          setState((prevState) => ({
            ...prevState,
            userId: user._id,
            showUserDetail: true
          }));
        }}
      >
        {`${capitalizeFirstLetter(
          findNameByLanguage(language, user.firstName)
        )} 
        ${capitalizeFirstLetter(findNameByLanguage(language, user.lastName))}`}
      </Typography>
    );

    const status = (
      <Switch
        checked={user.status === "ACTIVE" ? true : false}
        onChange={(e) => updateStatus(user._id, e)}
        disabled={user.isBetaTest === true}
      />
    );

    const driverVerified = (
      <Switch
        checked={user.isDriverVerified}
        onChange={async (e) => {
          try {
            setState(prev => ({
              ...prev,
              loading: true
            }))

            // update the driver verification status
            await updateUserById({ _id: user._id, isDriverVerified: e.target.checked })

            // update the data on the UI
            fetchData();
          } catch (error) {
            enqueueSnackbar("Unable to update driver verification status", { variant: "error" });

            setState(prev => ({
              ...prev,
              loading: false
            }))
          }

        }}
        disabled={(user.userPreference === "FINDER" || user.userPreference === "MARKET_PLACE" || user.isBetaTest === true) ? true : false}
      />
    );
    
    const action = (
      <>
        <Tooltip title="Rewards marketplace users">
          <IconButton
            color="primary"
            onClick={async () => {
              setState(prev => ({
                ...prev,
                showMarketplaceUsers: true,
              }))
              fetchMarketplaceUsers(user._id);
            }}
            disabled={user.userPreference === "MARKET_PLACE" || user.isBetaTest === true}
          >
            <GroupsIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Group members">
          <IconButton
            color="primary"
            onClick={async () => {
              setState(prev => ({
                ...prev,
                showGroupUsers: true,
              }))
              fetchGroupUsers(user._id);
            }}
            disabled={user.userPreference === "MARKET_PLACE" || user.isBetaTest === true}
          >
            <Group />
          </IconButton>
        </Tooltip>


          <Tooltip title="Find passengers">
            <IconButton
              color="primary"
              onClick={async () => {
                setState(prev => ({
                  ...prev,
                  loading: true
                }))

                try {
                  const userDetails = await getSingleUser(user._id)
                  
                  setUserClicked(userDetails)
                } catch (error) {
                  console.log('unable to fetch user details')
                }

                fetchPassengers(user._id);
              }}
              disabled={user.userPreference === "MARKET_PLACE" || user.isBetaTest === true}
            >
              <PersonSearchIcon />
            </IconButton>
          </Tooltip>

        <Tooltip title="Favourite Locations">
          <IconButton
            onClick={() => {
              setState((prev) => {
                return {
                  ...prev,
                  favouriteLocationId: user._id,
                  favouriteLocation: !state.favouriteLocation,
                };
              });
            }}
            color="primary"
            disabled={user.userPreference === "MARKET_PLACE" || user.isBetaTest === true}
          >
            <Home />
          </IconButton>
        </Tooltip>

        <Tooltip title="Vehicle details">
          <IconButton
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                vehicleDetails: !prevState.vehicleDetails,
                vehicleDetailId: user._id,
              }));
            }}
            color="primary"
            disabled={
              (user.userPreference === "BOTH" || user.userPreference === "OFFER") && (!user.isBetaTest)
                ? false
                : true
            }
          >
            <TimeToLeave />
          </IconButton>
        </Tooltip>

        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              onEdit("edit");
              setState((prevState) => ({
                ...prevState,
                manageId: user._id,
                manageDialog: true,
              }));
            }}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton onClick={() => onDelete(user._id)} color="error" disabled={user.isBetaTest === true}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      all,
      id: (state.page - 1) * 10 + index + 1,
      image,
      _id: user._id,
      name,
      email: <p style={{ wordWrap: 'break-word' }}>{user.email}</p>,
      phone: `${user.countryCode ? user.countryCode + " " + user.phone : user.phone
        }`,
      preference: capitalizeFirstLetter(user.userPreference),
      status,
      driverVerified,
      action,
    };
  };

  const fetchPassengers = async (userId: string) => {
    try {
      // get all the passengers where favourite location matches with the current user
      const passengers = await getAllPassengers(userId);

      if(passengers.data) {
        // after users are fetched, then show the list of users
        setShowPassengers(true);
    
        setPassengers({passengers: passengers.data, favouriteLocation: passengers.meta.favouriteLocation});
      }
  
    } catch (error) {
      console.log('passengers cannot be fetched: ', JSON.stringify(error))

      enqueueSnackbar("Passengers cannot be fetched", { variant: "error" })
    }

    setState(prev => ({
      ...prev,
      loading: false
    }))
  }

  const fetchGroupUsers = async (userId: string) => {
    try {
      // get all members of selected user
      const groupUsers = (await getGroupUsersByUserId(userId)).data;

      if (Boolean(groupUsers) !== false) {
        setState(prev => {
          return {
            ...prev,
            groupUsers: [...groupUsers]
          }
        });
      }
    } catch (error) {
      console.log('Members cannot be fetched: ', JSON.stringify(error))

      enqueueSnackbar("Members cannot be fetched", { variant: "error" })
    }
  }

  const fetchMarketplaceUsers = async (userId: string) => {
    try {
      // get all marketplace group members of selected user
      const marketplaceUsers = (await getMarketplaceUsers(userId)).data;

      if (Boolean(marketplaceUsers) !== false) {
        setState(prev => {
          return {
            ...prev,
            marketplaceUsers: [...marketplaceUsers]
          }
        });
      }
    } catch (error) {
      console.log('Members cannot be fetched: ', JSON.stringify(error))

      enqueueSnackbar("Members cannot be fetched", { variant: "error" })
    }
  }

  return (
    <>
      {state.loading && <Loader />}
      {/* Add Data  */}
      <Header
        searchPlaceholder="Search by user name or email"
        onSearch={handleSearch}
        onBtnClick={() => handleManage("new")}
        onDelete={handleMultiDelete}
        isDeleteDisable={state.selectAll.length ? false : true}
        searchInput
      >
        <FormControl className="ml-2" size="small">
          <Select
            size="small"
            defaultValue="ALL"
            value={state.status}
            onChange={handleStatusChange}
          >
            <MenuItem value="ALL">All Status</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="ml-2" size="small">
          <Select
            size="small"
            defaultValue="ALL"
            value={state.preference}
            onChange={handlePreferenceChange}
          >
            <MenuItem value="ALL">All Preference</MenuItem>
            <MenuItem value="OFFER">Offer</MenuItem>
            <MenuItem value="FINDER">Finder</MenuItem>
            <MenuItem value="BOTH">Both</MenuItem>
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list.map((user: object, index: number) =>
            createRow(
              state.selectAll,
              user,
              index,
              handleManage,
              handleDelete,
              handleMultipleSelect
            )
          )}
          height="calc(100vh - 193px)"
          errorMessage="No user to show here"
          pagination={{
            page: state.page,
            totalPages: state.totalPage,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        userId={state.manageId}
        onClose={() => {
          handleClose()
        }}
      />

      <UserDetail
        isOpen={state.showUserDetail}
        userId={state.userId}
        onClose={handleDetailDialogClose}
        isDisabled={true}
      />

      <VehicleDetails
        id={state.vehicleDetailId}
        isOpen={state.vehicleDetails}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            vehicleDetails: !state.vehicleDetails,
          }));
        }}
        isDisabled={true}
      />

      <FavouriteLocations
        isOpen={state.favouriteLocation}
        userId={state.favouriteLocationId}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            favouriteLocation: !state.favouriteLocation,
          }));
        }}
        isDisabled={true}
      />

        {/* show passengers in a modal */}
        <Passengers 
          isOpen={showPassengers}
          onClose={() => setShowPassengers(false)}
          passengers={passengers}
          setState={setState}
          userClicked={userClicked}
        />

      {/* show members of group of respective user */}
      <GroupMembers
        isOpen={state.showGroupUsers}
        onClose={() => {
          setState(prev => ({
            ...prev,
            showGroupUsers: false,
            groupUsers: []
          }))
        }}
        members={state.groupUsers}
      />

      {/* show marketplace users of respective carpool user */}
      <MarketPlaceFriendsList
        isOpen={state.showMarketplaceUsers}
        onClose={() => {
          setState(prev => ({
            ...prev,
            showMarketplaceUsers: false,
            groupUsers: []
          }))
        }}
        users={state.marketplaceUsers}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete User"
        description="Are you sure do you want to delete this user?"
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={deleteMultiple}
        title="Delete Users"
        description="Are you sure do you want to delete all selected users?"
      />
    </>
  );
}
export default User;
