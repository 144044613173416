import React, { FC } from 'react';
import './style.scss';
interface IErrorProps {
   innerText : any;
   className?:string;
   variant:string
}
const ErrorMessage:FC<IErrorProps> = ({innerText, className ,variant}) => {
    return (
        <>
        <p className={variant === 'error'?`error-text ${className}`:`success-text ${className}`}>{innerText}</p>
        </>
    )
}
export default ErrorMessage;