import { httpRequest } from "../Network"
import { ecoSavings } from "../endpoints"

// purchase carbon credits
export const purchaseCarbonAvoidanceCredits = (body:object) => {
    return httpRequest("POST",`${ecoSavings}/admin/carbon-credits`,body)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}