import {
  Chip,
  FormControl,
  InputBase as Input,
} from "@mui/material";
import {styled} from "@mui/material/styles"
import { Fragment} from "react";
import './index.scss'

interface IProps{
  inputFor : 'emails' | 'domains' | 'denominations',
  values : string[],
  setValues ?: React.Dispatch<React.SetStateAction<string[]>>,
  currValue : string,
  setCurrValue ?: React.Dispatch<React.SetStateAction<string>>,
  handleChange : (e:any) => void,
  handleKeyUp : (e: React.KeyboardEvent<HTMLInputElement>) => void,
  handleDelete : (item: string, index: number) => void
}
const FormWrapper = styled(FormControl)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  flexWrap: "wrap",
  flexDirection: "row",
  border: '1px solid #919eab33',
  padding: '8px',
  borderRadius: '8px',
  "& > div.input-container": {
    gap: "6px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  "& > div.input-container > span": {
    backgroundColor: "gray",
    padding: "1px 3px",
    borderRadius: "4px"
  }
});

const InputBase = styled(Input)({
  "& input": {
    "&::placeholder": {
      color: "#828282"
    },
  },
});

const MultipleInput = ({inputFor,values,setValues,currValue,setCurrValue,handleChange,handleKeyUp,handleDelete}:IProps): JSX.Element => {
 

  return (
    <Fragment>
      <FormWrapper className='grid-box'>
        <div className="input-container">
          {values.map((item, index) => (
            <Chip key={index} size="small" onDelete={() => handleDelete(item, index)} label={item} />
          ))}
        </div>
        <InputBase
          value={currValue}
          placeholder={`Enter your ${inputFor}`}
          onChange={handleChange}
          onKeyDown={handleKeyUp}
          sx={{width: '97%'}}
        />
      </FormWrapper>
      {/* {(error.invalidEmail) && <p className="error-message-input-base">{values.length ? `Invalid ${inputFor}  ` : ""}</p>} */}
    </Fragment>
  );
}

MultipleInput.defaultProps = {
  inputFor : 'emails'
}

export default MultipleInput;