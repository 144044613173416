import axios from 'axios';
axios.defaults.responseType = 'json';
// axios.defaults.baseURL = "https://ecopooling-api.relinns.co.in";
//axios.defaults.baseURL = "http://192.168.1.35:80";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
function httpRequest<T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTION',
    url: string,
    data = {},
    params = {}
) {
    return new Promise<T>((resolve, reject) => {
        axios({
            method,
            url,
            data,
            params,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token') || ""}`,
                "Content-Type": "application/json"
            }
        })
            .then((response: any) => {
                if (String(response.status).match(/20/gi)) {
                    resolve(response.data);
                } else {


                    reject("Something went wrong");
                }
            })
            .catch((err: any) => {
                // if (err.status === false && err.statusCode)
                console.log('inside catch', err.response);
                reject(err);
            })
    });
}

function httpFormRequest<T>(
    url: string,
    file: any = {}
) {
    return new Promise<T>((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('originalname', file.name);
        const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token') || ""}`,
                "Content-Type": "multipart/form-data",
                "x-locale": `${localStorage.getItem('locale') || 'en'}`

            },
        };

        axios.post(url, formData, config)
            .then((response) => {
                if (String(response.status).match(/20/gi)) {
                    resolve(response.data);
                } else {
                    reject("Something went wrong");
                }
            })
            .catch((err) => {
                // localStorage.removeItem('token');
                console.log('insdie catch', err);
                reject(err);
            });
    });
}

export { httpRequest, axios, httpFormRequest };
