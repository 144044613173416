import { FC, ChangeEvent, useEffect, useState } from "react";
import CustomDialog from "../../../../components/mui/dialog";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  CircularProgress,
} from "@mui/material";
import {
  addGift,
  editGift,
  getGiftProviderById,
} from "../../../../services/configurations/giftProvider";
import { useSnackbar } from "notistack";
import { upload } from "../../../../services/common";
import { useLanguage } from "../../../../components/language-provider";
import profileIcon from "../../../../assets/images/logo.png";
import { findNameByLanguage } from "../../../../utils";
import { StyledTextField, StyledSelect } from "../../../user/manage";
import { getAllGiftCategories } from "../../../../services/Gift Category";
import "./style.scss";
import { giftProviderSchema } from "../../../../utils";
import * as yup from "yup";
import MultipleInput from "../../../../components/Multiple Input";
import EditorComponent from "../../../../components/free-text-editor";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

interface props {
  isOpen: boolean;
  type: string;
  isDisabled: boolean;
  onClose: () => void;
  id: string;
  fetchData: () => void;
}

interface IError {
  giftProvider?: string;
  image?: string;
  giftCategory?: string;
  discount?: string;
  terms_and_condition_url?: string,
  availability?: string,
  min?: string,
  max?: string,
  unit?: string,
  deliveryType?: string,
  tilloDiscount?: string,
  tilloIdentifier?: string,
  notes?: string,
  redemptionInstructionsUrl?: string
}

const Manage: FC<props> = ({
  isOpen,
  type,
  isDisabled,
  onClose,
  fetchData,
  id,
}) => {
  const { language } = useLanguage();
  isDisabled = type === "view" ? true : false;

  const { enqueueSnackbar } = useSnackbar();

  const [giftProviderState, setGiftProviderState] = useState({
    giftProvider: "",
    image: "",
    giftCategory: "",
    discount: 0,
    allCategories: [],
    terms_and_condition_url: "",
    availability: "",
    min: 0,
    max: 0,
    unit: 0,
    denominations: [] as string[],
    deliveryType: "",
    tilloDiscount: 0,
    tilloIdentifier: "",
    notes: "",
    redemptionInstructionsUrl: ""
  });

  const [imageUpload, setImageUpload] = useState(false);
  const [currentDenomination, setCurrentDenomination] = useState("")
  const [error, setError] = useState<IError>({
    giftProvider: "",
    image: "",
    giftCategory: "",
    discount: "",
    terms_and_condition_url: "",
    availability: "",
    deliveryType: "",
    tilloDiscount: "",
    tilloIdentifier: "",
    notes: "",
    unit: "",
    redemptionInstructionsUrl: ""
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());


  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (error) {
      setError((prev) => {
        return {
          ...prev,
          [name]: false,
        };
      });
    }

    setGiftProviderState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  //eslint-disable
  useEffect(() => {
    if (type === "edit" && isOpen) {
      getGiftProviderById(id)
        .then((res: any) => {
          setGiftProviderState((prev: any) => {
            return {
              ...prev,
              giftProvider: findNameByLanguage(language, res.data.name),
              image: res.data.image,
              giftCategory: res.data._category,
              discount: res.data.discount,
              terms_and_condition_url: res.data.termsURL,
              availability: res.data.availability,
              min: res.data.min || 0,
              max: res.data.max || 0,
              unit: res.data.unit ?? 0,
              denominations: res.data.denominations || [],
              deliveryType: res.data.deliveryType || "",
              tilloDiscount: res.data?.tilloDiscount || 0,
              tilloIdentifier: res.data?.tilloIdentifier || "",
              notes: res.data?.notes || "",
              redemptionInstructionsUrl: res.data?.redemptionInstructionsUrl || ""
            };
          });

          // set editor state to saved html
          const blocksFromHTML = convertFromHTML(res.data?.notes || "");
          const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
          const newEditorState = EditorState.createWithContent(contentState);
          setEditorState(newEditorState);
        })
        .catch(() =>
          enqueueSnackbar("Unexpected error occured", { variant: "error" })
        );
    }

    if (type === "new") {
      setGiftProviderState((prev) => {
        return {
          ...prev,
          giftProvider: "",
          image: "",
          giftCategory: "",
          discount: 0,
          availability:"",
          terms_and_condition_url:"",
          denominations: [],
          deliveryType: "",
          tilloDiscount: 0,
          tilloIdentifier: "",
          notes: "",
          redemptionInstructionsUrl: "",
          unit: 0
        };
      });
    }
    // eslint-disable-next-line
  }, [id, type, isOpen]);

  //eslint-enable
  const uploadImage = (e: ChangeEvent<HTMLInputElement> | any) => {
    setImageUpload(true);
    const files = e.target.files;
    const maxSize = 3 * 1024 * 1024;
    const fileSize = files[0].size / 1024 / 1024;
    if (files?.length && fileSize < maxSize)
      upload(files[0])
        .then((response: any) => {
          console.log(response);
          setGiftProviderState({
            ...giftProviderState,
            image: response,
          });
          setImageUpload(false);
        })
        .catch((err) => console.log(err));
  };

  const handleConfirm = () => {
    let payload = {
      name: [
        {
          lang: language,
          value: giftProviderState.giftProvider,
        },
      ],
      image: giftProviderState.image,
      _category: giftProviderState.giftCategory,
      discount: +giftProviderState.discount,
      availability: giftProviderState.availability,
      termsURL: giftProviderState.terms_and_condition_url,
      min: +giftProviderState.min,
      max: +giftProviderState.max,
      unit: +giftProviderState.unit,
      denominations: giftProviderState.denominations,
      deliveryType: giftProviderState.deliveryType,
      tilloDiscount: +giftProviderState.tilloDiscount,
      tilloIdentifier: giftProviderState.tilloIdentifier,
      notes: giftProviderState.notes,
      redemptionInstructionsUrl: giftProviderState.redemptionInstructionsUrl
    };

    console.log(giftProviderState)
    console.log(payload)

    if (type === "new") {
      giftProviderSchema
        .validate(giftProviderState, { abortEarly: false })
        .then(() => {
          addGift(payload)
            .then(() => {
              enqueueSnackbar("Data added successfully", {
                variant: "success",
              });

              onClose();

              fetchData();
            })
            .catch(() =>
              enqueueSnackbar("Couldn't add data", { variant: "error" })
            );
        })
        .catch((error) => {
          console.log(error)

          const errors: { [key: string]: string } = {};

          error.inner.forEach((fieldError: yup.ValidationError) => {
            errors[fieldError.path!] = fieldError.message;
          });

          console.log(errors);
          
          setError(errors);
        });
    }

    if (type === "edit") {
      giftProviderSchema
        .validate(giftProviderState, { abortEarly: false })
        .then(() => {
          const newPayload = {
            ...payload,
            id: id,
          };

          editGift(newPayload)
            .then(() => {
              enqueueSnackbar("Data updated successfully", {
                variant: "success",
              });

              onClose();

              fetchData();
            })
            .catch(() =>
              enqueueSnackbar("Couldn't update data", { variant: "error" })
            );
        })
        .catch((error) => {
          const errors: { [key: string]: string } = {};

          error.inner.forEach((fieldError: yup.ValidationError) => {
            errors[fieldError.path!] = fieldError.message;
          });

          console.log(errors);

          setError(errors);
        });
    }
  };

  //eslint-disable
  useEffect(() => {
    if (isOpen) {
      getAllGiftCategories()
        .then((res: any) => {
          const options = res.data.map((categories: any, key: number) => ({
            id: categories._id,
            name: findNameByLanguage(language, categories.giftCategoryName),
          }));
          setGiftProviderState((prev) => {
            return {
              ...prev,
              allCategories: options,
            };
          });
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [isOpen]);

  // handle keyUp event
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      if (currentDenomination !== "") {
        setGiftProviderState((oldState) => ({...oldState, denominations: [...oldState.denominations, currentDenomination]}));
          
        setCurrentDenomination("");
        
      }
    }
  };

  const handleDelete = (item: string, index: number) => {
    const denominationsArray = [...giftProviderState.denominations];

    denominationsArray.splice(index, 1);

    setGiftProviderState((oldState) => ({...oldState, denominations: denominationsArray}));
  };

  //eslint-enable
  return (
    <CustomDialog
      title={type === "edit" ? "Edit details" : "Add gift provider"}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setError({});
        setGiftProviderState((prev) => {
          return {
            ...prev,
            giftProvider: "",
            image: "",
            giftCategory: "",
            discount: 0,
            availability:"",
            terms_and_condition_url:"",
            tilloIdentifier:"",
          };
        });
      }}
      onConfirm={handleConfirm}
      disabled={isDisabled}
    >
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className="grid-box">
            <Box className="center image-container my-3">
              {!imageUpload ? (
                <Box
                  height="100px"
                  width="100px"
                  className="img-circle"
                  aria-label="upload picture"
                  component="label"
                >
                  {imageUpload && <CircularProgress />}
                  <img
                    alt="Gift Provider"
                    src={giftProviderState.image || profileIcon}
                    className="image-logo"
                  />
                  <div className="edit-img ">
                    {type === "edit" ? "Edit" : "Add"}
                  </div>
                  <input
                    hidden
                    accept=".jpg, .jpeg, .png"
                    type="file"
                    onChange={uploadImage}
                  />
                </Box>
              ) : (
                <div className="progress-content">
                  <CircularProgress className="progress" />
                </div>
              )}
              {error && error.image && (
                <small className="image-error-message">{error.image}</small>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6} className="grid-box">
            <StyledTextField
              label="Tillo Identifier"
              name="tilloIdentifier"
              value={giftProviderState.tilloIdentifier}
              onChange={handleChange}
              disabled={isDisabled}
            />
            {error && error.tilloIdentifier && (
              <small className="error-message">{error.tilloIdentifier}</small>
            )}
          </Grid>

          <Grid item xs={12} md={6} className="grid-box">
            <StyledTextField
              label="Gift Provider Name"
              name="giftProvider"
              value={giftProviderState.giftProvider}
              onChange={handleChange}
              disabled={isDisabled}
            />
            {error && error.giftProvider && (
              <small className="error-message">{error.giftProvider}</small>
            )}
          </Grid>

          <Grid item xs={12} md={6} className="grid-box">
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">
                Gift Category
              </InputLabel>
              <StyledSelect
                size="small"
                label="Gift Category"
                name="giftCategory"
                disabled={isDisabled}
                value={giftProviderState.giftCategory}
                onChange={handleChange}
              >
                <MenuItem disabled>Select</MenuItem>
                {giftProviderState.allCategories.map(
                  (category: any, index: number) => (
                    <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                  )
                )}
              </StyledSelect>
            </FormControl>
            {error && error.giftCategory && (
              <small className="error-message">{error.giftCategory}</small>
            )}
          </Grid>

          <Grid item xs={12} md={6} className="grid-box">
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">
                Availability
              </InputLabel>
              <StyledSelect
                size="small"
                label="Availability"
                name="availability"
                disabled={isDisabled}
                value={giftProviderState.availability}
                onChange={handleChange}
              >
                <MenuItem disabled>Select</MenuItem>
                <MenuItem value="ONLINE">Online</MenuItem>
                <MenuItem value="STORE">In store</MenuItem>
                <MenuItem value="BOTH">Both</MenuItem>
              </StyledSelect>
            </FormControl>
            {error && error.availability && (
              <small className="error-message">{error.availability}</small>
            )}
          </Grid>

          <Grid item xs={12} md={6} className="grid-box">
            <StyledTextField
              label="Terms and condition url"
              name="terms_and_condition_url"
              type="text"
              value={giftProviderState.terms_and_condition_url}
              onChange={handleChange}
              disabled={isDisabled}
            />
            {error && error.terms_and_condition_url && (
              <small className="error-message">{error.terms_and_condition_url}</small>
            )}
          </Grid>

          <Grid item xs={12} md={6} className="grid-box">
            <StyledTextField
              label="Discount %"
              name="discount"
              type="number"
              value={giftProviderState.discount}
              onChange={(e) => {
                const re = /^[0-9]+\.?[0-9]*$/; // regex to match only digits and decimal numbers
                if (e.target.value === "" || re.test(e.target.value)) {
                  handleChange(e);
                }
              }}
              disabled={isDisabled}
            />
            {error && error.discount && (
              <small className="error-message">{error.discount}</small>
            )}
          </Grid>

          <Grid item xs={12} md={6} className="grid-box">
            <StyledTextField
              label="Tillo Discount %"
              name="tilloDiscount"
              type="number"
              value={giftProviderState.tilloDiscount}
              onChange={(e) => {
                const re = /^[0-9]+\.?[0-9]*$/; // regex to match only digits and decimal numbers
                if (e.target.value === "" || re.test(e.target.value)) {
                  handleChange(e);
                }
              }}
              disabled={isDisabled}
            />
            {error && error.tilloDiscount && (
              <small className="error-message">{error.tilloDiscount}</small>
            )}
          </Grid>

          <Grid item xs={12} md={3} className="grid-box">
            <StyledTextField
              label="Min"
              name="min"
              type="number"
              value={giftProviderState.min}
              onChange={(e) => {
                const re = /^[0-9]+\.?[0-9]*$/; // regex to match only digits and decimal numbers
                if (e.target.value === "" || re.test(e.target.value)) {
                  handleChange(e);
                }
              }}
              disabled={isDisabled}
            />
            {error && error.min && (
              <small className="error-message">{error.min}</small>
            )}
          </Grid>

          <Grid item xs={12} md={3} className="grid-box">
            <StyledTextField
              label="Max"
              name="max"
              type="number"
              value={giftProviderState.max}
              onChange={(e) => {
                const re = /^[0-9]+\.?[0-9]*$/; // regex to match only digits and decimal numbers
                if (e.target.value === "" || re.test(e.target.value)) {
                  handleChange(e);
                }
              }}
              disabled={isDisabled}
            />
            {error && error.max && (
              <small className="error-message">{error.max}</small>
            )}
          </Grid>

          <Grid item xs={12} md={6} className="grid-box">
            <MultipleInput
              inputFor="denominations"
              values={giftProviderState.denominations}
              currValue={currentDenomination}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const re = /^[0-9]+\.?[0-9]*$/; // regex to match only digits and decimal numbers

                if(re.test(e.target.value)) {
                  setCurrentDenomination(e.target.value)
                }
              }}
              handleKeyUp={handleKeyUp}
              handleDelete={handleDelete}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-box">
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">
                Delivery Type
              </InputLabel>
              <StyledSelect
                size="small"
                label="DeliveryType"
                name="deliveryType"
                disabled={isDisabled}
                value={giftProviderState.deliveryType}
                onChange={handleChange}
              >
                <MenuItem disabled>Select</MenuItem>
                <MenuItem value="AUTO">Auto</MenuItem>
                <MenuItem value="MANUAL">Manual</MenuItem>
              </StyledSelect>
            </FormControl>
            {error && error.deliveryType && (
              <small className="error-message">{error.deliveryType}</small>
            )}
          </Grid>

          <Grid item xs={12} md={6} className="grid-box">
            <StyledTextField
              label="Redemption Instructions Url"
              name="redemptionInstructionsUrl"
              value={giftProviderState.redemptionInstructionsUrl}
              onChange={handleChange}
              disabled={isDisabled}
            />
            {error && error.redemptionInstructionsUrl && (
              <small className="error-message">{error.redemptionInstructionsUrl}</small>
            )}
          </Grid>
          
          <Grid item xs={12} md={6} className="grid-box">
            <StyledTextField
              label="Unit"
              name="unit"
              type="number"
              value={giftProviderState.unit}
              onChange={(e) => {
                const re = /^[0-9]+\.?[0-9]*$/; // regex to match only digits and decimal numbers
                if (e.target.value === "" || re.test(e.target.value)) {
                  handleChange(e);
                }
              }}
              disabled={isDisabled}
            />
            {error && error.unit && (
              <small className="error-message">{error.unit}</small>
            )}
          </Grid>

          <Grid item xs={12} md={12} className="grid-box">
            {/* <StyledTextField
              label="Notes"
              name="notes"
              value={giftProviderState.notes}
              onChange={handleChange}
              disabled={isDisabled}
              id="notesTextEditor"
            /> */}
            <label style={{color: "#777", paddingBottom: "0.5rem", display: "block"}}>Notes</label>
            <EditorComponent editorClassName="notes-editor-wrapper" editorState={editorState} onEditorStateChange={(newEditorState) => {
              setEditorState(newEditorState);

              setGiftProviderState((prev) => {
                return {
                  ...prev,
                  notes: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                };
              });
              }} />
            {error && error.notes && (
              <small className="error-message">{error.notes}</small>
            )}
          </Grid>

        </Grid>
      </Box>
    </CustomDialog>
  );
};

Manage.defaultProps = {
  isDisabled: true,
};

export default Manage;
