import { ListItem as MuiListItem, ListItemIcon, ListItemText } from '@mui/material'
import { FC, ElementType } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface props {
    Icon: ElementType
    label: string
    navigateLink: string
}

const ListItem: FC<props> = ({ label, navigateLink, Icon }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isStateActive = location.pathname === navigateLink || location.pathname.split('/')[1] === navigateLink.split('/')[1];
    const primaryClassName = isStateActive ? "active-listItem-text" : "listItem-text";

    return (
        <MuiListItem
            onClick={() => navigate(navigateLink)}
            id="list-item"
            className={`collapse-ListItem ${isStateActive ? "active-item" : ""}`}
        >
            <ListItemIcon className='navIcons'>
                {
                    <Icon
                        style={{
                            color: isStateActive ? "#0B2A1A" : "",
                        }}
                    />
                }
            </ListItemIcon>
            <ListItemText
                className="collapse-text"
                primary={label}
                classes={{ primary: primaryClassName }}
            />
        </MuiListItem>
    )
}

export default ListItem;