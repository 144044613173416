import { LOGGEDIN_ADMIN } from "../../actions";

const initialState = {
 admin_id: ""
};

export const adminReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGGEDIN_ADMIN:{
      return {...state, admin_id:action.payload}
    }
    default: {
      return state;
    }
  }
};
