import "./style.scss";
import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Logo from "../../../assets/images/logo.png";
import { resetPassword } from "../../../services/Login";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [visibility,setVisibility] = useState({
    password: false,
    confirmPassword:false
  })
  const { enqueueSnackbar } = useSnackbar();
  const { resetCode } = useParams();
  const [info,setInfo] = useState({
    password: "",
    confirmPassword: "",

  })


  /*eslint-enable*/
  const handleSubmit = (event: SyntheticEvent) => {
    if(!info.password && !info.confirmPassword){
      return setErrors((prev)=>{
        return{
          ...prev,
          passwordRequired:true,
          matchPassword:false,
          confirmPasswordRequired:true
        }
      })
    }
    else if(!info.password){
      return setErrors((prev)=>{
        return{
          ...prev,
          passwordRequired:true,
          matchPassword:false,
          confirmPasswordRequired:false
        }
      })
    }
    else if(!info.confirmPassword){
      return setErrors((prev)=>{
        return{
          ...prev,
          passwordRequired:false,
          matchPassword:false,
          confirmPasswordRequired:true
        }
      })
    }
    else if(info.password !== info.confirmPassword){
      return setErrors((prev)=>{
        return{
          ...prev,
          passwordRequired:false,
          confirmPasswordRequired:false,
          matchPassword:true
        }
      })
    }
    else{
      setErrors((prev)=>{
        return{
          ...prev,
          passwordRequired:false,
          confirmPasswordRequired:false,
          matchPassword:false
        }
      })
      const payload = {
        newPassword : info.password,
        jti: resetCode
       }
  
      resetPassword(payload)
      .then(()=> {
        enqueueSnackbar("Password reset succesfully", {
            variant: "success",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          })
          navigate('/login')
    } )
    .catch((err)=> enqueueSnackbar("Unexpected error occured", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      }))
    }
  };

  const [errors,setErrors] = useState({
    passwordRequired: false,
    confirmPasswordRequired: false,
    matchPassword: false
  })


  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  return (
    <div className="container center">
      <div className="login-box">
          <Grid className="h-100" container>
            <Grid className="h-100" xs={12} md={6}>
              <img alt="Login" id="left-img" src={Logo} />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="credential-box center h-100">
                <div className="mb-5 text-center">
                  <Typography variant="h4" sx={{fontSize:'1.25rem'}}>Welcome to</Typography>
                  <Typography variant="h5" color="primary" sx={{fontSize:'2rem',fontWeight:"800"}}>
                    EcoPooling
                  </Typography>
                </div>
                <div className="password-box grid-box">
                  <TextField
                    label="New Password"
                    name="password"
                    type={!visibility.password ? "password" : "text"}
                    className="mb-3"
                    value={info.password}
                    onChange={handleChange}
                  />
                  <span
                    className={visibility.password  ? "visibility" : "hide-eye"}
                    onClick={() => setVisibility((prev)=>{
                      return{
                        ...prev,
                        password: !prev.password
                      }
                    })}
                  >
                    <Visibility className="visibility-icon"  sx={{color:"#707070"}}/>
                  </span>
                  <span
                    className={
                      !visibility.password  ? "visibility-off" : "hide-eye"
                    }
                    onClick={() => setVisibility((prev)=>{
                      return{
                        ...prev,
                        password: !prev.password
                      }
                    })}
                  >
                    <VisibilityOff className="visibility-officon" sx={{color:"#707070"}}/>
                  </span>
                  {errors.passwordRequired && (
                    <p className="auth-error-message">Password is required</p>
                  )}
                </div>
                <div className="password-box grid-box">
                  <TextField
                    label="Confirm New Password"
                    name="confirmPassword"
                    type={visibility.confirmPassword ? "password" : "text"}
                    className="mb-3"
                    value={info.confirmPassword}
                    onChange={handleChange}
                  />
                  <span
                    className={!visibility.confirmPassword ? "visibility" : "hide-eye"}
                    onClick={() => setVisibility((prev)=>{
                      return{
                        ...prev,
                        confirmPassword: !prev.confirmPassword
                      }
                    })}
                  >
                    <Visibility className="visibility-icon"  sx={{color:"#707070"}} />
                  </span>
                  <span
                    className={
                      visibility.confirmPassword  ? "visibility-off" : "hide-eye"
                    }
                    onClick={() => setVisibility((prev)=>{
                      return{
                        ...prev,
                        confirmPassword: !prev.confirmPassword
                      }
                    })}
                  >
                    <VisibilityOff className="visibility-officon"   sx={{color:"#707070"}}/>
                  </span>
                  {errors.confirmPasswordRequired && (
                    <p className="auth-error-message">Confirm Password is required</p>
                  )}
                  {errors.matchPassword && (
                    <p className="auth-error-message">Password doesn't match</p>
                  )}
                </div>
                <Button type="submit" className="mb-3" size="medium" fullWidth onClick={handleSubmit}>
                  Set New Password
                </Button>
              </div>
            </Grid>
          </Grid>
      </div>
    </div>
  );
};

export default ResetPassword;