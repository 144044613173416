import { httpRequest } from "../Network";
import { invite, trip } from "../endpoints";

export const getTrips = (filter:object) => {
    return httpRequest("GET",trip,{},filter)
    .then((response)=>{
        return response
    })
    .catch((err)=>{
        console.log(err)
    })
}

export const getTripsById = (id:string) => {
    return httpRequest('GET',`${trip}/${id}`)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}

export const exportTrips = (body:object) => {
    return httpRequest("POST",`${trip}/export`,body)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}

export const deleteTrip = (body: any) => {
    return httpRequest("DELETE",trip,body)
    .then((response:any)=>{
        return response.data
    })
    .catch((err)=>{
        console.log(err)
    })
}

export const exportSeatBookings = (body: {startDate: Date | string, endDate: Date | string}) => {
    return httpRequest("GET", 
        `${invite}/export-seat-bookings?startDate=${body.startDate}&endDate=${body.endDate}`)
    .then((response) => response)
    .catch((err)=>{
        throw err
    })
}

export const getTripsCount = (
    userId: string,
    startDate: string | Date,
    endDate: string | Date
) => {
    return httpRequest("GET", `/aggregation/trip/admin/user-trips-count?startDate=${startDate}&endDate=${endDate}&userId=${userId}`)
        .then((response: any) => {
            return response;
        })
        .catch((err) => {
            throw err;
        });
};

export const getUserTripReceipt = (_id: string, _tripId: string) => {
    return httpRequest('POST', '/payment/transaction/admin/trip-receipt', { _id, _tripId})
        .then((response: any) => response.data)
        .catch(err => {throw err;})
}

export const getPayoutTrips = (params: any) => {
    return httpRequest('GET', '/aggregation/trip/admin/payouts',{}, params)
        .then((response: any) => response)
        .catch(err => {throw err;});
}

export const payingOutTrips = (payload: any) => {
    return httpRequest('POST', '/aggregation/trip/admin/payouts', {...payload})
        .then((response) => response)
        .catch((err) => { throw err; });
}

export const exportPayoutTrips = (params: any) => {
    return httpRequest("GET", "/aggregation/trip/admin/export-payouts", {}, params)
        .then((response: any) => response)
        .catch((err) => { throw err; });
}
