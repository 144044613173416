import React, { FC } from "react";
import "./style.scss";
import { Grid, Typography } from "@mui/material";
import { capitalizeFirstLetter} from "../../../../utils";
import { PaymentDetailProps } from "../../../../interfaces/payment";
const PaymentDetails: FC<PaymentDetailProps> = ({
  email,
  phone,
  image,
  fullName,
  gender,
  paymentUniqueId,
  totalAmount,
  userPreference,
  paymentDate,
  paymentType,
}) => {
  return (
    <>
      <div className="detail-container ">
        <div className="top-heading-container">
          <b>User Details</b>
        </div>
        <div className="detail-card">
          <div className="user-image-container">
            <img src={image} alt="user-pic" className="image" />
          </div>
          <div className="user-detail">
            <div>
              <span>
                <b>{fullName}</b>
              </span>
              <br/>
              <span>Gender : </span>
              <span>{capitalizeFirstLetter(gender)}</span>

              <br />
              <span>Email : </span>
              <span>{email}</span>

              <br />
              <span>Phone : </span>
              <span>{phone}</span>
              <br />
              <span>Preference : </span>
              <span>{capitalizeFirstLetter(userPreference)}</span>
              <br />
            </div>
          </div>
        </div>
        <div className="divider">
          <hr className="styled-horizontal-bar" />
        </div>
        <div className="essential-details">
          <div className="details-text">
            <b>Payment Details</b>
          </div>
          <div className="gridContainer">
            <Grid container className="grid">
              <Grid item xs={12} md={6} className="grid-item">
                <Typography className="grid-text">
                  <b>Payment Id</b>
                </Typography>
                <Typography sx={{paddingRight:'16px'}}>{paymentUniqueId}</Typography>
              </Grid>
              <Grid item xs={12} md={6} className="grid-item">
                <Typography className="grid-text">
                  <b>Total Amount</b>
                </Typography>
                <Typography>£{totalAmount}</Typography>
              </Grid>
              <Grid item xs={12} md={6} className="grid-item">
                <Typography className="grid-text">
                  <b>Payment Date</b>
                </Typography>
                <Typography sx={{paddingRight:'16px'}}>{paymentDate}</Typography>
              </Grid>
              <Grid item xs={12} md={6} className="grid-item" >
                <Typography className="grid-text" >
                  <b>Transaction Type</b>
                </Typography>
                <Typography>{paymentType}</Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentDetails;
