import { Grid, Button, Box, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { getWhatsAppBot, updateWhatsAppData } from '../../../services/configurations';
import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import Loader from '../../../components/mui/loader/Loader';
import { IResponse, IWhatsAppBotState } from '../../../interfaces/configuration/whatsapp-bot/index';
import ErrorMessage from '../../../components/error/errorStatement';
import { StyledTextField } from '../../user/manage';

const WhatsappBot = () => {

  const { register, handleSubmit, setValue, formState: {
    errors
  } } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [whatsAppBot, setWhatsAppBot] = useState<IWhatsAppBotState>(
    {
      appId: "",
      appSecretId: "",
      businessManagerId: "",
      createdAt: "",
      phoneNumberId: "",
      systemToken: "",
      updatedAt: "",
      wabaId: "",
      __v: 0,
      _id: "",
      loader: true
    }
  );
  /* eslint-disable */
  useEffect(() => {
    const result = getWhatsAppBot();
    result.then((response: IResponse) => {
      setValue("appId", response.data.appId);
      setValue("appSecretId", response.data.appSecretId);
      setValue("businessManagerId", response.data.businessManagerId);
      setValue("wabaId", response.data.wabaId);
      setValue("phoneNumberId", response.data.phoneNumberId);
      setValue("systemToken", response.data.systemToken)
      if (response.data) {
        setWhatsAppBot({ ...whatsAppBot, ...response.data });
        setWhatsAppBot({ ...whatsAppBot, loader: false });
      }
    })
    .catch(()=>{
      enqueueSnackbar("Data not found",{variant:'error'})
      setWhatsAppBot({ ...whatsAppBot, loader: false });
    })
  }, [])
  /* eslint-enable */
  const formDetails = (details: FieldValues) => {
    setWhatsAppBot({ ...whatsAppBot, loader: true });
    let appId = details.appId;

    let appSecretId = details.appSecretId;

    let businessManagerId = details.businessManagerId;

    let wabaId = details.wabaId;

    let phoneNumberId = details.phoneNumberId;

    let systemToken = details.systemToken;

    const result = updateWhatsAppData({ appId, appSecretId, businessManagerId, wabaId, phoneNumberId, systemToken });
    result.then((response) => {
      setWhatsAppBot({ ...whatsAppBot, loader: false });
      enqueueSnackbar("Bot updated successfully", {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      })
    }).catch((er) => enqueueSnackbar("Couldn't update the bot , please try again", {
      variant: 'error',
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      }
    }))


  }
  return (
    <>
      <div className='whats-appbot' style={{marginTop:'24px'}}>

        <form onSubmit={handleSubmit(formDetails)}>
          <Grid container spacing={5} >
            <Grid item xs={12} md={6}>
              <StyledTextField
                label="App ID"
                variant="outlined"
                {...register("appId", { required: true })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' />)
                }}
              />
              {
                errors.appId && <p className='error-text'>App Id is required</p>
              }
            </Grid>
            <Grid item xs={12} md={6}>
            <StyledTextField
                label="App Secret ID"
                variant="outlined"
                {...register("appSecretId", { required: true })}
                InputProps={{ startAdornment: (<InputAdornment position="start" />) }}
              />
              {errors.appSecretId && <p className='error-text'>App Secret Id is required</p>}
            </Grid>
            <Grid item xs={12} md={6} className='grid-item'>
            <StyledTextField
                label="Business Manager ID"
                variant="outlined"
                InputProps={{
                  startAdornment: (<InputAdornment position='start' />)
                }}
                {...register("businessManagerId", { required: true })}
              />
              {
                errors.businessManagerId && <p className='error-text'>Business Manager Id is required</p>
              }
            </Grid>
            <Grid item xs={12} md={6} className='grid-item'>
            <StyledTextField
                label="WABA ID"
                variant="outlined"
                {...register("wabaId", { required: true })}
                InputProps={{ startAdornment: (<InputAdornment position='start' />) }}
              />
              {errors.wabaId && <p className='error-text'>Waba Id is required</p>}
            </Grid>
            <Grid item xs={12} md={6} className='grid-item'>
            <StyledTextField
                label="Phone Number ID"
                variant="outlined"
                {...register("phoneNumberId", { required: true })}
                InputProps={{ startAdornment: (<InputAdornment position='start' />) }}
              />
              {
                errors.phoneNumberId && <p className='error-text'>Phone Number Id is required</p>
              }
            </Grid>
            <Grid item xs={12} md={6} className='grid-item'>
            <StyledTextField
                label="System Token"
                variant="outlined"
                {...register("systemToken", { required: true })}
                InputProps={{ startAdornment: (<InputAdornment position="start" />) }}
              />
              {errors.systemToken && <ErrorMessage variant='error' className='error-text' innerText={"System Token is required"} />}
            </Grid>
          </Grid>

          <Box className='mt-3' textAlign='end'>
            <Button
              size='medium'
              type='submit'
            >
              Save
            </Button>
          </Box>
        </form>
        {whatsAppBot.loader && <Loader />}
      </div>
    </>
  )
}

export default WhatsappBot;