import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "./index.scss";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
interface ControlledEditorProps {
  editorState: EditorState;
  onEditorStateChange: (newEditorState: EditorState) => void;
  editorClassName?: string;
}

const EditorComponent: React.FC<ControlledEditorProps> = ({
  editorState,
  onEditorStateChange,
  editorClassName,
}) => {
  return (
    <Editor
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "fontFamily",
          "list",
          "textAlign",
          "colorPicker",
          "link",
          "emoji",
          "image",
        ],
        inline: { options: ["bold", "italic", "underline", "strikethrough"] },
        blockType: { options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"] },
        colorPicker: {
          colors: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
          ],
        },
      }}
      wrapperClassName="demo-wrapper"
      editorClassName={`${editorClassName} editor-class`}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
  );
};

export default EditorComponent;
