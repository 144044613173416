import { httpRequest } from "../../Network"
import {ecoSavings, location} from '../../endpoints'

export const addNewLocation = (payload:object) => {
    return httpRequest('POST',location,payload)
    .then((res)=>res)
    .catch((err)=> {
        throw err
    })
}
export const getAllLocations = (filter?:object) => {
    return httpRequest('GET',location,{},filter)
    .then((res)=>res)
    .catch((err)=> {
        throw err
    })
}

export const getLocationById = (id:string|undefined) => {
    return httpRequest('GET',`${location}/${id}`)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}

export const updateStatus = (id:string,payload:object) => {
    return httpRequest('PUT',`${location}/${id}`,payload)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}

export const deleteLocation = (id:string) => {
    return httpRequest('DELETE',`${location}/${id}`)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}

// approve the status of admin to approved
export const approveMember = (id:string,payload:object) => {
    return httpRequest('PUT',`${location}/community-member-requests/${id}`,payload)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}

export const getLocationEcoSavings = ( locationId: string | undefined ) => {
    return httpRequest('GET',`${ecoSavings}/admin/location/${locationId}`)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}

// update invited users in the location
export const updateInvitedUsers = (locationId:string, payload:object) => {
    return httpRequest('PUT',`${location}/admin/invite-user/${locationId}`,payload)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}
