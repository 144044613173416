import './App.scss';
import './assets/css/bootstrap/style.scss';
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from './assets/theme';
import routes from './routes';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        autoHideDuration={1000}
        maxSnack={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <RouterProvider router={routes} />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
