import { MouseEvent, ChangeEvent, useState, useEffect } from "react";
import { IconButton, FormControl, Select, MenuItem, Tooltip, Button, FormLabel } from "@mui/material";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditDialog from "./Edit-purchase-request";
import WarningDialog from "../../../components/mui/warning-dialog";
import {
  exportGiftData,
  getAllPurchaseRequests,
  sendGifCardOnWhatsapp,
} from "../../../services/Purchase Request";
import Loader from "../../../components/mui/loader/Loader";
import { findNameByLanguage, capitalizeFirstLetter, formatDate } from "../../../utils";
import { useLanguage } from "../../../components/language-provider";
import { useSnackbar } from "notistack";
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { deletePayment } from "../../../services/payment";
import { Download } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ExportDialog from "../../../components/mui/dialog/exportDialog";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IPurchaseState } from "../../../interfaces/payment";
import greenReceiptIcon from '../../../assets/images/green_reciept.svg';
import { getUserGiftReceipt } from "../../../services/Gift Category";
// import { getUserGiftReceipt } from "../../../services/Gift Category";

const columns = [
  {
    id: "giftCardName",
    label: "Gift Card Name",
  },
  {
    id: "giftId",
    label : "Gift Id"
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "promoCodeDiscount",
    label: "Promo Code Discount"
  },
  {
    id: "customerName",
    label: "Customer Name",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id : "purchasedOn",
    label : "Purchase Date"
  },
  {
    id: "giftUrl",
    label: "Gift URL",
  },
  {
    id: "purchaseStatus",
    label: "Status",
  },
  {
    id: "tilloFee",
    label: "Tillo Fee"
  },
  {
    id: "ecopoolingCommission",
    label: "ecopoolingCommission"
  },
  {
    id: "action",
    label: "Action",
  },
];

const GiftPurchaseRequest = () => {
  const { language } = useLanguage();
  const [purchaseStates, setPurchaseStates] = useState<IPurchaseState>({
    status: "ALL",
    deleteWarning: false,
    editDialog: false,
    _request: "",
    loading: true,
    page: 1,
    totalPage: 1,
    editInfo: {
      _id: '',
      name: '',
      amount: '',
      customer: '',
      phone: '',
      status: '',
      giftUrl: ''
    },
    exportGiftsData: false,
    startDate: undefined,
    endDate: undefined
  });
  const [searchTerm, setSearchTerm] = useState("");

  const [giftCardStates, setGiftCardStates] = useState({
    giftWarning: false,
    giftInfo: {
      name: '',
      photo: '',
      phone: "",
      amount: 0,
      userName: '',
      giftCardUrl: ''
    }
  });
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any>([]);
  // const [edit, setEdit] = useState(false);

  const handleSearch = (e: ChangeEvent<unknown> | any) => {
    const searchValue = e.target.value;
    
    if (e.key === "Enter") {
      setSearchTerm(e.target.value)

      fetchData(searchValue);
    }
  };

  const handleAdd = (e: MouseEvent<HTMLElement>) => { };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setPurchaseStates((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  
  const onPageChange = (e: ChangeEvent<unknown>, pageNumber: number) => {
    setPurchaseStates({ ...purchaseStates, page: pageNumber });
  };

  const onEdit = (
    _id: string,
    name: string,
    amount: number | string,
    status: string,
    giftUrl: string,
    customer: string,
    phone: number | string
  ) => {
    setPurchaseStates((prev: any) => {
      return {
        ...prev,
        editDialog: !prev.editDialog,
        editInfo: {
          _id,
          name,
          amount,
          customer,
          status,
          giftUrl,
          phone,
        },
      };
    });
  };

  // eslint-disable-next-line
  const sendGiftCard = (
    name: string,
    photo: string,
    phone: string,
    amount: string,
    giftCardUrl: string,
    userName: string,
  ) => {
    setGiftCardStates((prev: any) => {
      return {
        ...prev,
        giftInfo: {
          name,
          photo,
          phone,
          amount,
          giftCardUrl,
          userName
        }
      };
    });
  };

  const onDelete = () => {
    deletePayment(purchaseStates._request)
      .then(() => {
        enqueueSnackbar("Deleted successfully", {
          variant: "success",
        })
        handleDeleteWarning("")
        fetchData()
      }
      )
      .catch(() => {
        handleDeleteWarning("");
        enqueueSnackbar("Couldn't delete data", {
          variant: "error",
        })
      }
      );
  };

  const onSendGiftCard = () => {
    sendGifCardOnWhatsapp(giftCardStates.giftInfo)
      .then(() => {
        enqueueSnackbar("Send successfully", {
          variant: "success",
        })

        handleGiftCardWarning({});

        fetchData()
      })
      .catch((e) => {
        handleGiftCardWarning({});

        enqueueSnackbar(e.message, {
          variant: "error",
        })
      });
  };

  const handleDeleteWarning = (id: string) => {
    setPurchaseStates((prev) => {
      return {
        ...prev,
        deleteWarning: !prev.deleteWarning,
        _request: id,
      };
    });
  };

  const handleGiftCardWarning = (purchaseReq: any) => {
    setGiftCardStates((prev) => {
      let amountVal = (((purchaseReq?.amount?.totalAmount * 100) + purchaseReq.ecoTokens) / (1 - ((purchaseReq.discount / 100)))) / 100;

      return {
        ...prev,
        giftWarning: !prev.giftWarning,

        giftInfo: {
          name: (purchaseReq?.giftName || [{ value: "" }])[0]?.value || "",
          photo: purchaseReq?.giftImage || "",
          phone: purchaseReq?.phone || "",
          giftCardUrl: purchaseReq?.giftUrl || "",
          amount: Math.round(amountVal),
          userName: ((purchaseReq?.firstName || [{ value: "" }])[0]?.value || "") + ' ' + ((purchaseReq?.lastName || [{ value: "" }])[0]?.value || "")
        }
      };
    });
  };


  const createRow = (
    purchaseRequests: any,

    onEdit: (_id: string, name: string, amount: number, status: string, giftUrl: string, customer: string, phone: number) => void,

    handleDeleteWarning: (id: string) => void,

    // eslint-disable-next-line
    handleGiftCardWarning: ({ }) => void
  ) => {
    const _id = purchaseRequests._id;

    const giftCardName = findNameByLanguage(
      language,
      purchaseRequests.giftName
    );

    const promoCodeDiscount = purchaseRequests.promos !== undefined ? (purchaseRequests.promos.length * 0.35).toFixed(2) : 0;
    const giftId = purchaseRequests.giftUniqueId;

    const customerName =
      findNameByLanguage(language, purchaseRequests.firstName) +
      " " +
      findNameByLanguage(language, purchaseRequests.lastName);

    const amount = purchaseRequests.amount.totalAmount;
    const giftUrl = purchaseRequests.giftUrl;

    const purchaseStatus = capitalizeFirstLetter(
      purchaseRequests.giftStatus
    );

    const phone = purchaseRequests.phone;

    const purchasedOn = formatDate(purchaseRequests.createdAt);

    const action = (
      <>
        <Tooltip title="Send Gift Card">
          {(purchaseRequests.giftUrl && purchaseRequests.giftStatus === "COMPLETED") ? (<IconButton
            color="primary"
            onClick={() => handleGiftCardWarning(purchaseRequests)}
          >
            <CardGiftcardIcon />
          </IconButton>) : ((<IconButton
            color="primary"
            onClick={() => handleGiftCardWarning(purchaseRequests)}
            disabled
          >
            <CardGiftcardIcon />
          </IconButton>))}
        </Tooltip>

        <Tooltip title="Receipt">
          <IconButton
            color="primary"
            onClick={async () => {
              try {
                const link = (await getUserGiftReceipt( purchaseRequests._id )).path;

                let url = '';

                fetch(link)
                  .then(response => response.blob())
                  .then(blob => {
                    url = URL.createObjectURL(blob)
                    const a = document.createElement('a');
                    a.href=url;
                    a.download="gift_receipt.pdf";
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                  })
                  .catch(err => {
                    enqueueSnackbar(err.message || "Error occurred while downloading receipt", {
                      variant: "error"
                    })
                  })
                  .finally(() => {
                    URL.revokeObjectURL(url);
                  })

                // const a = document.createElement('a');
                // a.href=link;
                // a.download="gift_receipt.pdf";
                // document.body.appendChild(a);
                // a.target = '_blank';
                // a.click();
                // document.body.removeChild(a);
              } catch (error) {
                enqueueSnackbar('Error occurred while generating receipt');
              }
              
            }}
          >
            <img src={greenReceiptIcon} alt="receipt" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Edit">
          <IconButton
            color="primary"
            onClick={() => onEdit(_id, giftCardName as string, amount, purchaseStatus, giftUrl, customerName, phone)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title='Delete'>
          <IconButton
            color="error"
            onClick={() => handleDeleteWarning(purchaseRequests._id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    const tilloFee = purchaseRequests.tilloServiceFee;
    const ecopoolingCommission = purchaseRequests.ecopoolingCommission;
    return {
      _id,
      giftCardName,
      customerName,
      amount,
      giftUrl,
      giftId,
      purchasedOn,
      purchaseStatus,
      phone,
      action,
      tilloFee,
      ecopoolingCommission,
      promoCodeDiscount
    };
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [purchaseStates.status, purchaseStates.page]);

  const handleGiftsExportClose = () => {
    setPurchaseStates({...purchaseStates, startDate: undefined, endDate: undefined, exportGiftsData: !purchaseStates.exportGiftsData })
  }

  const handleGiftDataExport = () => {
    const payload = {
      startDate: purchaseStates.startDate && new Date(new Date(purchaseStates.startDate).setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds() )).toISOString() ,
      endDate: purchaseStates.endDate && new Date(new Date(purchaseStates.endDate).setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds() )).toISOString(),
    };
    if (!purchaseStates.endDate || !purchaseStates.startDate) {
      return enqueueSnackbar("Select start date and end date first", {
        variant: "error",
      });
    }

    exportGiftData(payload)
      .then((res:any) => {        
        enqueueSnackbar("Data exported", {
          variant: "success",
        });
        handleGiftsExportClose();
        window.open(res.data,"_self")
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
        handleGiftsExportClose();
      });
  }
  //eslint-enable
  const fetchData = (searchValue?: string) => {
    setPurchaseStates((prev) => {
      return {
        ...prev,
        loading: true
      }
    })

    getAllPurchaseRequests({
      page : (searchValue !== undefined) ? 1 : purchaseStates.page,
      purchaseRequestStatus: purchaseStates.status,
      searchValue: (searchValue !== undefined) ? searchValue : searchTerm ? searchTerm : undefined,
    })
      .then((res: any) => {
        const list = res.data.map((elem: object, key: number) =>
          createRow(elem, onEdit, handleDeleteWarning, handleGiftCardWarning)
        );

        setPurchaseStates((prev) => {
          return {
            ...prev,
            loading: false,
            page: res.meta.page,
            totalPage: res.meta.totalPages
          }
        })

        setRows(list);
      })
      .catch((err) => {
        setPurchaseStates((prev) => {
          return {
            ...prev,
            loading: false
          }
        })

        setRows([]);
          console.log(err.response.data.message)
          enqueueSnackbar(err.response.data.message,{variant:"error"})
      })
      .finally(() => {
        setPurchaseStates((prev) => {
          return {
            ...prev,
            loading: false
          }
        })
      });
  };

  return (
    <>
      {purchaseStates.loading && <Loader />}
      <Header
        searchPlaceholder="Search by customer name or phone"
        // btnText="Add User"
        onSearch={handleSearch}
        onBtnClick={handleAdd}
        searchInput
      >
        <FormControl className="ml-2" size="small">
          <Select
            size="small"
            //defaultValue="ALL"
            name="status"
            value={purchaseStates.status}
            onChange={handleChange}
          >
            <MenuItem value="ALL">All status</MenuItem>

            <MenuItem value="PENDING">Pending</MenuItem>

            <MenuItem value="COMPLETED">Delivered</MenuItem>

            <MenuItem value="CANCELLED">Cancelled</MenuItem>
          </Select>
        </FormControl>
        <Tooltip title="Export Gift Purchases">
          <Button
            color="primary"
            variant="outlined"
            className="ml-2 h-100"
            onClick={() =>
              setPurchaseStates({ ...purchaseStates, exportGiftsData: !purchaseStates.exportGiftsData })
            }
          >
           <Download/>
          </Button>
        </Tooltip>
      </Header>

      <CustomTable
        columns={columns}
        rows={rows}
        height="calc(100vh - 183px)"
        errorMessage="No data to show"
        pagination={{
          page: purchaseStates.page,
          totalPages: purchaseStates.totalPage,
        }}
        onPageChange={onPageChange}
      />

      <EditDialog
        isOpen={purchaseStates.editDialog}
        _id={purchaseStates.editInfo._id}
        onClose={() => onEdit("", "", "", "", "", "", "")}
        fetchData={fetchData}
        name={purchaseStates.editInfo.name}
        amount={purchaseStates.editInfo.amount}
        customer={purchaseStates.editInfo.customer}
        phone={purchaseStates.editInfo.phone}
        status={purchaseStates.editInfo.status?.toUpperCase()}
        giftURL={purchaseStates.editInfo.giftUrl}
      />

      <WarningDialog
        isOpen={purchaseStates.deleteWarning}
        onClose={() => handleDeleteWarning("")}
        onConfirm={onDelete}
        title="Delete User"
        description="Are you sure do you want to delete this purchase request?"
      />

      <WarningDialog
        isOpen={giftCardStates.giftWarning}
        onClose={() => handleGiftCardWarning({})}
        onConfirm={onSendGiftCard}
        title="Send Gift Card"
        description="Are you sure do you want to send this gift Card?"
      />
      {/* Export Data  */}
      <ExportDialog
        title="Export Gifts Data"
        open={purchaseStates.exportGiftsData}
        onConfirm={handleGiftDataExport}
        onClose={() => handleGiftsExportClose()}
        btnText="Export Gifts Data"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="date-container">
            <FormLabel sx={{ marginBottom: '5px' }} className="date-label">Start Date</FormLabel>
            <DatePicker
              label={purchaseStates.startDate ? "" : "Select Start Date"}
              className="start-date"
              value={purchaseStates.startDate}
              minDate={
                purchaseStates.endDate
                  ? dayjs(purchaseStates.endDate).subtract(1, "month")
                  : undefined
              }
              maxDate={dayjs(new Date())}
              onChange={(newValue) =>
                setPurchaseStates((prev) => {
                  return {
                    ...prev,
                    startDate: (newValue),
                  };
                })
              }
            />
          </div>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="date-container">
            <FormLabel sx={{ marginBottom: '5px', marginTop: '5px' }} className="date-label">End Date</FormLabel>
            <DatePicker
              label={purchaseStates.endDate ? "" : "Select End Date"}
              className="end-date"
              value={purchaseStates.endDate}
              minDate={purchaseStates.startDate ? dayjs(purchaseStates.startDate) : ""}
              maxDate={
                purchaseStates.startDate &&
                  dayjs().diff(dayjs(purchaseStates.startDate), "day") > 31
                  ? dayjs(purchaseStates.startDate).add(1, "month")
                  : dayjs(new Date())
              }
              onChange={(newValue) =>
                setPurchaseStates((prev) => {
                  return {
                    ...prev,
                    endDate: (newValue),
                  };
                })
              }
            />
          </div>
        </LocalizationProvider>
      </ExportDialog>
    </>
  );
};

export default GiftPurchaseRequest;
