import { httpRequest } from "../Network";
import { dashboard } from "../endpoints";

export const getTripDashboardCount = () => {
    return httpRequest("GET",`${dashboard}/count`)
      .then((response) => response)
      .catch((er) => {
        throw er
      });
};

export const getUsersData = (filter:object) => {
    return httpRequest(
        "GET",
        `${dashboard}/users`,
        {},
        filter
    )
}

export const getTripsData = (filter:object) => {
   return httpRequest(
      "GET",
      `${dashboard}/trips`,
      {},
      filter
    )
      .then((response) => response)
      .catch((error) =>{
        throw error
      } );
};
