import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Checkbox, FormControl, IconButton, MenuItem, Select, Switch, Tooltip } from '@mui/material';
import Header from '../../components/header';
import CustomTable from '../../components/mui/table';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Manage from './manage';
import WarningDialog from '../../components/mui/warning-dialog';
import {getAllAdmin, searchAdmin, deleteAdmin, updateAdminStatus } from '../../services/Admin';
import Avatar from '@mui/material/Avatar';
import { useSnackbar } from 'notistack';
import { findNameByLanguage,openImage } from '../../utils';
import { useLanguage } from '../../components/language-provider';
import Loader from '../../components/mui/loader/Loader';
import { capitalizeFirstLetter } from '../../utils';
import { adminColumns as columns} from '../../utils';
import { IAdminState } from '../../interfaces/admin';


const Admin = () => {
  const {language} = useLanguage()
  const {enqueueSnackbar} = useSnackbar()
  const [state, setState] = useState<IAdminState>({
    manage: {
      type: 'new',
      isOpen: false,
      data: {}
    },
    loading:true,
    deleteWarning: false,
    updateStatusWarning:false,
    _user: '',
    currStatus:'',
    list: [],
    status:'ALL',
    page:1,
    totalPage:1
  });

  const fetchData = () => {
    getAllAdmin({ status: state.status, page: state.page })
      .then((response) => {
        const list = response.data.map((user: any, index: any) =>
          createRow(user, index, handleManage, handleDelete)
        );
        setState((prevState) => {
          return {
            ...prevState,
            list: list,
            totalPage:response.meta.totalPage,
            loading:false
          };
        });
      })
      .catch((err) =>  enqueueSnackbar("Couldn't get data", {
        variant: "error"
      }));
  };
  // const handleStatusDialog = (user:string) => {
  //   setState((prev)=>{
  //     return{
  //       ...prev,
  //       updateStatusWarning: !prev.updateStatusWarning,
  //       _user:user
  //     }
  //   })
  // }
/*eslint-disable*/
  useEffect(() => {
    fetchData()
  }, [state.status, state.page]);
/*eslint-enable*/

  const handleSearch = (event: ChangeEvent<HTMLInputElement> | any) => {
    const searchItem = event.target.value;
    if (event.key === "Enter") {
      searchAdmin(searchItem).then((response: any) => {
        const list = response.map((user: any, index: number) =>
          createRow(user, index, handleManage, handleDelete)
        );
        setState((prevState) => {
          return {
            ...prevState,
            list: list,
          };
        });
      });
    }
  };

  const onPageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    setState({ ...state, page: newPage });
  };

  const handleManage = (type: 'new' | 'edit' | 'view') => {
    setState(prevState => {
      return {
        ...prevState,
        manage: {
          ...prevState.manage,
          isOpen: !prevState.manage.isOpen,
          type
        }
      }
    });
  }

  const handleDelete = (_user: string = '') => {
    setState(prevState => {
      return {
        ...prevState,
        deleteWarning: !prevState.deleteWarning,
        _user
      }
    });
  }

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement> | any) => {
    setState((prevState) => {
      return {
        ...prevState,
        status: event.target.value,
      };
    });
     fetchData();
  };

  const onDelete = () => {
    const data = {
      ids: [state._user]
    }
    deleteAdmin(data).then(()=>{
      enqueueSnackbar("Deleted successfully", {
        variant: "success"
      });
      setState(prevState => {
        return {
          ...prevState,
          deleteWarning: !prevState.deleteWarning
        }
      });
      fetchData();
    })
      .catch((err) => {
        console.log(err)
      })
  }

  const updateStatus = (userId: string, event: ChangeEvent<HTMLInputElement> | any) => {
    const isChecked = event.target.checked;
    const newStatus = isChecked ? 'ACTIVE' : 'INACTIVE';
    const body = {
      _id: userId,
      status: newStatus
    }
    updateAdminStatus(body).then(()=>{
      setState((prevState)=>{
        return {
          ...prevState,
          loading:true
        }
      })
      enqueueSnackbar("Status Updated", {
        variant: "success"
      });
      fetchData()
    })
     .catch((err)=> enqueueSnackbar("Couldn't update status", {
      variant: "error"
    }))
  }

  const handleClose = () => {
    handleManage(state.manage.type)
    fetchData()
  }

  const createRow = (user: any, index: number, onEdit: any, onDelete: any) => {
    return {
      all: <Checkbox defaultChecked={false} />,
      id: (state.page - 1) * 10 + index + 1,
      image: (
        <Avatar
          sx={{ cursor: "pointer",margin:'0px auto' }}
          alt="image"
          src={user.photo}
          onClick={() => openImage(user.photo)}
        ></Avatar>
      ),
      name:`${capitalizeFirstLetter(findNameByLanguage(language, user.firstName))} 
      ${capitalizeFirstLetter(findNameByLanguage(language, user.lastName))}`,
      email: user.email,
      role: capitalizeFirstLetter(user.role.roleType),
      status: (
        <Switch
          checked={user.status === "ACTIVE" ? true : false}
          onChange={(e) => updateStatus(user._id, e)}
        />
      ),
      action: (
        <>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                onEdit("edit");
                setState((prevState) => ({
                  ...prevState,
                  manage: {
                    ...prevState.manage,
                    data: user,
                  },
                }));
              }}
              color="primary"
              disabled={user.role.roleType === "SUPERADMIN" ? true : false}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="delete">
            <IconButton
              onClick={() => onDelete(user._id)}
              color="error"
              disabled={user.role.roleType === "SUPERADMIN" ? true : false}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    };
  }

  return (
    <>
      {state.loading && <Loader />}
      <div>
        {/* Add Data  */}
        <Header
          searchPlaceholder="Search by user name, email"
          btnText="Add User"
          onSearch={handleSearch}
          onBtnClick={() => handleManage("new")}
          searchInput
        >
          <FormControl className="ml-2" size="small">
            <Select
              size="small"
              value={state.status}
              onChange={handleStatusChange}
            >
              <MenuItem value="ALL">All Status</MenuItem>
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">In-Active</MenuItem>
            </Select>
          </FormControl>
        </Header>

        {/* Show Data  */}
        <Box marginTop="10px">
          <CustomTable
            columns={columns}
            rows={state.list}
            height="calc(100vh - 193px)"
            errorMessage="Add user to see the data here"
            pagination={{
              page: state.page,
              totalPages: state.totalPage,
            }}
            onPageChange={onPageChange}
          />
        </Box>

        {/* Manage Data  */}
        <Manage
          isOpen={state.manage.isOpen}
          type={state.manage.type}
          data={state.manage.data}
          onClose={() => {
            handleClose();
          }}
        />

        {/* <WarningDialog
          isOpen={state.updateStatusWarning}
          onClose={() => handleStatusDialog()}
          onConfirm={updateStatus}
          title="Update Status"
          description="Are you sure want to make changes ?"
        /> */}

        {/* Delete Data  */}
        <WarningDialog
          isOpen={state.deleteWarning}
          onClose={() => handleDelete()}
          onConfirm={onDelete}
          title="Delete User"
          description="Are you sure do you want to delete this user?"
        />
      </div>
    </>
  );
}

export default Admin;