import { IPaymentListing } from '../../interfaces/payment';
import { httpRequest } from '../Network';
export const getPayment = () => {
    return new Promise((resolve, reject) => {
        httpRequest("GET", "/configuration/payment").then((response) => {
            resolve(response);
        }).catch(er => {
            console.log("Error", er);
        })
    })
}
export const savePayment = (stripeId: string, secretKey: string, stripe: string) => {
    return new Promise((resolve, reject) => {
        httpRequest("PUT", "/configuration/payment", { providerId: stripeId, secretKey, providerType: stripe }).then((response) => {
            resolve(response)
        }).catch((er) => {
            console.log("Error", er)
        })
    })
}

export const getPaymentList = (filter?:object) => {
    return httpRequest("GET", 'payment/transaction/list', {}, filter)

        .then((res: IPaymentListing | any) => res)
        .catch((err) => {
            throw err
        })
}

export const getPaymentById = (id:string) => {
    return httpRequest("GET",`payment/transaction/get-transaction-detail/${id}`)
    .then((response)=> response)
    .catch((error)=>{
        throw error
    })
}
export const deletePayment = (id: string) => {
    return new Promise((resolve, reject) => {
        httpRequest("DELETE", `payment/transaction?_id=${id}`).then((reponse) =>
            resolve(reponse)
        ).catch((er) => reject(er));
    })
}

// export payment data service
export const exportPayment = (body:object) => {
    return httpRequest("POST",`payment/transaction/export`,body)
    .then((res)=> res)
    .catch((err)=>{
        throw err
    })
}