import React,{ useState} from 'react';
import Picker from 'emoji-picker-react';
import './index.scss';
import { StyledTextField } from '../../screens/user/manage';


interface IProps {
  inputStr : string,
  onEmojiClick : (emojiObject:any,event:any) => void,
  handleEmojiChange :(event:any) => void
}
const EmojiPicker = ({inputStr,onEmojiClick,handleEmojiChange}:IProps) => {


  const [showPicker, setShowPicker] = useState(false);
 
 
  return (
    <div className="">
      <div className="picker-container">
        <StyledTextField
          label="Select emoji"
          className="input-style"
          value={inputStr}
          onChange={(e) => {
            handleEmojiChange(e);
            setShowPicker(val => !val);
          }}
          />
        <img
          className="emoji-icon"
          src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
          alt ="emoji-picker"
          onClick={() => setShowPicker(val => !val)} 
          />
        {showPicker && <Picker
          onEmojiClick={onEmojiClick} />}
      </div>
    </div>
  )
}

export default EmojiPicker
