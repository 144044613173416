import { FC, useEffect, useState } from "react";
import CustomDialog from "../../../components/mui/dialog";
import { Box, Button } from "@mui/material";
import { getTripsById } from "../../../services/Trips";
import { findNameByLanguage, capitalizeFirstLetter } from "../../../utils";
import { useLanguage } from "../../../components/language-provider";
import "./index.scss";
import { formatDate } from "../../../utils";
import PassengerDetail from "../../../components/passengerDetail";
import profileIcon from "../../../assets/images/logo.png";
import Loader from "../../../components/mui/loader/Loader";

import { IManageTripProps, ITripDetails } from "../../../interfaces/trips";

const Manage: FC<IManageTripProps> = ({
  isOpen,
  type,
  isDisabled,
  onClose,
  id,
  payoutData
}) => {
  const { language } = useLanguage();
  isDisabled = type === "view" ? true : false;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ITripDetails>();
  const [ecoData,setEcoData] = useState<any>()

  useEffect(() => {
    if (id) {
      setLoading(true);
      getTripsById(id)
        .then((res: any) => {
          setData(res.data);
          setEcoData(res.meta)
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [type, id]);

  return (
    <>
      {loading && isOpen ? (
        <Loader />
      ) : (
        <CustomDialog
          title={"Details"}
          disabled={isDisabled}
          isOpen={isOpen}
          onClose={onClose}
        >
          <Box>
            {data && (
              <div className="driver-detail-container ">
                <div className="top-heading-container">
                  <b>Driver Details</b>
                </div>
                <div className="driver-detail-card">
                  <div className="driver-row">
                    <div className="driver-image-container">
                      <img
                        className="driver-image"
                        src={data?._driver?.photo || profileIcon}
                        alt="profile-pic"
                      />
                    </div>
                    <div className="driver-name-contact content-wrap">
                      <span>
                        <b>
                          {capitalizeFirstLetter(
                            findNameByLanguage(
                              language,
                              data?._driver?.firstName
                            )
                          ) +
                            " " +
                            capitalizeFirstLetter(
                              findNameByLanguage(
                                language,
                                data?._driver?.lastName
                              )
                            )}
                        </b>
                      </span>
                      <br />
                      <address className="text-wrap">
                        {findNameByLanguage(
                          language,
                          data?._driver?.address.line1
                        )}
                        ,&nbsp;
                        {findNameByLanguage(
                          language,
                          data?._driver?.address.line2
                        )}
                        &nbsp;
                        {findNameByLanguage(
                          language,
                          data?._driver?.address.city
                        )}
                        ,&nbsp;
                        {findNameByLanguage(
                          language,
                          data?._driver?.address?.state
                        )}
                        ,&nbsp;
                        {findNameByLanguage(
                          language,
                          data?._driver?.address?.country
                        )}
                        ,&nbsp; {data?._driver?.address?.zip}
                      </address>
                      <span>Phone : </span>
                      <span>{data?._driver?.phone}</span>

                      <br />
                      <span>Email : </span>
                      <span>{data?._driver?.email}</span>

                      <br />
                      <br />
                    </div>
                  </div>
                  <hr className="styled-horizontal-bar" />
                  <div className="heading-container">
                    <b>Trip Details</b>
                  </div>

                  <div className="driver-trip-detail-container">
                    <div className="detail-row">
                      <span className="left-element">
                        <b>Trip Id : </b>
                        {data?.uniqueId}
                      </span>
                      <span className="right-element">
                        <b>Status : </b>
                      <span>{capitalizeFirstLetter(data?.tripStatus)}</span>
                      {data?.cancellationReason && <span>{ " - " + data?.cancellationReason}</span>}
                      </span>
                    </div>

                    <div className="detail-row">
                      <span className="left-element">
                        <b>Total Seats : </b>
                        {data?.totalSeats}
                      </span>
                      <span className="right-element">
                        <b>Total Seat Booked : </b>
                        {data?.seatsBooked}
                      </span>
                    </div>

                    <div className="detail-row">
                      <span className="left-element">
                        <b> Total Child Seats : </b>
                        {data?.totalChildSeats}
                      </span>
                      <span className="right-element">
                        <b>Total Child Seat Booked : </b>
                        {data?.childSeatsBooked}
                      </span>
                    </div>
                    <div className="detail-row">
                      <span className="left-element content-wrap" style={{marginLeft:'0px'}}>
                        <b>From -</b> &nbsp;
                        {findNameByLanguage(language, data?.pickUp.line1)}
                        ,&nbsp;
                        {findNameByLanguage(language, data?.pickUp.line2)}&nbsp;
                        {findNameByLanguage(language, data?.pickUp.city)}
                        ,&nbsp;
                        {findNameByLanguage(language, data?.pickUp.state)}
                        ,&nbsp;{" "}
                        {findNameByLanguage(language, data?.pickUp.country)}
                        ,&nbsp; {data?.pickUp.zip}
                      </span>

                      <span className="right-element content-wrap">
                        <b>To -</b> &nbsp;
                        {findNameByLanguage(language, data?.destination.line1)}
                        ,&nbsp;
                        {findNameByLanguage(language, data?.destination.line2)}
                        &nbsp;
                        {findNameByLanguage(language, data?.destination.city)}
                        ,&nbsp;
                        {findNameByLanguage(language, data?.destination.state)}
                        ,&nbsp;{" "}
                        {findNameByLanguage(
                          language,
                          data?.destination.country
                        )}
                        ,&nbsp; {data?.destination.zip}
                      </span>
                    </div>
                    <div className="detail-row">
                      <span className="left-element">
                        <b>Scheduled Date : </b>{" "}
                        {formatDate(data?.scheduleDate)}
                      </span>
                      <span className="right-element">
                        <b>Drop Date : </b>
                        {formatDate(data?.dropDate)}
                      </span>
                    </div>

                    {/* <div className="hr-box">
                      <hr className="styled-horizontal-bar" />
                    </div> */}
                    <div className="detail-row">
                      <span className="left-element">
                        <b> Total Eco Credits : </b>
                        {ecoData?.totalEcoCredits}
                      </span>
                      <span className="right-element">
                        <b>Total Eco Tokens : </b>
                        {(payoutData?.ecoTokens ?? ecoData?.totalEcoTokens).toFixed(2)}
                      </span>
                    </div>
                    <div className="detail-row">
                      <span className="left-element">
                        <b> Total Carbon Saving : </b>
                        {ecoData?.totalcarbonSavings}
                      </span>
                      {/* <span className="right-element">
                        <b>Total Eco Tokens : </b>
                        {ecoData?.totalEcoTokens}
                      </span> */}
                      {
                        payoutData && payoutData.showPayout && 
                          (
                            <span className="right-element">
                              <Button onClick={payoutData.onPayoutClick} disabled={payoutData.disablePayoutButton}>
                                Payout
                              </Button>

                            </span>
                          )
                      }
                    </div>
                  </div>
                </div>
                <div className="hr-box">
                  <hr className="styled-horizontal-bar" />
                </div>
                {data?._user.length ? (
                  <>
                    <div className="trip-detail-card">
                      <div className="trip-detail-heading">
                        <b>Passenger Details</b>
                      </div>
                      <div className="passengers">
                        {data?._user.map((user: object, index: number) => (
                          <PassengerDetail data={user} tripId={id} key={index} />
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="no-passenger-message-box">
                    <b>No passengers to show</b>
                  </div>
                )}
              </div>
            )}
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

Manage.defaultProps = {
  isDisabled: true,
};

export default Manage;
