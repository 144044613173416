import { Box, Button, Checkbox, FormLabel, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import Header from "../../components/header";
import { ChangeEvent, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import './style.scss';
import { capitalizeFirstLetter, findNameByLanguage, formatAddress, formatDate, tripColumns } from "../../utils";
import CustomTable from "../../components/mui/table";
import { useLanguage } from "../../components/language-provider";
import { ITripDetails } from "../../interfaces/trips";
import { getUsersByName } from "../../services/User";
import { enqueueSnackbar } from "notistack";
import { IUser } from "../../interfaces/payment";
import { exportPayoutTrips, getPayoutTrips, payingOutTrips } from "../../services/Trips";
import Manage from "../trip/manage";
import Loader from "../../components/mui/loader/Loader";
import { Download } from "@mui/icons-material";
import ExportDialog from "../../components/mui/dialog/exportDialog";

interface IPayoutState {
  search: string;
  manage: {
    type: "view";
    id: string;
    isOpen: boolean;
    tripDetail?: ITripDetails;
  };
  startDate: any;
  endDate: any;
  tripsList: ITripDetails[];
  selectedTrips: any;
  loader: boolean;
  status: string;
  pagination: {
    page: number;
    totalPages: number;
  };
  searchName: string;
  usersAsOptions: { id: string; label: string; }[];
  exportPayouts: boolean;
  exportStartDate: any;
  exportEndDate: any;
}


const Payouts = () => {
  const { language } = useLanguage();
  const [state, setState] = useState<IPayoutState>({
    loader: true,
    search: "",
    manage: {
      type: "view",
      isOpen: false,
      id: "",
    },
    startDate: '',
    endDate: '',
    tripsList: [],
    selectedTrips: [],
    searchName: "",
    pagination: {
      page: 1,
      totalPages: 1
    },
    usersAsOptions: [
      {
        label: 'Deepak Kumar',
        id: '44352435243'
      }
    ],
    status: 'ALL',
    exportPayouts: false,
    exportStartDate: '',
    exportEndDate: ''
  });

  const handleStatusChange = (value: string) => {
    //Call backend API to get list of trips using status which is 'e'
    setState(prev => ({
      ...prev,
      status: value,
      pagination: {
        ...state.pagination,
        page: 1
      }
    }));
  }

  const handleMultipleDelete = (
    e: ChangeEvent<HTMLInputElement>,
    selectedTrips: string[],
    id: string
  ) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = selectedTrips;
      payload.push(id);
    } else {
      payload = selectedTrips.filter((ele) => ele !== id);
    }

    setState((prevState) => {
      return {
        ...prevState,
        selectedTrips: payload,
      };
    });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];

    if (e.target.checked) {
      if (state.tripsList?.length) {
        payload = state.tripsList.map((ele: any) => ele._id.toString());
      }
    } else {
      payload = [];
    }
    const updatedList = state.tripsList.map((items: any) => (
      {
        ...items, all: (
          <Checkbox
            sx={{ color: "#bdbdbd" }}
            onChange={(e) => handleMultipleDelete(e, state.selectedTrips, (items.id).toString())}
            checked={payload.includes(items._id.toString()) ? true : false}
          />
        )
      }
    ))
    setState((prevState) => {
      return {
        ...prevState,
        selectedTrips: payload,
        list: updatedList
      };
    });
  };

  const onPageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    setState({ ...state, pagination: { ...state.pagination, page: newPage } });
  };

  const createRow = (trip: ITripDetails) => {
    return {
      all: (
        <Checkbox
          sx={{ color: "#bdbdbd" }}
          onChange={(e) => {
            if (e.target.checked) {
              setState(prev => ({
                ...prev,
                selectedTrips: [...state.selectedTrips, trip._id.toString()]
              }))
            } else {
              setState(prev => ({
                ...prev,
                selectedTrips: state.selectedTrips.filter((id: string) => id !== trip._id)
              }))
            }


          }}
          checked={state.selectedTrips.includes(trip._id.toString()) ? true : false}
        />
      ),
      id: trip._id,
      tripId: (
        <Typography
          className="cursor-pointer"
          color="primary"
          variant="body1"
          style={{ fontFamily: "Space Grotesk" }}
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              manage: {
                ...prevState.manage,
                id: trip._id,
                type: 'view',
                isOpen: !state.manage.isOpen,
                tripDetail: trip
              },
            }));
          }}
        >
          {trip.uniqueId}
        </Typography>
      ),
      name: (
        <p>
          {capitalizeFirstLetter(
            findNameByLanguage(language, trip?._driver?.firstName)
          )}{' '}
          {capitalizeFirstLetter(
            findNameByLanguage(language, trip?._driver?.lastName)
          )}
        </p>
      ),
      from: (
        <>
          <Tooltip title={findNameByLanguage(language, trip.pickUp.line1)}>
            <p>{formatAddress(trip.pickUp.line1, language)}</p>
          </Tooltip>
        </>
      ),
      to: (
        <Tooltip title={findNameByLanguage(language, trip.destination.line1)}>
          <p>{formatAddress(trip.destination.line1, language)}</p>
        </Tooltip>
      ),
      scheduledDate: <p>{formatDate(trip?.scheduleDate)}</p>,
      status: <p>{capitalizeFirstLetter(trip.tripStatus)}</p>,
    };
  }

  const columns = [
    {
      id: "all",
      label: (
        <Checkbox
          sx={{ color: "#bdbdbd" }}
          onChange={handleSelectAll}
          checked={
            state.selectedTrips.length &&
              state.tripsList.length === state.selectedTrips.length
              ? true
              : false
          }
        />
      ),
    },
    ...tripColumns,
  ].filter(item => item.id !== 'action' && item.id !== 'inviteAccepted');

  const getUsers = async () => {
    try {
      setState((prev) => ({
        ...prev,
        loader: true
      }))

      const users = await getUsersByName(state.searchName) as IUser[];
      setState(prev => ({
        ...prev,
        usersAsOptions: users.map(user => {
          return {
            label: (user.firstName.length > 0 ? user.firstName[0].value : '') +
              (user.lastName.length > 0 ? ` ${user.lastName[0].value}` : '') + ` - ${user.phone}`,
            id: user._id,
          }
        })
      }));

    } catch (err) {

      enqueueSnackbar((err as { message: string }).message || 'Error occurred while fetching users', {
        variant: 'error'
      })
    } finally {
      setState((prev) => ({
        ...prev,
        loader: false
      }))
    }
  }

  const getTrips = async () => {
    setState((prev) => ({
      ...prev,
      loader: true
    }))

    try {
      const trips = await getPayoutTrips({
        ...(state.startDate && { startDate: new Date(state.startDate) }),
        ...(state.endDate && { endDate: new Date(state.endDate) }),
        page: state.pagination.page,
        ...(state.search && { searchDriver: state.search }),
        status: state.status
      });

      setState(prev => ({
        ...prev,
        tripsList: [...trips.data],
      }));

      setState(prev => ({
        ...prev,
        pagination: {
          ...state.pagination,
          totalPages: trips.meta.totalPages
        }
      }))
    } catch (err) {
      enqueueSnackbar((err as { message: string; }).message || 'Error occurred while fetching trips', {
        variant: 'error'
      });
    } finally {
      setState((prev) => ({
        ...prev,
        loader: false
      }))
    }
  }

  const handleExport = async () => {
    try {
      setState(prev => ({
        ...prev,
        loader: true
      }))
      const exportLink = (await exportPayoutTrips({
        startDate: state.exportStartDate,
        endDate: state.exportEndDate,
        status: state.status
      }) as {data: {link: string}}).data.link;

      const a = document.createElement("a");
      a.href = exportLink;
      
      document.body.append(a);
      a.click();

      setState(prev => ({
        ...prev,
        exportPayouts: !state.exportPayouts,
      }));

    } catch (error) {
      enqueueSnackbar((error as { message: string }).message || "Error occurred while exporting payouts", {
        variant: "error"
      })
    } finally {
      setState(prev => ({
        ...prev,
        loader: false,
      }))
    }
  }  

  useEffect(() => {
    console.log(state)
  });

  useEffect(() => {
    getUsers()
  //eslint-disable-next-line
  }, [state.searchName]);

  useEffect(() => {
    getTrips();
  //eslint-disable-next-line
  }, [state.search, state.startDate, state.pagination.page, state.endDate, state.status])

  return (
    <Box id="payouts-container">
      {state.loader && <Loader />}

      <Header
        searchPlaceholder="Search by driver name"
        onSearch={(e) => {
          setState(prev => ({
            ...prev,
            searchName: e.target.value
          }))
        }}
        onSelect={(event, value) => {
          setState(prev => ({
            ...prev,
            search: value?.id || ''
          }))
        }}
        // searchInput
        searchSelect
        onBtnClick={() => { }}
        optionsToSelect={state.usersAsOptions}
      >
        <Box className="header-container">
          <Select
            size="small"
            className="w-7"
            defaultValue="ALL"
            onChange={(event) => handleStatusChange(event.target.value)}
          >
            <MenuItem value="ALL">All</MenuItem>
            <MenuItem value="PAID">Paid</MenuItem>
            <MenuItem value="UNPAID">Unpaid</MenuItem>
          </Select>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="date-container">
              <DatePicker
                label={"Select Start Date"}
                className="start-date"
                value={state.startDate}
                maxDate={dayjs(new Date())}
                onChange={(newValue) =>
                  setState((prev) => {
                    return {
                      ...prev,
                      startDate: dayjs(newValue),
                    };
                  })
                }
              />
            </div>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="date-container">
              <DatePicker
                label={"Select End Date"}
                className="end-date"
                value={state.endDate}
                minDate={state.startDate ? dayjs(state.startDate) : ""}
                onChange={(newValue) =>
                  setState((prev) => {
                    return {
                      ...prev,
                      endDate: dayjs(newValue),
                    };
                  })
                }
              />
            </div>
          </LocalizationProvider>
          <Button onClick={async () => {
            const selectedPayoutTrips = [] as {_id: string, ecoTokens: number}[];
            state.tripsList.forEach(trip => {
              if (state.selectedTrips.includes(trip._id) && trip.payoutDate === undefined) {
                selectedPayoutTrips.push({ _id: trip._id, ecoTokens: trip.ecoTokens ?? 0}) 
              }
            });
            if (selectedPayoutTrips.length > 0) {

              setState(prev => ({
                ...prev,
                loader: true
              }))

              await payingOutTrips({
                selectedTrips: [...selectedPayoutTrips]
              });

              enqueueSnackbar('Payout successful', {
                variant: 'success'
              })

              getTrips()
            } else if (state.selectedTrips.length > 0){
              enqueueSnackbar('All these trips have been already paid out', {
                variant: 'info'
              })
            } else {
              enqueueSnackbar('Select atleast one trip to payout', {
                variant: 'warning'
              })
            }
          }}>Payouts</Button>

          <Tooltip title="Export Trips">
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                setState({ ...state, exportPayouts: !state.exportPayouts })
              }
            >
              <Download />
            </Button>
          </Tooltip>
        </Box>
      </Header>

      <CustomTable
        columns={columns}
        rows={state.tripsList.map((trip: ITripDetails) => {
          return createRow(trip)
        })}
        pagination={state.pagination}
        onPageChange={onPageChange}
      />

      <Manage
        payoutData={{
          ecoTokens: state.manage.tripDetail?.ecoTokens ?? 0,
          showPayout: true,
          disablePayoutButton: state.manage.tripDetail?.payoutDate !== undefined ? true : false,
          onPayoutClick: async () => {
            setState((prev => ({
              ...prev,
              loader: true
            })))

            await payingOutTrips({
              selectedTrips: [
                {
                  _id: state.manage.tripDetail?._id,
                  ecoTokens: state.manage.tripDetail?.ecoTokens
                }
              ]
            });

            enqueueSnackbar('Payout successful', {
              variant: 'success'
            })

            getTrips()

            setState(prev => ({
              ...prev,
              manage: {
                ...state.manage,
                isOpen: !state.manage.isOpen,
              }
            }));

            // window.location.reload();
          }
        }}
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        id={state.manage.id}
        onClose={() => {
          setState((prevState) => {
            return {
              ...prevState,
              manage: {
                ...prevState.manage,
                isOpen: !prevState.manage.isOpen,
              },
            };
          });
        }}
      />
      <ExportDialog
        title="Export Trips"
        open={state.exportPayouts}
        onConfirm={handleExport}
        onClose={() => {
          setState(prev => ({
            ...prev,
            exportPayouts: !state.exportPayouts
          }));
        }}
        btnText="Export Trips"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="date-container">
            <FormLabel sx={{marginBottom:'5px'}} className="date-label">Start Date</FormLabel>
            <DatePicker
              label={state.exportStartDate ? "" : "Select Start Date"}
              className="start-date"
              value={state.exportStartDate}
              minDate={
                state.exportEndDate
                  ? dayjs(state.exportEndDate).subtract(1, "month")
                  : undefined
              }
              maxDate={dayjs(new Date())}
              onChange={(newValue) =>
                setState((prev) => {
                  return {
                    ...prev,
                    exportStartDate: (newValue),
                  };
                })
              }
            />
          </div>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="date-container">
            <FormLabel sx={{marginBottom:'5px',marginTop:'5px'}} className="date-label">End Date</FormLabel>
            <DatePicker
              label={state.exportEndDate ? "" : "Select End Date"}
              className="end-date"
              value={state.exportEndDate}
              minDate={state.exportStartDate ? dayjs(state.exportStartDate) : ""}
              maxDate={
                state.exportStartDate &&
                dayjs().diff(dayjs(state.exportStartDate), "day") > 31
                  ? dayjs(state.exportStartDate).add(1, "month")
                  : dayjs(new Date())
              }
              onChange={(newValue) =>
                setState((prev) => {
                  return {
                    ...prev,
                    exportEndDate: (newValue),
                  };
                })
              }
            />
          </div>
        </LocalizationProvider>
      </ExportDialog>
    </Box>
  );
};

export default Payouts;