import { Box } from "@mui/material"
import './style.scss';
import { IUser } from "../../../interfaces/payment";
import CustomDialog from "../../../components/mui/dialog";

interface IMarketplaceUsersProp {
  users: IUser[];
  isOpen: boolean;
  onClose: () => void;
}


const MarketPlaceFriendsList = ({ users, isOpen = false, onClose }: IMarketplaceUsersProp) => {

  return (
    <CustomDialog
      title="Rewards Marketplace Users"
      isOpen={isOpen}
      onClose={onClose}
      disabled={true}
    >
      <Box id="marketplace-friends-list">

        <div className="marketplace-friends-list-content">
          {
            users.length > 0 && <div className="marketplace-friends-list-container">
              {
                users.map((item, index) => {
                  return (<Friend user={item as IUser} key={item.phone} borderTop={index !== 0} />)
                })
              }
            </div>
          }
          {
            users.length <= 0 && (<div className="text-center p-1"> No marketplace users</div>)
          }
        </div>

      </Box>
    </CustomDialog>
  )
}

const Friend = ({ user, borderTop }: { user: IUser; borderTop: boolean }) => {
  return (
    <Box className={`friend-container ${borderTop === true ? 'border-top' : ''}`}>
      <b>{`${user.firstName && user.firstName[0] && user.firstName[0].value} ${
          user.lastName && user.lastName[0] && user.lastName[0].value}`}</b>
      <small><b>{user.phone}</b></small>
    </Box>
  )
}

export default MarketPlaceFriendsList;