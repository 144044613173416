import { FC } from 'react';
import CustomDialog from '../../../components/mui/dialog';
import { IGroupMemberProps } from '../../../interfaces/users';
import { capitalizeFirstLetter } from '../../../utils';
import { Box } from '@mui/material';

interface IUserProps {
  image: string;
  name: string;
  email: string;
  phone: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  zip: string | number;
  gender: string;
  preference: string;
}

const UserDetails = ({
  image,
  name,
  email,
  phone,
  gender,
  line1,
  line2,
  city,
  state,
  country,
  zip,
  preference,
}: IUserProps) => {
  return (
    <div className="user-detail-container ">
      <div className="user-detail-card">
        <div className="user-row">
          <div className="col-xs-12 user-image-container">
            <img src={image} className="user-profile-image" alt="user-pic" />
          </div>
          <div className="col-md-6 col-xs-12 user-details">
            <span>
              <b>{name}</b>
            </span>

            <address className="text-wrap">
              <span>
                <b>Address: </b>
              </span>
              {line1},&nbsp;
              {line2}&nbsp;
              {city}
              ,&nbsp;{state},&nbsp; {country},&nbsp; {zip}
            </address>

            <span>
              <b>Gender : </b>
            </span>
            <span>{capitalizeFirstLetter(gender)}</span>

            <br />
            <span>
              <b>Email : </b>
            </span>
            <span>{email}</span>

            <br />
            <span>
              <b>Phone : </b>
            </span>
            <span>{phone}</span>
            <br />
            <span>
              <b>Preference : </b>
            </span>
            <span>{preference}</span>
          </div>
        </div>
      </div>
    </div>
  );
};


const GroupMembers: FC<IGroupMemberProps> = ({ isOpen, onClose, members }) => {
  return (<>
    <CustomDialog
      title="Group Members"
      isOpen={isOpen}
      onClose={onClose}
      disabled={true}
    >
      { members.length ? 
        members.map((member, index) => {
          return (
            <UserDetails
              key={index}
              name={member.firstName[0]?.value + ' ' + member.lastName[0]?.value}
              image={member.photo}
              email={member.email}
              phone={member.phone}
              gender={member.gender}
              line1={member.address.line1[0]?.value}
              line2={member.address.line2[0]?.value}
              city={member.address.city[0]?.value}
              state={member.address.state[0]?.value}
              country={member.address.country[0]?.value}
              zip={member.address.zip}
              preference={member.userPreference}
            />
          )
        }) : (
          <Box>
            <p className='text-center'>No group members</p>
          </Box>
        )
      }
    </CustomDialog>
  </>)
}

export default GroupMembers;