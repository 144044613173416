import { Grid, Button, Box, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPayment, savePayment } from "../../../services/payment";
import { FieldValues, useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import Loader from "../../../components/mui/loader/Loader";
import { StyledTextField } from "../../user/manage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IStripeDetails } from "../../../interfaces/configuration/stripe/index";
import ErrorMessage from "../../../components/error/errorStatement";
import "./index.scss";
const Stripe = () => {
  const [stripeDetails, setStripeDetails] = useState<IStripeDetails>({
    createdAt: "",
    providerId: "",
    providerType: "",
    secretKey: "",
    updatedAt: "",
    __v: 0,
    _id: "",
    loader: true,
  });
  const [visibility, setVisibility] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  /* eslint-disable */
  useEffect(() => {
    const result = getPayment();
    result.then((response: any) => {
      setValue(`providerId`, response.data.providerId);
      setValue(`secretKey`, response.data.secretKey);
      setValue(`providerType`, `STRIPE`);
      setStripeDetails({ ...stripeDetails, loader: false });
    }).catch(()=>{
      setStripeDetails({ ...stripeDetails, loader: false });
    })
  }, []);
  /* eslint-enable */
  const formDetails = (details: FieldValues) => {
    setStripeDetails({ ...stripeDetails, loader: true });
    const result = savePayment(
      details.providerId,
      details.secretKey,
      details.providerType
    );
    result
      .then((response) => {
        setStripeDetails({ ...stripeDetails, loader: false });
        enqueueSnackbar("Stripe saved successfully", {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      })
      .catch((er) =>
        enqueueSnackbar("Coudn't update stripe,please try again later", {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        })
      );
  };
  return (
    <>
      <div
        className={stripeDetails.loader ? "stripe fade" : "stripe"}
        style={{ marginTop: "24px" }}
      >
        <form onSubmit={handleSubmit(formDetails)}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <StyledTextField
                label="Stripe ID"
                variant="outlined"
                {...register("providerId", { required: true })}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
              />
              {errors.providerId && (
                <ErrorMessage
                  className="error-text"
                  variant="error"
                  innerText={"Stripe Id is required"}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                label="Stripe Secret"
                variant="outlined"
                type={visibility ? "text" : "password"}
                {...register("secretKey", { required: true })}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
              />
              <span
                className={
                  !visibility ? "stripe-key-visibility-off" : "hide-eye"
                }
                onClick={() => setVisibility(!visibility)}
              >
                <VisibilityOff
                  className="visibility-officon"
                  sx={{ color: "#707070" }}
                />
              </span>
              <span
                className={
                  visibility ? "stripe-key-visibility-off" : "hide-eye"
                }
                onClick={() => setVisibility(!Visibility)}
              >
                <Visibility
                  className="visibility-officon"
                  sx={{ color: "#707070" }}
                />
              </span>

              {errors.secretKey && (
                <ErrorMessage
                  className="error-text"
                  variant="error"
                  innerText={"Secret Key is required"}
                />
              )}
            </Grid>
          </Grid>

          <Box className="mt-3" textAlign="end">
            <Button size="medium" type="submit">
              Save
            </Button>
          </Box>
        </form>
        {stripeDetails.loader && <Loader />}
      </div>
    </>
  );
};

export default Stripe;
