import { ChangeEvent, useEffect, useState } from 'react';
import './style.scss';
import {Box} from '@mui/material';
import { getProfileDetails } from '../../../services/profileDetails';
import { IResponseProfile } from '../../../interfaces/profile';
import Logo from "../../../assets/images/logo.png";
import Loader from '../../../components/mui/loader/Loader';
interface IProfileDetails {
  firstName: string;
  secondName: string;
  email: string;
  phone: string;
  cicularProgress: boolean
}

const Profile = () => {
  const [profileDetails, setProfileDetails] = useState<IProfileDetails>({
    firstName: "",
    secondName: "",
    email: "",
    phone: "",
    cicularProgress: true
  });
  const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
  }
  const fetchData = () => {
    getProfileDetails().then((response: IResponseProfile) => {
      setProfileDetails({ ...profileDetails, firstName: response?.data?.firstName[0]?.value, secondName: response.data?.lastName[0]?.value, email: response?.data?.email, phone: response.data?.phone, cicularProgress: false });
    }).catch((er) => console.log("Error", er));
  }
  /* eslint-disable */
  useEffect(() => {
    fetchData();
  }, []);
  /* eslint-enable */
  return (
    <div id='profile-info' className='profile'>
      <Box className='center'>
        <Box height='100px' width='100px' className="img-circle" aria-label="upload picture" component="label">
          <img alt='Profile-Pic' src={Logo} />
          <input hidden accept="image/*" type="file" onChange={uploadImage} />
        </Box>
      </Box>
      <Box className="admin-info-container">
        <Box className='grid-container'>
          <p className='label'>First Name :</p>
          <p>{profileDetails.firstName}</p>
        </Box>
        <Box className='grid-container'>
          <p className='label'>Last Name :</p>
          <p>{profileDetails.secondName}</p>
        </Box>
        <Box className='grid-container'>
          <p className='label'>Email :</p>
          <p >{profileDetails.email}</p>
        </Box>
        <Box className='grid-container'>
          <p className='label'>Phone :</p>
          <p>{profileDetails.phone}</p>
        </Box>
        </Box>
      {
        profileDetails.cicularProgress &&
        <Loader />
      }
    </div>
  )
}

export default Profile;