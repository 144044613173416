import { httpRequest } from "../Network";

export const whatsAppWebHook = (
 payload:object
) => {
  return httpRequest("POST", `whatsapp/template/webhook`, payload)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

