import { useEffect, useState } from "react";
//import TinyEditor from "../../../components/text-editor";
import { Box, Button } from "@mui/material";
//import { RawDraftContentState } from "draft-js";
import { getDeclarations, updateDeclarations } from "../../../services/declarations";
import { useSnackbar } from "notistack";
import { EditorState,convertToRaw,convertFromHTML,ContentState, } from "draft-js";
import Loader from "../../../components/mui/loader/Loader";
import EditorComponent from "../../../components/free-text-editor";
import { IResponseDeclarations } from '../../../interfaces/configuration/declarations/index';
import messages from "../../../assets/messages";
import draftToHtml from "draftjs-to-html";
const Declaration = () => {

 // const [state, setState] = useState<any>(EditorState.createEmpty());
  const [loader, setLoader] = useState(true);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleEditorStateChange = (newEditorState: EditorState) => {
    // const contentState = editorState.getCurrentContent()
   //  const rawContentState = convertToRaw(contentState)
    setEditorState(newEditorState)
  };
  
  useEffect(() => {
    const result = getDeclarations();
    result.then((response: IResponseDeclarations) => {
      const html = response.data.declaration;
      const blocksFromHTML = convertFromHTML(html);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
      setLoader(false);

    })
    .catch(()=>{
      enqueueSnackbar("No data found",{variant:'error'})
      setLoader(false)
    })
    //eslint-disable-next-line
  }, [])
  const { enqueueSnackbar } = useSnackbar();
  const updateData = (data: any) => {
    const payload = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    //console.log(data)
    setLoader(true);
    updateDeclarations({ declaration:payload}).then(() => {
      setLoader(false);
      enqueueSnackbar(`${messages.snackbar.success.declarations}`, {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      })
    }).catch((er) => enqueueSnackbar(`${messages.snackbar.error.declarations}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top"
      }
    }))
  }
  // const checkTinyEditor = (event: any) => {
  //   setState(event);
  // }
  return (
    <>
      <div className="" style={{marginTop:'24px'}}>
        {/* <TinyEditor
          value={state}
          onChange={checkTinyEditor}
          height="615px"
        /> */}
        <EditorComponent editorState={editorState} onEditorStateChange={handleEditorStateChange}/>
        
        <Box textAlign='end'>
          <Button className="mt-3" size='medium' autoFocus onClick={() => { updateData(editorState) }}>
            Save changes
          </Button>
        </Box>
        {loader &&
          <Loader />
        }
      </div>
    </>
  )
}

export default Declaration;