import { ChangeEvent, useEffect, useState } from "react";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Manage from "./manage";
import WarningDialog from "../../../components/mui/warning-dialog";
import {
  deleteLocation,
  getLocation,
  updateLocationStatus,
} from "../../../services/configurations/favourite-location";
import { useSnackbar } from "notistack";
import { capitalizeFirstLetter, findNameByLanguage } from "../../../utils";
import { useLanguage } from "../../../components/language-provider";
import Loader from "../../../components/mui/loader/Loader";
import {
  IEditableDetails,
  state,
  ILocationList,
  IFavouriteLocation
} from "../../../interfaces/configuration/favourite-location";
import messages from "../../../assets/messages";

// eslint-disable-next-line
let apiData: any[] = [];
let modifiedData: any[] = [];
// eslint-disable-next-line
let count: number = 1;
const columns = [
  {
    id: "emoji",
    label: "Emoji",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "order",
    label: "Order",
  },
  {
    id: "total-ecoCredit",
    label: "Total eco credits",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];
const FavoriteLocation = () => {
  const [state, setState] = useState<state>({
    manage: {
      type: "new",
      isOpen: false,
    },
    deleteWarning: false,
    _location: "",
    list: [],
    filterType: "ALL",
    page: 1,
    totalPage: 1,
    loader: true,
    searchLoader: false,
    initialState: "",
    searchItem: false,
    addLocationDialog: false,
    categoryId: ""
  });
  const [editableDetails, setEditableDetails] = useState<IEditableDetails>({
    name: [{ value: "" }],
    order: "",
    id: "",
    location: {},
  });
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLanguage();
  const fetchData = (searchValue?: string) => {
    // if (!state.searchItem) {
    getLocation({ status: state.filterType, page: searchValue ? 1 : state.page, searchValue })
      .then((response: IFavouriteLocation) => {
        setState({ ...state, loader: false });

        modifiedData = response.data.map(
          (user: ILocationList, index: number) =>
            createRow(
              user,
              handleManage,
              handleDelete,
              handleUpdate
            )
        );
        setState((prevState) => {
          return {
            ...prevState,
            list: modifiedData,
            deleteWarning: false,
            page: response.meta.currentPage,
            totalPage: response.meta.totalPage,
            manage: { ...state.manage, isOpen: false, type: "new" },
          };
        });
        apiData = modifiedData;
      })
      .catch((er) => console.log("Error sdfghjkl", er));
  };

  /* eslint-disable */
  useEffect(() => {
    fetchData();
  }, [state.filterType, state.page]);

  /* eslint-enable */
  const handleUpdate = (event: any, id: string) => {
    let isChecked = event.target.checked;
    let status = isChecked ? "ACTIVE" : "INACTIVE";
    updateLocationStatus({ id, status })
      .then(() => {
        fetchData();
        setTimeout(() => {
          enqueueSnackbar(`${messages.snackbar.success.status}`, {
            variant: "success",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
        }, 500)
      })
      .catch((er) =>
        enqueueSnackbar(`${messages.snackbar.error.status}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        })
      );
  };

  const handleSearch = (event: ChangeEvent<unknown> | any) => {
    const searchValue = event.target.value;
    if (event.key === "Enter") {
      fetchData(searchValue);
    }
  };

  const onPageChange = (e: ChangeEvent<unknown>, pageNumber: number) => {
    setState({ ...state, loader: true, page: pageNumber });
  };

  const handleManage = (
    type: "new" | "edit" | "view",
    name: any,
    order: string,
    id: string,
    location?: any
  ) => {
    if (type === "new") {
      setState({
        ...state,
        list: modifiedData,
        manage: { ...state.manage, isOpen: !state.manage.isOpen, type },
        loader: false,
      });
      setEditableDetails({
        ...editableDetails,
        name: [{ value: "" }],
        order: "",
        id: "",
      });
    } else {
      setState({
        ...state,
        list: modifiedData,
        manage: { ...state.manage, isOpen: true, type },
        loader: false,
        deleteWarning: false,
      });
      setEditableDetails({ ...editableDetails, name, order, id, location });
    }
  };

  const handleDelete = (id: string) => {
    setState({
      ...state,
      deleteWarning: true,
      loader: false,
      list: modifiedData,
      manage: { ...state.manage, isOpen: false },
    });
    setEditableDetails({ ...editableDetails, id });
  };

  const onDelete = (id: string) => {
    setState({ ...state, deleteWarning: false, loader: true });
    deleteLocation({ ids: [`${id}`] })
      .then((response) => {
        enqueueSnackbar(`${messages.snackbar.success.delete}`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
        fetchData();
      })
      .catch((er) =>
        enqueueSnackbar(`${messages.snackbar.error.delete}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        })
      );
  };

  const customLocation = (event: any) => {
    setState({ ...state, filterType: event.target.value, page: 1, loader: true });
  };

  const createRow = (
    location: ILocationList,
    onEdit: any,
    onDelete: any,
    onUpdate: any
  ) => {
    return {
      emoji : location.emoji,
      name: capitalizeFirstLetter(findNameByLanguage(language, location.locationName)),
      order: location.order,
      status: (
        <Switch
          checked={location.status === "ACTIVE" ? true : false}
          onChange={(event) => onUpdate(event, location._id)}
        />
      ),
      action: (
        <>
          <Tooltip title="Edit">
            <IconButton
              onClick={() =>
                onEdit(
                  "edit",
                  location.locationName,
                  location.order,
                  location._id,
                  location
                )
              }
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => onDelete(location._id)} color="error">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    };
  };

  return (
    <>
      <div>
        <Header
          className="my-2"
          searchPlaceholder="Search by category name"
          btnText="Add New Category"
          onSearch={handleSearch}
          onBtnClick={() =>
            handleManage(
              "new",
              editableDetails.name,
              editableDetails.order,
              editableDetails.id
            )
          }
          searchInput
        >
          <FormControl className="ml-2" size="small">
            <Select
              size="small"
              value={state.filterType}
              onChange={customLocation}
            >
              <MenuItem value="ALL">All Status</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">In-Active</MenuItem>
            </Select>
          </FormControl>
        </Header>

        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 248px)"
          errorMessage="Add locations to see data here"
          pagination={{
            page: state.page,
            totalPages: state.totalPage,
          }}
          onPageChange={onPageChange}
          searchLoader={state.searchLoader}
        />
        <Manage
          isOpen={state.manage.isOpen}
          type={state.manage.type}
          onClose={() =>
            setState({ ...state, manage: { ...state.manage, isOpen: false } })
          }
          data={editableDetails}
          fetchData={() => fetchData()}
          loader={() =>
            setState({
              ...state,
              loader: !state.loader,
              manage: { ...state.manage, isOpen: false },
            })
          }
          location={editableDetails.location}
        />

        <WarningDialog
          isOpen={state.deleteWarning}
          onClose={() => setState({ ...state, deleteWarning: false })}
          onConfirm={() => onDelete(editableDetails.id)}
          title="Delete Location"
          description="Are you sure do you want to delete this location?"
        />
        {state.loader && <Loader />}
      </div>
    </>
  );
};

export default FavoriteLocation;
