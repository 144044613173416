export const user = "auth/user";
export const trip = "aggregation/trip/admin";
export const admin = "auth/admin";
export const favouriteLocation = 'auth/favourite-location';
export const configuration = 'configuration/location'
export const location = 'configuration/loc'
export const favourite = '/aggregation/trip'
export const dashboard = '/aggregation/dashboard/admin'
export const purchaseRequests = 'configuration/purchase-request'
export const giftCategory = 'configuration/gift-category'
export const sendGiftCard = 'whatsapp/send-gift-card'
export const ecoSavings = 'aggregation/eco-saving'
export const invite = "aggregation/invite";
export const boardcastTemplate = "whatsapp/broadcast/template"
