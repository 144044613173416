import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
//import { ImportantDevices } from '@mui/icons-material';

interface props {
    isOpen: boolean;
    title: string;
    description: string;
    onClose: (e: MouseEvent<HTMLElement>) => void;
    onConfirm: (e: MouseEvent<HTMLElement>) => void;
}
const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialogTitle-root': {
        padding: '24px',
    },
    '& .MuiDialogContent-root': {
        padding: '0px 24px !important ' ,
    },
    '& .MuiDialogActions-root': {
        padding: '24px',
    },
    
}));
const WarningDialog: FC<props> = ({ isOpen, title, description, onClose, onConfirm }) => {
    return (
        <StyledDialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { description }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>Cancel</Button>
                <Button  onClick={onConfirm} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </StyledDialog>
    )
}

export default WarningDialog;