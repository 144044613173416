import "./style.scss";
import React from "react";
import { useState } from "react";
import { Box, Button, Grid} from "@mui/material";
import { useForm, FieldValues } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { changeAdminPassword } from "../../../services/Login";
import { StyledTextField } from "../../user/manage";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
interface IState {
  error: {
    emptyPassword: {
      password: boolean;
      confirmPassword: boolean;
    };
    unmatchedPassword: boolean;
  };
  passwordVisibility: {
    password: boolean;
    confirmPassword: boolean;
    oldPassword: boolean;
  };
}
const ChangePassword = () => {
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [confirmDetails, setConfirmDetails] = useState({
    oldPassword: "",
    passoword: "",
    confirmPassword: "",
  });
  const [state, setState] = useState<IState>({
    error: {
      emptyPassword: {
        password: false,
        confirmPassword: false,
      },
      unmatchedPassword: false,
    },
    passwordVisibility: {
      password: false,
      confirmPassword: false,
      oldPassword: false,
    },
  });
  const validateErrorPassword = (event: any) => {
    setConfirmDetails({ ...confirmDetails, passoword: event.target.value });

    if (confirmDetails.confirmPassword !== "") {
      if (
        event.target.value !== confirmDetails.confirmPassword &&
        event.target.value !== ""
      ) {
        return setState({
          ...state,
          error: {
            ...state.error,
            unmatchedPassword: true,
            emptyPassword: { ...state.error.emptyPassword, password: false },
          },
        });
      } else if (event.target.value === confirmDetails.confirmPassword) {
        return setState({
          ...state,
          error: {
            ...state.error,
            unmatchedPassword: false,
            emptyPassword: { ...state.error.emptyPassword, password: false },
          },
        });
      } else if (event.target.value === "") {
        return setState({
          ...state,
          error: {
            ...state.error,
            unmatchedPassword: false,
            emptyPassword: { ...state.error.emptyPassword, password: true },
          },
        });
      }
    } else if (event.target.value === "") {
      return setState({
        ...state,
        error: {
          ...state.error,
          unmatchedPassword: false,
          emptyPassword: { ...state.error.emptyPassword, password: true },
        },
      });
    } else if (event.target.value !== "") {
      return setState({
        ...state,
        error: {
          ...state.error,
          emptyPassword: { ...state.error.emptyPassword, password: false },
        },
      });
    }
  };

  const validateErrorConfirmPassword = (event: any) => {
    setConfirmDetails({
      ...confirmDetails,
      confirmPassword: event.target.value,
    });
    if (confirmDetails.passoword !== "") {
      if (
        event.target.value !== confirmDetails.passoword &&
        event.target.value !== ""
      ) {
       return setState({
          ...state,
          error: {
            ...state.error,
            unmatchedPassword: true,
            emptyPassword: {
              ...state.error.emptyPassword,
              confirmPassword: false,
            },
          },
        });
      } else if (event.target.value === confirmDetails.passoword) {
       return setState({
          ...state,
          error: { ...state.error, unmatchedPassword: false },
        });
      } else if (
        event.target.value === "" &&
        confirmDetails.passoword !== event.target.value
      ) {
       return setState({
          ...state,
          error: {
            ...state.error,
            unmatchedPassword: false,
            emptyPassword: {
              ...state.error.emptyPassword,
              confirmPassword: true,
            },
          },
        });
      }
    } else if (event.target.value === "") {
      return setState({
        ...state,
        error: {
          ...state.error,
          emptyPassword: {
            ...state.error.emptyPassword,
            confirmPassword: true,
          },
        },
      });
    } else if (event.target.value !== "") {
      return setState({
        ...state,
        error: {
          ...state.error,
          emptyPassword: {
            ...state.error.emptyPassword,
            confirmPassword: false,
          },
        },
      });
    }
  };

  const formDetails = (details: FieldValues) => {
    const payload = {
      oldPassword:details.oldPassword,
      newPassword:details.password
    }
    if (
      !state.error.emptyPassword.password &&
      !state.error.emptyPassword.confirmPassword &&
      !state.error.unmatchedPassword
    ) {
      changeAdminPassword(payload)
        .then((res : any) => {
          if(res.message === "Old Password is incorrect"){
              enqueueSnackbar("Old password is incorrect",{variant:"error"})
              return  
          }
          enqueueSnackbar("Password updated successfully", {
            variant: "success",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });

          setConfirmDetails((prev) => ({
            ...prev,
            oldPassword: "",
            password: "",
            confirmPassword: "",
          }))
          navigate('/dashboard')
        })
        .catch(() =>{
          enqueueSnackbar("Incorrect old password", {
            variant: "error",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          })
        } 
        );
    } 
  };
  
  return (
    <div id="change-password">
      <form onSubmit={handleSubmit(formDetails)}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12} className="grid-item">
          <StyledTextField
              label="Old Password*"
              variant="outlined"
              style={{color:"#707070"}}
              type={state.passwordVisibility.oldPassword ? "text" : "password"}
              {...register("oldPassword", {
                required: true,
                onChange(event) {},
              })}
            />
            <span
              className={
                state.passwordVisibility.oldPassword
                  ? "eye-visibility"
                  : "hide-icon"
              }
              style={{color:"#707070"}}
              onClick={() =>
                setState({
                  ...state,
                  passwordVisibility: {
                    ...state.passwordVisibility,
                    oldPassword: !state.passwordVisibility.oldPassword,
                  },
                })
              }
            >
              <Visibility />
            </span>
            <span
              className={
                !state.passwordVisibility.oldPassword
                  ? "eye-visibilityOff"
                  : "hide-icon"
              }
              style={{color:"#707070"}}
              onClick={() =>
                setState({
                  ...state,
                  passwordVisibility: {
                    ...state.passwordVisibility,
                    oldPassword: !state.passwordVisibility.oldPassword,
                  },
                })
              }
            >
              <VisibilityOff />
            </span>
            {errors.oldPassword && (
              <small className="errorText">Old Password is required</small>
            )}
          </Grid>
          <Grid item xs={12} md={12} className="grid-item">
            <StyledTextField
              label="New Password*"
              variant="outlined"
              style={{color:"#707070"}}
              type={state.passwordVisibility.password ? "text" : "password"}
              {...register("password", {
                required: true,
                onChange(event) {
                  validateErrorPassword(event);
                },
              })}
            />
            <span
              className={
                state.passwordVisibility.password
                  ? "eye-visibility"
                  : "hide-icon"
              }
              style={{color:"#707070"}}
              onClick={() =>
                setState({
                  ...state,
                  passwordVisibility: {
                    ...state.passwordVisibility,
                    password: !state.passwordVisibility.password,
                  },
                })
              }
            >
              <Visibility />
            </span>
            <span
              className={
                !state.passwordVisibility.password
                  ? "eye-visibilityOff"
                  : "hide-icon"
              }
              style={{color:"#707070"}}
              onClick={() =>
                setState({
                  ...state,
                  passwordVisibility: {
                    ...state.passwordVisibility,
                    password: !state.passwordVisibility.password,
                  },
                })
              }
            >
              <VisibilityOff />
            </span>
            {(errors.password || state.error.emptyPassword.password) && (
              <small className="errorText">New Passsword is required</small>
            )}
            {/* {state.error.unmatchedPassword && (
              <p className="errorText">
                New Password and confirm password must match each other
              </p>
            )} */}
          </Grid>
          <Grid item xs={12} md={12} className="grid-item">
          <StyledTextField
              label="Confirm Password*"
              style={{color:"#707070"}}
              variant="outlined"
              type={
                state.passwordVisibility.confirmPassword ? "text" : "password"
              }
              {...register("confirmPassword", {
                required: true,
                onChange(event) {
                  validateErrorConfirmPassword(event);
                },
              })}
            />
            <span
              className={
                state.passwordVisibility.confirmPassword
                  ? "eye-visibility"
                  : "hide-icon"
              }
              style={{color:"#707070"}}
              onClick={() =>
                setState({
                  ...state,
                  passwordVisibility: {
                    ...state.passwordVisibility,
                    confirmPassword: !state.passwordVisibility.confirmPassword,
                  },
                })
              }
            >
              <Visibility />
            </span>
            <span
              className={
                !state.passwordVisibility.confirmPassword
                  ? "eye-visibilityOff"
                  : "hide-icon"
              }
              style={{color:"#707070"}}
              onClick={() =>
                setState({
                  ...state,
                  passwordVisibility: {
                    ...state.passwordVisibility,
                    confirmPassword: !state.passwordVisibility.confirmPassword,
                  },
                })
              }
            >
              <VisibilityOff  sx={{color:"#707070"}}/>
            </span>
            {(errors.confirmPassword ||
              state.error.emptyPassword.confirmPassword) && (
              <small className="errorText">Confirm Password is required</small>
            )}
            {state.error.unmatchedPassword && (
              <small className="errorText">
                New Password and Confirm Password must match each other
              </small>
            )}
          </Grid>
        </Grid>

        <Box className="" style={{marginTop:'24px'}} textAlign="end">
          <Button size="medium" type="submit">
            Save
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default ChangePassword;
