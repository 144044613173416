import { httpRequest } from "../Network";
import { purchaseRequests, sendGiftCard } from "../endpoints";
export const getAllPurchaseRequests = (filter?:object) => {
  return httpRequest("GET", purchaseRequests,{},filter)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPurchaseRequestById = (id:string) => {
  return httpRequest("GET",`${purchaseRequests}/${id}`)
  .then((res)=> res)
  .catch((err)=> err)
}

export const updatePurchaseRequest = (id:string,payload:object) => {
  return httpRequest("PUT",`${purchaseRequests}/${id}`,payload)
  .then((res)=> res)
  .catch((err)=> {
    throw err
  })
}
export const deletePurchaseRequests = (id:string) => {
  return httpRequest("DELETE",`${purchaseRequests}/${id}`)
  .then((res)=> res)
  .catch((err)=> {
    throw err
  })
}

export const sendGifCardOnWhatsapp = (payload:object) => {
  return httpRequest("POST",`${sendGiftCard}`, payload)
  .then((res)=> res)
  .catch((err)=> {
    throw err
  })
}

export const updateGiftUrl = (payload:object) => {
  return httpRequest("PUT","/payment/transaction/update-gift-url",payload)
  .then((res)=> res)
  .catch((er)=>{
    throw er
  })
}

export const exportGiftData = (payload: {startDate: Date | string | undefined, endDate: Date | string | undefined}) => {
  return httpRequest("GET", `/payment/transaction/export-gifts?startDate=${payload.startDate}&endDate=${payload.endDate}`)
    .then((res)=> res)
    .catch((er)=>{
      throw er
    })
}