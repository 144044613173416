import { httpRequest } from "../Network";

export const updateMaintenanceDeatils = (payload: object) => {
  return httpRequest('PUT', '/configuration/maintenance/admin', {...payload})
    .then((response: any) => response.data)
    .catch(error => console.log(error));
}

export const getMaintenanceDetails = () => {
  return httpRequest('GET', '/configuration/maintenance/admin')
    .then((response: any) => response.data)
    .catch(err => console.log(err));
}
