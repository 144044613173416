import { Dialog, DialogContent,DialogTitle, DialogActions } from '@mui/material';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import './index.scss';
interface IProps {
    title:string;
    open: boolean;
    close?: boolean;
    children?: React.ReactElement | any;
    onClose: () => void;
    onConfirm:()=> void;
    className?: string;
    btnText:string;
}

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialogTitle-root': {
        padding: '24px',
    },
    '& .MuiDialogContent-root': {
        padding: '0px 24px !important',
    },
    '& .MuiDialogActions-root': {
        padding: '24px',
    },
    
}));
const ExportDialog = (props: IProps) => {
    const { open,title, children, onClose,onConfirm,btnText } = props;
    return (
        <>
            <StyledDialog open={open} onClose={onClose} className='export-dialog'>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent  className=''>
                    {children}
                </DialogContent>
                <DialogActions className='dialog-actions'>
                    <Button variant='outlined' size='small' onClick={onClose}>Cancel</Button>
                    <Button variant='contained' size='small' onClick={onConfirm}>{btnText}</Button>
                </DialogActions>
            </StyledDialog>
        </>
    )
}
export default ExportDialog;