import { httpRequest } from "../Network";
import { boardcastTemplate } from "../endpoints";


export const getBroadcastTemplates = (filter?:object) => {
    return httpRequest('GET',boardcastTemplate,{},filter)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}

export const sendBroadcastTemplate = (data?:object) => {
    return httpRequest('POST',boardcastTemplate,data)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}