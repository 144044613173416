import { createBrowserRouter } from "react-router-dom";
import PageNotFound from "../components/page-not-found";
import AuthGuard from "../components/AuthGuard";
import Layout from "../layout";
import Dashboard from "../screens/dashboard";
import GiftCards from "../screens/gift-cards";
import User from "../screens/user";
import Trip from "../screens/trip";
import Admin from "../screens/admin";
import Payment from "../screens/payment";
//import EditorComponent from "../components/free-text-editor";
import PaymentList from "../screens/payment/listing";

// Authorization 
import Login from "../screens/auth/login";
import ForgotPassword from "../screens/auth/forgot-password";

// configuration 
import Configuration from "../screens/configuration";
import FavoriteLocation from "../screens/configuration/favorite-location";
import GiftProvider from "../screens/configuration/gift-provider";
import Declaration from "../screens/configuration/declaration";
import WhatsappBot from "../screens/configuration/whatsapp-bot";
import Locations from "../screens/configuration/locations";

// settings 
import Settings from "../screens/settings";
import Profile from "../screens/settings/profile";
import ChangePassword from "../screens/settings/change-password";
import Stripe from "../screens/configuration/stripe";
import ResetPassword from "../screens/auth/reset-password";
import CarbonSaving from "../screens/configuration/carbon-saving";
import GiftCategory from "../screens/gift-cards/category";
import GiftPurchaseRequest from "../screens/gift-cards/gift-purchase-request";
import Payouts from "../screens/payouts";
import Broadcast from "../screens/broadcast";
import { MaintenanceSettings } from "../screens/settings/maintenance";



export default createBrowserRouter([
    {
        path: "login",
        element: <Login />,
        errorElement: <PageNotFound />
    },
    {
        path: "forgot-password",
        element: <ForgotPassword />,
        errorElement: <PageNotFound />
    },
    {
        element: <ResetPassword />,
        path: 'reset-password/:resetCode',
        errorElement: <PageNotFound />,
    },
    {
        path:"",
        element: <AuthGuard element={<Layout />} />,
        errorElement: <PageNotFound />,
        children: [
            {
                path: "dashboard",
                element: <Dashboard />,
                errorElement: <PageNotFound />
            },
            {
                path: "users",
                element: <User />,
                errorElement: <PageNotFound />
            },
            {
                path: "trips",
                element: <Trip />,
                errorElement: <PageNotFound />
            },
            {
                path: "payment",
                element: <Payment />,
                errorElement: <PageNotFound />,
                children: [
                    {
                        path: ":duration",
                        element: <PaymentList />,
                        errorElement: <PageNotFound />
                    },
                ]
            },
            {
                path: "payouts",
                element: <Payouts />
            },
            {
                path: "gift-cards",
                element: <GiftCards />,
                errorElement: <PageNotFound />,
                children:[
                      {
                        path: "gift-category",
                        element: <GiftCategory />,
                        errorElement: <PageNotFound />
                    },
                     {
                        path: "gift-providers",
                        element: <GiftProvider />,
                        errorElement: <PageNotFound />
                    },
                     {
                        path: "gift-purchase-requests",
                        element: <GiftPurchaseRequest />,
                        errorElement: <PageNotFound />
                    }
                ]
            },
            {
                path: "configurations",
                element: <Configuration />,
                errorElement: <PageNotFound />,
                children: [
                    {
                        path: "category",
                        element: <FavoriteLocation />,
                        errorElement: <PageNotFound />
                    },
                  
                    {
                        path: "declaration",
                        element: <Declaration />,
                        errorElement: <PageNotFound />
                    },
                    {
                        path: "whatsapp-bot",
                        element: <WhatsappBot />,
                        errorElement: <PageNotFound />
                    },
                    {
                        path: "stripe",
                        element: <Stripe />,
                        errorElement: <PageNotFound />
                    },
                    {
                        path: "locations",
                        element: <Locations />,
                        errorElement: <PageNotFound />
                    },
                    {
                        path: "carbon-saving",
                        element: <CarbonSaving />,
                        errorElement: <PageNotFound />
                    }
                ]
            },
            {
                path: "admin-users",
                element: <Admin />,
                errorElement: <PageNotFound />
            },
            {
                path: "broadcast",
                element: <Broadcast />,
                errorElement: <PageNotFound />
            },
            {
                path: 'settings',
                element: <Settings />,
                errorElement: <PageNotFound />,
                children: [
                    {
                        path: "profile",
                        element: <Profile />,
                        errorElement: <PageNotFound />
                    },
                    {
                        path: "change-password",
                        element: <ChangePassword />,
                        errorElement: <PageNotFound />
                    },
                    {
                        path: "maintenance",
                        element: <MaintenanceSettings />,
                        errorElement: <PageNotFound />
                    }
                ]
            },
        ]
    },

]);  
